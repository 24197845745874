import { gql } from '@apollo/client';

export const getCustomersGQL = gql`
  query getCustomers(
    $companyCode: String!
    $projectCode: String!
    $limit: Int
    $search: String
    $offset: Int
  ) {
    getCustomers(
      companyCode: $companyCode
      projectCode: $projectCode
      limit: $limit
      search: $search
      offset: $offset
    ) {
      totalCount
      customers {
        email
        ticket
        firstname
        lastname
        status
        isActivated
        ticketCategory {
          code
          name
        }
        customAttributes
      }
    }
  }
`;

export const getCustomerGQL = gql`
  query getCustomer(
    $companyCode: String!
    $projectCode: String!
    $email: String!
  ) {
    getCustomer(
      companyCode: $companyCode
      projectCode: $projectCode
      email: $email
    ) {
      email
      ticket
      firstname
      lastname
      status
      isActivated
      ticketCategory {
        code
        name
      }
      customAttributes
    }
  }
`;

export const exportCustomersGql = gql`
  query exportCustomers($companyCode: String!, $projectCode: String!) {
    exportCustomers(companyCode: $companyCode, projectCode: $projectCode) {
      url
    }
  }
`;

export const exportCustomersLoginHistoryGql = gql`
  query exportCustomersLoginHistory($companyCode: String!, $projectCode: String!, $startDate: String!, $endDate: String!) {
    exportCustomersLoginHistory(companyCode: $companyCode, projectCode: $projectCode, startDate: $startDate, endDate: $endDate) {
      url
    }
  }
`;

export const deleteCustomerGql = gql`
  mutation deleteCustomer(
    $companyCode: String!
    $projectCode: String!
    $email: String!
  ) {
    deleteCustomer(
      companyCode: $companyCode
      projectCode: $projectCode
      email: $email
    )
  }
`;

export const editCustomerGql = gql`
  mutation updateCustomer(
    $companyCode: String!
    $projectCode: String!
    $email: String!
    $customer: UpdateCustomer!
  ) {
    updateCustomer(
      companyCode: $companyCode
      projectCode: $projectCode
      email: $email
      customer: $customer
    )
  }
`;

export const addCustomerDirectlyGql = gql`
  mutation addCustomerDirectly(
    $companyCode: String!
    $projectCode: String!
    $customer: AddCustomer!
  ) {
    addCustomerDirectly(
      companyCode: $companyCode
      projectCode: $projectCode
      customer: $customer
    )
  }
`;

export const getAvailableCustomerAttributes = gql`
  query getAvailableCustomerAttributes(
    $companyCode: String!
    $projectCode: String!
  ) {
    getAvailableCustomerAttributes(
      companyCode: $companyCode
      projectCode: $projectCode
    ) {
      availableAttributes {
        type
        default
        code
        label
      }
    }
  }
`;

export const activateAllCustomersGql = gql`
  mutation activateAllCustomers($companyCode: String!, $projectCode: String!) {
    activateAllCustomers(companyCode: $companyCode, projectCode: $projectCode)
  }
`;

export const getCustomerLoginHistoryGql = gql`
  query getCustomerLoginHistoryQuery(
    $companyCode: String!
    $projectCode: String!
    $startDate: String
    $endDate: String
    $showOnlyLoggedInCustomer: Boolean
    $limit: Int
    $offset: Int
  ) {
    getCustomerLoginHistory(
      companyCode: $companyCode
      projectCode: $projectCode
      startDate: $startDate
      endDate: $endDate
      showOnlyLoggedInCustomer: $showOnlyLoggedInCustomer
      limit: $limit
      offset: $offset
    ) {
      history {
        email
        firstname
        lastname
        customAttributes
        loggedInDate
      }
      totalCount
    }
  }
`;

export const exportCustomerEventsGQL = gql`
  query exportCustomerEvents(
    $companyCode: String!, 
    $projectCode: String!, 
    $filter: Filter,
    $groupByCustomer: Boolean,
  ) {
    exportCustomerEvents(
      companyCode: $companyCode
      projectCode: $projectCode
      filter: $filter
      groupByCustomer: $groupByCustomer
    ) {
      url
    }
  }
`;

export const getCustomerEventsGQL = gql`
 query getCustomerEvents(
   $companyCode: String!,
   $projectCode: String!,
   $filter: Filter,
   $groupByCustomer: Boolean,
   $limit: Int,
   $offset: Int
  ) {
    getCustomerEvents(
      companyCode: $companyCode
      projectCode: $projectCode
      filter: $filter
      groupByCustomer: $groupByCustomer
      limit: $limit
      offset: $offset
    ) {
      events {
        anonymousCustomerId
        email
        firstname
        lastname
        event
        targetType
        target
        eventDate
      }
      totalCount
    }
  }
`;

export const queryFilterDataGQL = gql`
  query queryFilterDataPages($companyCode: String!, $projectCode: String!) {
    getCustomPages(companyCode: $companyCode, projectCode: $projectCode) {
      code
      name
      path
    }
    getAllConversionCards(companyCode: $companyCode, projectCode: $projectCode) {
      conversionCards { 
        code
        name
        type
        params
      }
    }
    getProjectTimes(companyCode: $companyCode, projectCode: $projectCode) {
      start
      end
    }
  },
`;

export const importCustomerGQL = gql`
mutation importCustomer($companyCode: String!, $projectCode: String!, $file: Upload!){
  importCustomer(companyCode: $companyCode, projectCode: $projectCode, file: $file) {
    hasErrors
    errors
    overallCounts {
      imported
      failed
      skipped
    }
  }
}
`;

export const sendCustomerInfoMailGQL = gql`
mutation sendCustomerInfoMail ($companyCode: String!, $projectCode: String!, $email: String!) {
  sendCustomerInfoMail(
    companyCode: $companyCode
    projectCode: $projectCode
    email: $email
  )
}
`;

export const deleteAllCustomersGQL = gql`
  mutation deleteAllCustomers($companyCode: String!, $projectCode: String!) {
    deleteAllCustomers(companyCode: $companyCode, projectCode: $projectCode)
  }
`;

export const sendInfoMailToAllCustomerGql = gql`
  mutation sendInfoMailToAllCustomer($companyCode: String!, $projectCode: String!, $type: InfoMailType!){
    sendInfoMailToAllCustomer(
      companyCode: $companyCode
      projectCode: $projectCode
      type: $type
    )
  }
`;
