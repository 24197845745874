import React, { useState } from 'react';
import { Button, Grid, Paper, Typography, FormControlLabel, Checkbox, TextField } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';
import {
  getSettings,
  setSettingsQuery,
  getProjects,
  updateProjectMutation,
} from '../gql';
import SelectFormField from '../../../components/forms/SelectFormField';
import { getTicketCategoriesGQL } from '../../TicketCategories/gql';

export default function MailSettings() {
  const params = useParams();
  const snackbar = useSnackbar();

  const [settings, setSettings] = useState({});
  const [parentProjectCode, setParentProjectCode] = useState('null');
  const [allowedTicketCategories, setAllowedTicketCategories] = useState([]);
  const { loading, error, refetch } = useQuery(getSettings, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      settingKeys: ['loginType', 'sessionDurationInSeconds', 'loginAfterRegistration', 'globalPassword'],
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getSettings }) => setSettings(getSettings.settings),
  });

  const { data: { getProjects: projects } = {} } = useQuery(getProjects, {
    onCompleted: ({ getProjects }) => {
      const currentProject = (getProjects || []).find(({ projectCode }) => projectCode === params.project);
      setParentProjectCode(currentProject.parentProjectCode || 'null');
      setAllowedTicketCategories(currentProject.allowedTicketCategories || []);
    },
  });

  const { data: getTicketCategoriesData = { getTicketCategories: [] } } = useQuery(getTicketCategoriesGQL, {
    skip: parentProjectCode === 'null',
    variables: { companyCode: params.company, projectCode: parentProjectCode },
  });

  const [updateSettingsInApi] = useMutation(setSettingsQuery);
  const [updateProject] = useMutation(updateProjectMutation, {
    variables: { companyCode: params.company, projectCode: params.project },
  });

  const onSave = async (e) => {
    e.preventDefault();
    await updateSettingsInApi({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        settings,
      },
    });

    const project = {
      parentProjectCode: parentProjectCode === 'null' ? null : parentProjectCode,
      type: parentProjectCode !== 'null' ? 'child' : 'standard',
      allowedTicketCategories,
    };

    await updateProject({ variables: { project } });
    refetch();
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const parentProjects = (projects || []).filter(({ type }) => type === 'parent');

  if (loading) return (<p>Loading</p>);
  if (error) return (<p>Fehler beim laden</p>);

  return (
    <form onSubmit={onSave}>
      <Paper elevation={3} style={{ padding: 30 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="primary" variant="contained" type="submit">
            Speichern
          </Button>
        </div>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" component="h2">Anmeldung</Typography>
            </Grid>
            <Grid item xs={5} style={{ marginTop: 30 }}>
              <SelectFormField
                label="Zugangstype"
                name="loginType"
                value={settings.loginType || 'loginWithRegistration'}
                options={[
                  { label: 'Zugang für alle (Keine Anmeldung nötig)', value: 'anonymousLogin' },
                  { label: 'Anmeldung über ein globales Passwort', value: 'loginWithPassword' },
                  { label: 'Anmeldung über ein Token in der URL', value: 'loginWithToken' },
                  { label: 'Anmeldung nur über vorherige Einladung', value: 'loginWithInvite' },
                  { label: 'Anmeldung nur über vorherige Einladung (versteckte Registrierung)', value: 'loginWithInviteWORegistration' },
                  { label: 'Anmeldung mit Registrierung ', value: 'loginWithRegistration' },
                  { label: 'Anmeldung mit E-Mail und Name (Cloud)', value: 'eventRegistration' },
                ]}
                onChange={({ target }) => setSettings({ ...settings, loginType: target.value })}
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 35 }}>
              {['loginWithRegistration', 'loginWithInviteWORegistration', 'loginWithInvite'].includes(settings.loginType)
                ? (
                  <FormControlLabel
                    control={
                      (
                        <Checkbox
                          checked={settings.loginAfterRegistration}
                          onChange={({ target }) => setSettings({ ...settings, loginAfterRegistration: target.checked })}
                        />
                      )
                    }
                    label="Teilnehmer einloggen nach der Registrierung"
                  />
                ) : null }
              {['loginWithPassword'].includes(settings.loginType)
                ? (
                  <TextField
                    label="Passwort"
                    fullWidth
                    required
                    value={settings.globalPassword}
                    onChange={({ target }) => setSettings({ ...settings, globalPassword: target.value })}
                    variant="outlined"
                  />
                ) : null }

            </Grid>
            <Grid item xs={5} style={{ marginTop: 30 }}>
              <TextField
                label="Sessionlaufzeit (in Sekunden)"
                fullWidth
                required
                type="number"
                value={settings.sessionDurationInSeconds || 12 * 60 * 60}
                onChange={({ target }) => setSettings({ ...settings, sessionDurationInSeconds: target.value })}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: 30 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" component="h2">Verbindung zu einem Gruppenevent</Typography>
            </Grid>
            <Grid item xs={5}>
              <SelectFormField
                label="Gruppenevent"
                name="loginType"
                value={parentProjectCode}
                options={[
                  { label: '(Keine Verbindung)', value: 'null' },
                  ...parentProjects.map((project) => ({ label: project.projectName, value: project.projectCode })),
                ]}
                onChange={({ target }) => setParentProjectCode(target.value)}
              />
            </Grid>
            {(parentProjectCode !== 'null') && (
            <Grid item xs={5}>
              <SelectFormField
                label="Berechtigte Tickets"
                name="ticketCategoryAccess"
                multiple
                value={allowedTicketCategories}
                options={[
                  ...getTicketCategoriesData?.getTicketCategories?.map((cat) => ({ label: cat.name, value: cat.code })),
                ]}
                onChange={({ target }) => setAllowedTicketCategories(target.value)}
                required
              />
            </Grid>
            )}

          </Grid>
        </div>
      </Paper>
    </form>
  );
}
