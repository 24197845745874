import React, { useState } from 'react';
import {
  Button,
  Paper,
} from '@material-ui/core';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import { useSnackbar } from 'material-ui-snackbar-provider';
import OverviewLinks from './OverviewLinks';
import Welcome from './Welcome';
import Timesettings from './Timesettings';
import Popup from './Popup';
import { getProjectTimesGql, setProjectTimesGql } from './gql';

const GetSettings = gql`
  query getSettings(
    $companyCode: String!
    $projectCode: String!
    $settingKeys: [String]!
  ) {
    getSettings(
      companyCode: $companyCode
      projectCode: $projectCode
      settingKeys: $settingKeys
    ) {
      settings
    }
  }
`;

const SetSettings = gql`
  mutation setSettings(
    $companyCode: String!
    $projectCode: String!
    $settings: Params!
  ) {
    setSettings(
      companyCode: $companyCode
      projectCode: $projectCode
      settings: $settings
    )
  }
`;

export default function Projects() {
  const params = useParams();
  const snackbar = useSnackbar();
  const [settings, setSettings] = useState();
  const [projectTimes, setProjectTimes] = useState([]);

  const { loading, error, refetch } = useQuery(GetSettings, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      settingKeys: ['start', 'end', 'modal', 'welcome', 'sessionDurationInSeconds', 'loginAfterRegistration', 'globalPassword'],
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getSettings }) => setSettings({
      welcome: { lines: [] },
      modal: { lines: [] },
      ...settings,
      ...getSettings.settings,
      start: new Date(getSettings.settings.start),
      end: new Date(getSettings.settings.end),
    }),
  });

  const { loading: loadingProjectTimes } = useQuery(getProjectTimesGql, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getProjectTimes }) => setProjectTimes(getProjectTimes),
  });

  const [updateSettingsInApi] = useMutation(SetSettings);
  const [updateProjectTimesInApi] = useMutation(setProjectTimesGql);
  const onSave = async () => {
    await updateSettingsInApi({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        settings: {
          ...settings,
          start: settings.start.getTime(),
          end: settings.end.getTime(),
        },
      },
    });
    await updateProjectTimesInApi({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        projectTimes,
      },
    });
    refetch();
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  if (!settings || loading || loadingProjectTimes) return <p>Daten werden geladen</p>;
  if (error) return <p>{error}</p>;
  return (
    <>
      <Paper elevation={3} style={{ padding: 30 }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="primary" variant="contained" onClick={onSave}>
            Speichern
          </Button>
        </div>
        <Timesettings
          projectTimes={projectTimes}
          onChange={(projectTimes) => setProjectTimes(projectTimes)}
        />
        <Welcome
          data={settings.welcome}
          onChange={(welcomeSettings) => setSettings({ ...settings, welcome: welcomeSettings })}
        />
        <Popup
          data={settings.modal}
          onChange={(modal) => setSettings({ ...settings, modal })}
        />
      </Paper>
      <Paper elevation={3} style={{ marginTop: 10, padding: 30 }}>
        <OverviewLinks />
      </Paper>
    </>
  );
}
