import { gql, useQuery, useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import DialogCreateProject from '../../components/dialogs/DialogCreateProject';
import LoadingScreen from '../../components/dialogs/Loading';
import Surface from '../../components/MainSurface';
import ProjectContextSwitch from './ProjectContextSwitch';
import { datagrid } from '../../util/language';
import AppBar from '../../components/Navigation/AppBar';
import PageTitle from '../../components/PageTitle';
import { useAuthState } from '../../context/auth';

const GET_PROJECTS = gql`
query getProjects{
  getProjects{
    id
    projectCode
    projectName
    type
    domain
    subdomain
    fullSubdomainUrl
    createdAt
  }
}`;

const DELETE_PROJECT = gql`
mutation deleteProject($projectCode: String!){
  deleteProject(projectCode: $projectCode)
}`;

function useForceUpdate() {
  const [setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

export default function Projects() {
  const { user } = useAuthState();

  let projects = [];
  const forceUpdate = useForceUpdate();

  const [openDialog, setDialogOpen] = React.useState(false);
  const [selection, setSelection] = React.useState(null);

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'no-cache',
  });
  const [deleteProject] = useMutation(DELETE_PROJECT, {
  });

  const handleAddProject = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };
  const handleSubmit = () => {
    setDialogOpen(false);
    refetch();
    forceUpdate();
  };

  const typeTranslation = {
    child: 'Teilevent',
    parent: 'Gruppenevent',
    standard: 'Standard',
  };

  const columns = [

    { field: 'id', headerName: 'id', hide: 'true', type: 'int', filterable: false },
    {
      field: 'select',
      headerName: 'Auswählen',
      width: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelection(params.row.projectCode);
            }}
          >
            Auswählen
          </Button>
        </strong>
      ),
    },
    { field: 'projectCode', headerName: 'Code', type: 'string', flex: 0.5 },
    { field: 'projectName', headerName: 'Projekt', type: 'dateTime', flex: 1 },
    { field: 'type', headerName: 'Type', type: 'string', flex: 0.5, valueFormatter: ({ value }) => typeTranslation[value] },
    { field: 'domain',
      headerName: 'Domain',
      type: 'string',
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Link
            href={`https://${params?.row?.domain}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params?.row?.domain}
          </Link>
        </strong>
      ),
    },
    { field: 'subdomain',
      headerName: 'Subdomain',
      type: 'string',
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Link
            href={`https://${params?.row?.fullSubdomainUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params?.row?.subdomain}
          </Link>
        </strong>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Angelegt',
      type: 'dateTime',
      flex: 0.8,
      valueFormatter: ({ value }) => new Date(parseInt(value)).toLocaleDateString(),
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 150,
      renderCell: (params) => (
        ([0, 1].includes(user.roleCode)) && (
        <strong>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              deleteProject({
                variables: {
                  companyCode: params.row.projectCode,
                },
                refetchQueries: [{
                  query: GET_PROJECTS,
                }],
              });
            }}
          >
            Löschen
          </Button>
        </strong>
        )
      ),
    },
  ];

  if (error) return `Error! ${error}`;

  if (data) {
    projects = data.getProjects.map((item) =>
      ({ ...item, selected: false }));
  }

  return (
    <>
      <AppBar />
      <Surface>

        <Grid container direction="column" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <PageTitle
              title="Projekte"
              button={([0, 1].includes(user.roleCode)) ? (
                (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleAddProject}
                  >
                    Erstellen
                  </Button>
                )
              ) : null}
            />

          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ widht: '100%' }}>
            <DataGrid
              rows={projects}
              columns={columns}
              componentsProps={{ toolbar: { filter: true, remove: false, add: handleAddProject, edit: false } }}
              pageSize={100}
              autoHeight
              hideFooterRowCount="false"
              localeText={datagrid}
            />
          </Grid>
        </Grid>
      </Surface>
      <DialogCreateProject open={openDialog} onClose={closeDialog} onFinish={handleSubmit} />

      {selection && <ProjectContextSwitch projectCode={selection} />}

      {(loading || networkStatus === 4) && <LoadingScreen /> }
    </>
  );
}
