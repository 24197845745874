import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useMemo } from 'react';
import SelectFormField from '../../../components/forms/SelectFormField';
import AccessTime from './AccessTime';

export default function AccessTimeRestriction({ currentPageRevision, onChange, accessTimeRestrictionData, loading }) {
  const displayedInputField = useMemo(() => {
    if (loading) return (<AccessTime loading={loading} />);

    if (currentPageRevision?.schedulingMode === 'inherited' && accessTimeRestrictionData?.schedulingMode === 'custom') {
      return (
        <AccessTime
          accessTime={accessTimeRestrictionData}
          isDisabled
          helperText="vom Seitentyp geerbt"
        />
      );
    }

    if (currentPageRevision?.schedulingMode === 'custom') {
      return (
        <AccessTime
          accessTime={currentPageRevision}
          onChange={onChange}
        />
      );
    }

    return null;
  }, [loading, currentPageRevision]);

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" style={{ marginBottom: -10, fontWeight: 'normal' }}>Zeitschaltung</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {loading ? <Skeleton height={59} /> : (
          <SelectFormField
            label="Typ"
            name="schedulingMode"
            value={currentPageRevision.schedulingMode}
            options={[
              { label: 'Aus', value: 'off' },
              { label: 'Von Seitentyp übernehmen', value: 'inherited' },
              { label: 'Von Projekt übernehmen', value: 'event' },
              { label: 'Benutzerdefiniert', value: 'custom' }]}
            onChange={({ target }) => onChange({ ...currentPageRevision, schedulingMode: target.value })}
          />
        )}
      </Grid>
      <Grid item xs={12} md={12}>
        {displayedInputField}
      </Grid>
    </>
  );
}
