import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import React,{useState} from "react";

export default function Welcome(props){
  const [state, setState] = useState(props.data)

  const onChange = (key, value, index) => {
    const newState = { ...state }
    if (index !== undefined) {
      newState[key] = [...newState[key]]
      newState[key][index] = value
    } else {
      newState[key] = value
    }
    setState(newState)
    props.onChange && props.onChange(newState)
  }

  return (
      <div style={{marginTop: 50}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider variant={"middle"} style={{marginBottom: 30}}/>
              <Typography variant={"h6"} component={"h2"}>Willkommmensseite</Typography>
          </Grid>
          <form>
              <Grid container spacing={3} style={{marginTop: 30}}>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <TextField
                          id="outlined-textarea"
                          label="Überschrift 1"
                          multiline
                          fullWidth
                          required
                          value={state.title}
                          onChange={e => onChange('title', e.target.value)}
                          variant="outlined"/>


                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <TextField
                          id="outlined-textarea"
                          label="Überschrift 2"
                          multiline
                          fullWidth
                          required
                          value={state.subtitle}
                          onChange={e => onChange('subtitle', e.target.value)}
                          variant="outlined"/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <TextField
                          id="outlined-textarea"
                          label="Zeile 1"
                          multiline
                          fullWidth
                          required
                          value={state.lines ? state.lines[0] : ''}
                          onChange={e => onChange('lines', e.target.value, 0)}
                          variant="outlined"/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <TextField
                          id="outlined-textarea"
                          label="Zeile 2"
                          multiline
                          fullWidth
                          required
                          value={state.lines ? state.lines[1] : ''}
                          onChange={e => onChange('lines', e.target.value, 1)}
                          variant="outlined"/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                      <TextField
                          id="outlined-textarea"
                          label="Zeile 3"
                          multiline
                          fullWidth
                          required
                          value={state.lines ? state.lines[2] : ''}
                          onChange={e => onChange('lines', e.target.value, 2)}
                          variant="outlined"/>
                  </Grid>
              </Grid>
          </form>
      </div>


  )




}