import { Button, makeStyles, Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState, useCallback } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import debounce from 'lodash.debounce';
import {
  getCustomersGQL,
  deleteCustomerGql,
  exportCustomersGql,
  activateAllCustomersGql,
  sendCustomerInfoMailGQL,
} from './gql';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import QuickSearchToolbar from '../../components/QuickSearchToolbar';
import UploadDialog from './components/UploadDialog';
import ParticipantMoreMenu from './components/ParticipantMoreMenu';

const useStyles = makeStyles((theme) => ({
  customerAttribute: {
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
  optionsButtonGroup: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      marginBlockStart: theme.spacing(1),
    },
  },
}));

export default function Participants() {
  const params = useParams();
  const match = useRouteMatch();
  const history = useHistory();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const classes = useStyles();
  const [pageLimit, setPageLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [importDialogOpen, setImportDialogOpen] = useState(false);

  const { error, loading, data, refetch } = useQuery(getCustomersGQL, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      limit: pageLimit,
      offset: pageLimit * page,
      search: searchTerm || undefined,
    },
  });

  const [exportCustomers, { loading: exportLoading }] = useLazyQuery(
    exportCustomersGql,
    {
      variables: { companyCode: params.company, projectCode: params.project },
      onCompleted: (data) => window.open(data.exportCustomers.url, '_blank'),
      onError: () =>
        snackbar.showMessage('Fehler beim exportieren der Teilnehmer'),
    },
  );

  const [deleteCustomer] = useMutation(deleteCustomerGql, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      limit: pageLimit,
      offset: pageLimit * page,
      searchTerm,
    },
    onCompleted: () =>
      refetch()
      && snackbar.showMessage('Teilnehmer wurde erfolgreich gelöscht'),
    onError: () => snackbar.showMessage('Fehler beim Löschen des Teilnehmers'),
  });

  const [sendInfoMail] = useMutation(sendCustomerInfoMailGQL, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
    },
    onCompleted: () =>
      refetch()
      && snackbar.showMessage('E-Mail wurde erfolgreich versendet'),
    onError: (err) => {
      if (err?.graphQLErrors[0]?.extensions.code === 40001) {
        snackbar.showMessage('Fehler beim Versenden der E-Mail! Es wurde kein Template festgelegt');
        return;
      }
      if (err?.graphQLErrors[0]?.extensions.code === 400) {
        snackbar.showMessage('Die Info E-Mail kann nur an einen importierte Teilnehmer versendet werden');
        return;
      }
      snackbar.showMessage('Fehler beim Versenden der E-Mail');
    },
  });

  const [setAllActive, { loading: activateAllLoading }] = useMutation(
    activateAllCustomersGql,
    {
      variables: { companyCode: params.company, projectCode: params.project },
      onCompleted: () =>
        refetch()
        && snackbar.showMessage(
          'Alle nicht aktivierten Teilnehmer wurden erfolgreich aktiviert.',
        ),
      onError: () =>
        snackbar.showMessage(
          'Fehler beim aktivieren aller nicht aktivierten Teilnehmer',
        ),
    },
  );

  const throttleSearchTermChange = useCallback(debounce((value) => {
    setSearchTerm(value);
  }, 200), [debounce]);

  if (error) return <p>Fehler beim laden</p>;

  const columns = [
    { field: 'email', headerName: 'E-Mail', type: 'string', flex: 0.6 },
    { field: 'firstname', headerName: 'Vorname', type: 'string', flex: 0.5 },
    { field: 'lastname', headerName: 'Nachname', type: 'string', flex: 0.5 },
    { field: 'ticket', headerName: 'Ticket', type: 'string', flex: 0.2 },
    {
      field: 'ticketCategory',
      headerName: 'Ticketkategorie',
      type: 'string',
      flex: 0.5,
      valueFormatter: ({ value }) => (value ? value.name : null),
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      flex: 0.2,
      valueFormatter: ({ value }) => (value === 'active' ? 'Aktiv' : 'Inaktiv'),
    },
    {
      field: 'isActivated',
      headerName: 'Aktiviert',
      type: 'string',
      flex: 0.2,
      valueFormatter: ({ value }) => (value ? 'Ja' : 'Nein'),
    },
    {
      field: 'customAttributes',
      headerName: 'Attribute',
      type: 'string',
      flex: 0.5,
      renderCell: ({ value }) => {
        if (!value) return null;
        return (
          <ul>
            {Object.entries(value).map(([key, value]) => {
              if (key === 'inviteCode') return null;
              return (
                <li key={key} className={classes.customerAttribute}>
                  {key}: {value}
                </li>
              );
            })}
          </ul>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 120,
      valueGetter: (params) => params.getValue('email'),
      renderCell: ({ value }) => (
        <>
          <Tooltip title="E-Mail versenden">
            <IconButton
              onClick={async () => {
                await confirm({
                  description: `Wollen Sie dem Teilnehmer mit der E-Mail Addresse ${value} wirklich die Info-Email zusenden?`,
                  confirmationText: 'Senden',
                  // confirmationButtonProps: { color: 'secondary' },
                });
                sendInfoMail({ variables: { email: value } });
              }}
            >
              <SendIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Löschen">
            <IconButton
              onClick={async () => {
                await confirm({
                  description: `Wollen Sie den Teilnehmer mit der E-Mail Addresse ${value} wirklich löschen?`,
                  confirmationText: 'Löschen',
                  confirmationButtonProps: { color: 'secondary' },
                });
                deleteCustomer({ variables: { email: value } });
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Teilnehmer"
        button={(
          <div className={classes.optionsButtonGroup}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`${match.url}/add`)}
            >
              Hinzufügen
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setImportDialogOpen(true)}
            >
              Importieren
            </Button>
            <ParticipantMoreMenu
              activateAllLoading={activateAllLoading}
              exportLoading={exportLoading}
              onExportCustomers={exportCustomers}
              onDelete={refetch}
              onSetAllActive={setAllActive}
            />
          </div>
        )}
      />
      <Paper elevation={3}>
        <DataGrid
          loading={loading}
          style={{ marginTop: '20px' }}
          columns={columns}
          rows={data?.getCustomers?.customers || []}
          getRowId={(row) => row.email}
          pageSize={pageLimit}
          page={page}
          rowCount={data?.getCustomers?.totalCount}
          autoHeight
          hideFooterRowCount="false"
          localeText={datagrid}
          paginationMode="server"
          pagination
          disableColumnMenu
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          onPageSizeChange={(size) => {
            setPageLimit(size.pageSize);
            setPage(0);
          }}
          onPageChange={(params) => setPage(params.page)}
          onCellClick={(selection) =>
            selection.colIndex !== 8
            && history.push(`${match.url}/edit/${selection.row.id}`)}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              onChange: (value) => throttleSearchTermChange(value),
            },
          }}
        />
      </Paper>
      <UploadDialog
        open={importDialogOpen}
        handleClose={() => setImportDialogOpen(false)}
        onNewImport={() => refetch()}
      />
    </>
  );
}
