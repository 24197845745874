import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SelectFormField from '../../../components/forms/SelectFormField';
import PageTitle from '../../../components/PageTitle';
import InputFormField from '../../../components/forms/InputFormField';
import { ADD_MEDIA, UPDATE_MEDIA, GET_SINGLE_MEDIA } from '../gql';
import CategoryParams from '../components/CategoryParams';
import VimeoParams from '../components/VimeoParams';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function MediaEdit() {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const snackbar = useSnackbar();

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [err, setErr] = useState(false);
  const [input, setInput] = useState({
    title: '',
    code: '',
    type: 'vimeo',
    status: 'inactive',
    params: {
      videoId: '',
      link: '',
    },
  });

  const [cat, setCat] = useState([]);
  const { loading } = useQuery(GET_SINGLE_MEDIA, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      code: params.code,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setInput({
        title: data.getSingleMediaLibraryMedia.title,
        code: params.code,
        type: data.getSingleMediaLibraryMedia.type,
        status: data.getSingleMediaLibraryMedia.status,
        params: {
          videoId: data.getSingleMediaLibraryMedia.params?.videoId,
        },
      });
      const array = data.getSingleMediaLibraryMedia.categories
        .map((item) => ({ code: item.code, sequenceId: item.sequenceId }));
      setCat(array);
    },
  });

  const [addMedia] = useMutation(ADD_MEDIA, {
    onCompleted() {
      history.push(`/${params.company}/${params.project}/mediathek/edit/media/${input.code}`);
      showSuccessMessage();
    },
    onError: (err) => {
      setErr(true);
      if (err.graphQLErrors[0]?.extensions.code === (409)) {
        snackbar.showMessage(' Das Medium existiert bereits.');
      } else if (err.graphQLErrors[0]?.extensions.code === (400)) {
        snackbar.showMessage(` Die ${input.type} ID ist inkorrekt`);
      } else {
        snackbar.showMessage('Fehler beim Erstellen des Mediums.');
      }
    },
  });

  const [updateMedia] = useMutation(UPDATE_MEDIA, {
    onCompleted() {
      showSuccessMessage();
    },
    onError: (err) => {
      setErr(true);
      if (err.graphQLErrors[0]?.extensions.code === (400)) {
        snackbar.showMessage(` Die ${input.type} ID ist inkorrekt`);
      } else {
        snackbar.showMessage('Fehler beim Bearbeiten des Mediums.');
      }
    },
  });

  const renderMediaSettings = () => {
    if (input.type === 'vimeo') {
      return (
        <VimeoParams
          err={err}
          params={input.params}
          onChange={(params) => setInput({ ...input, params })}
        />
      );
    }
    return null;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (params.code) {
      updateMedia({
        variables: {
          companyCode: params.company,
          projectCode: params.project,
          code: input.code,
          media: {
            title: input.title,
            status: input.status,
            type: input.type,
            params: {
              videoId: input.params.videoId,
            },
            categories: cat,
          },
        },
      });
      return;
    }

    addMedia({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        media: {
          code: input.code,
          title: input.title,
          status: input.status,
          type: input.type,
          params: {
            videoId: input.params.videoId,
          },
          categories: cat,
        },
      },
    });
  };

  if (loading || (params.code && !input.code)) return (<p>Loading</p>);
  return (
    <form onSubmit={onSubmit}>
      <PageTitle
        title={(params.code) ? 'Medium bearbeiten' : 'Medium hinzufügen'}
        button={(
          <Button
            variant="contained"
            color="primary"
            type="submit"
          >
            Speichern
          </Button>
          )}
      />
      <Paper className={classes.paper}>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputFormField
              error={(err && input.code === '')}
              label="Code"
              name="code"
              value={input.code}
              required
              disabled={!!(params.code)}
              onChange={((e) => {
                setInput({
                  ...input,
                  code: e.target.value,
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              error={(err && input.title === '')}
              label="Titel"
              name="title"
              value={input.title}
              required
              onChange={((e) => {
                setInput({
                  ...input,
                  title: e.target.value,
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectFormField
              label="Status"
              name="status"
              value={input.status}
              options={[{ label: 'Aktiv', value: 'active' }, { label: 'Inaktiv', value: 'inactive' }]}
              required
              onChange={((e) => {
                setInput({
                  ...input,
                  status: e.target.value,
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectFormField
              label="Art"
              name="type"
              options={[{ label: 'VIMEO', value: 'vimeo' }]}
              value={input.type}
              onChange={((e) => {
                setInput({
                  ...input,
                  type: e.target.value,
                });
              })}
              required
            />
          </Grid>
          {renderMediaSettings()}
          <Grid item xs={12}>
            <CategoryParams
              cat={cat}
              onChange={(categories) => setCat(categories)}
            />
          </Grid>
        </Grid>
      </Paper>
    </form>

  );
}
