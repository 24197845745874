import React from 'react';
import { Switch } from 'react-router';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import DynamicRoute from '../../util/DynamicRoute';
import Drawer from '../../components/Navigation/Drawer';
import AppBar from '../../components/Navigation/AppBar';
import Pages from '../Pages';
import CustomPages from '../CustomPages';
import CustomPagesEdit from '../CustomPages/subpages/edit';
import PagesTypes from '../CustomPagesTypes';
import Overview from '../Overview/Overview';
import CTA from '../CTA';
import Poll from '../Poll';
import CTAEdit from '../CTA/subpages/edit';
import PollEdit from '../Poll/subpages/edit';
import Forms from '../Forms';
import FormsEdit from '../Forms/subpages/edit';
import FormsModeratorView from '../Forms/subpages/moderatorView';
import Surface from './ProjectSurface';
import Streams from '../Streams';
import StreamEdit from '../Streams/subpages/edit';
import Config from '../Config';
import Invites from '../Invites';
import InvitesEdit from '../Invites/subpages/edit';
import Participants from '../participants';
import ParticipantEdit from '../participants/subpages/edit';
import Mediathek from '../MediaLib';
import MediaEdit from '../MediaLib/subpages/mediaEdit';
import CategoryEdit from '../MediaLib/subpages/categoryEdit';
import PageEdit from '../Pages/subpages/edit';
import MailSettings from '../Config/subpages/MailSettings';
import ChatSettings from '../Config/subpages/ChatSettings';
import AccessSettings from '../Config/subpages/AccessSettings';
import LoginHistory from '../participants/subpages/LoginHistory';
import Report from '../participants/subpages/Report';
import TicketCategories from '../TicketCategories';
import TicketCategoryEdit from '../TicketCategories/subpages/edit';
import Emojis from '../Emojis';
import EmojisEdit from '../Emojis/subpages/edit';
import PageTypesEdit from '../CustomPagesTypes/subpages/edit';
import Users from '../Users';
import UserEdit from '../Users/subpages/edit';

const GET_PROJECT = gql`
query getProject ($companyCode: String!, $projectCode: String!){
  getProject (companyCode: $companyCode, projectCode: $projectCode){
    id
    projectCode
    projectName
    parentProjectCode
    type
    domain
    createdAt
  }
}`;

export default function Project() {
  const params = useParams();
  const { data } = useQuery(GET_PROJECT, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
  });

  return (
    <>
      <AppBar projectName={data?.getProject?.projectName} projectType={data?.getProject?.type} />
      <Drawer projectType={data?.getProject?.type} />
      <Surface>
        <Switch>
          <DynamicRoute
            path="/:company/:project/pages/copy/:pageCode"
            authenticated
            neededRole={5}
          >
            <PageEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:company/:project/pages/edit/:pageCode"
            authenticated
            neededRole={5}
          >
            <PageEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:company/:project/pages/add"
            authenticated
            neededRole={5}
          >
            <PageEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:company/:project/pages"
            authenticated
            neededRole={5}
          >
            <Pages />
          </DynamicRoute>

          <DynamicRoute
            path="/:companyCode/:projectCode/customPages/copy/:code"
            authenticated
            neededRole={5}
          >
            <CustomPagesEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/customPages/draft/:code"
            authenticated
            neededRole={5}
          >
            <CustomPagesEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/customPages/edit/:code"
            authenticated
            neededRole={5}
          >
            <CustomPagesEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/customPages/add"
            authenticated
            neededRole={5}
          >
            <CustomPagesEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/customPages"
            authenticated
            neededRole={5}
          >
            <CustomPages />
          </DynamicRoute>

          <DynamicRoute
            path="/:companyCode/:projectCode/pageTypes/copy/:code"
            authenticated
            neededRole={5}
          >
            <PageTypesEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/pageTypes/draft/:code"
            authenticated
            neededRole={5}
          >
            <PageTypesEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/pageTypes/edit/:code"
            authenticated
            neededRole={5}
          >
            <PageTypesEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/pageTypes/add"
            authenticated
            neededRole={5}
          >
            <PageTypesEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/pageTypes"
            authenticated
            neededRole={5}
          >
            <PagesTypes />
          </DynamicRoute>

          <DynamicRoute
            path="/:companyCode/:projectCode/cta/edit/:code"
            authenticated
            neededRole={5}
          >
            <CTAEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/cta/copy/:code"
            authenticated
            neededRole={5}
          >
            <CTAEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/cta/add"
            authenticated
            neededRole={5}
          >
            <CTAEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/cta"
            authenticated
            neededRole={5}
          >
            <CTA />
          </DynamicRoute>

          <DynamicRoute
            exact
            path="/:company/:project/config"
            authenticated
            neededRole={5}
          >
            <Config />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/config/mailSettings"
            authenticated
            neededRole={5}
          >
            <MailSettings />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/config/accessSettings"
            authenticated
            neededRole={5}
          >
            <AccessSettings />
          </DynamicRoute>

          <DynamicRoute
            path="/:company/:project/invites/add"
            authenticated
            neededRole={5}
          >
            <InvitesEdit />
          </DynamicRoute>

          <DynamicRoute
            path="/:company/:project/invites"
            authenticated
            neededRole={5}
          >
            <Invites />
          </DynamicRoute>

          <DynamicRoute
            path="/:company/:project/streams/edit/:streamId"
            authenticated
            neededRole={5}
          >
            <StreamEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:company/:project/streams/add"
            authenticated
            neededRole={5}
          >
            <StreamEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/streams"
            authenticated
            neededRole={5}
          >
            <Streams />
          </DynamicRoute>

          <DynamicRoute
            path="/:company/:project/chat-settings"
            authenticated
            neededRole={5}
          >
            <ChatSettings />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/overview"
            authenticated
            neededRole={5}
          >
            <Overview />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/poll/edit/:code"
            authenticated
            neededRole={5}
          >
            <PollEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/poll/copy/:code"
            authenticated
            neededRole={5}
          >
            <PollEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/poll/add"
            authenticated
            neededRole={5}
          >
            <PollEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/poll"
            authenticated
            neededRole={5}
          >
            <Poll />
          </DynamicRoute>
          <DynamicRoute
            path="/:company/:project/forms/edit/:code/moderator"
            authenticated
            neededRole={5}
          >
            <FormsModeratorView />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/forms/edit/:code"
            authenticated
            neededRole={5}
          >
            <FormsEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/forms/copy/:code"
            authenticated
            neededRole={5}
          >
            <FormsEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/forms/add"
            authenticated
            neededRole={5}
          >
            <FormsEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/forms"
            authenticated
            neededRole={5}
          >
            <Forms />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:companyCode/:projectCode/emojis"
            authenticated
            neededRole={5}
          >
            <Emojis />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/emojis/add"
            authenticated
            neededRole={5}
          >
            <EmojisEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:companyCode/:projectCode/emojis/edit/:code"
            authenticated
            neededRole={5}
          >
            <EmojisEdit />
          </DynamicRoute>

          <DynamicRoute
            exact
            path="/:company/:project/mediathek"
            authenticated
            neededRole={5}
          >
            <Mediathek />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/mediathek/add/media"
            authenticated
            neededRole={5}
          >
            <MediaEdit />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/mediathek/add/category"
            authenticated
            neededRole={5}
          >
            <CategoryEdit />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/mediathek/edit/media/:code"
            authenticated
            neededRole={5}
          >
            <MediaEdit />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/mediathek/edit/category/:code"
            authenticated
            neededRole={5}
          >
            <CategoryEdit />
          </DynamicRoute>

          <DynamicRoute
            path="/:company/:project/participants/add"
            authenticated
            neededRole={5}
          >
            <ParticipantEdit />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/logins"
            authenticated
            neededRole={5}
          >
            <LoginHistory />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:companyCode/:projectCode/report"
            authenticated
            neededRole={5}
          >
            <Report />
          </DynamicRoute>
          <DynamicRoute
            path="/:company/:project/participants/edit/:participantEmail"
            authenticated
            neededRole={5}
          >
            <ParticipantEdit />
          </DynamicRoute>
          <DynamicRoute
            exact
            path="/:company/:project/participants"
            authenticated
            neededRole={5}
          >
            <Participants />
          </DynamicRoute>

          <DynamicRoute
            path="/:company/:project/ticketCategories/edit/:ticketCategoryCode"
            authenticated
            neededRole={5}
          >
            <TicketCategoryEdit />
          </DynamicRoute>
          <DynamicRoute
            path="/:company/:project/ticketCategories/add"
            authenticated
            neededRole={5}
          >
            <TicketCategoryEdit />
          </DynamicRoute>

          <DynamicRoute
            exact
            path="/:company/:project/ticketCategories"
            authenticated
            neededRole={5}
          >
            <TicketCategories />
          </DynamicRoute>

          <DynamicRoute
            path="/:companyCode/:projectCode/users/edit/:userId"
            authenticated
            neededRole={5}
          >
            <UserEdit />
          </DynamicRoute>

          <DynamicRoute
            path="/:companyCode/:projectCode/users/add"
            authenticated
            neededRole={5}
          >
            <UserEdit />
          </DynamicRoute>

          <DynamicRoute
            exact
            path="/:companyCode/:projectCode/users"
            authenticated
            neededRole={5}
          >
            <Users />
          </DynamicRoute>
        </Switch>
      </Surface>
    </>
  );
}
