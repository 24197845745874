import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

export default function AccessTime({ accessTime, onChange, loading, isDisabled = false, helperText }) {
  return (
    <Grid item xs={12} xl={12}>
      {loading ? <Skeleton height={49} />
        : (
          <Typography variant="h6" style={{ marginBottom: 20, fontWeight: 'normal' }}>
            Zugangszeit
          </Typography>
        )}
      <Grid container spacing={2} wrap>
        <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
          {loading ? <Skeleton height={59} />
            : (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  ampm={false}
                  label="Anfang"
                  value={accessTime?.startDate}
                  onChange={(value) => onChange({ ...accessTime, startDate: value })}
                  onError={(err) => { if (err) console.error(err); }}
                  format="dd.MM.yyyy HH:mm"
                  inputVariant="outlined"
                  disabled={isDisabled}
                  helperText={helperText}
                />
              </MuiPickersUtilsProvider>
            )}
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
          {loading ? <Skeleton height={59} />
            : (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  ampm={false}
                  label="Schluss"
                  value={accessTime?.endDate}
                  onChange={(value) => onChange({ ...accessTime, endDate: value })}
                  onError={(err) => { if (err) console.error(err); }}
                  format="dd.MM.yyyy HH:mm"
                  minDate={accessTime?.startDate}
                  inputVariant="outlined"
                  disabled={isDisabled}
                  helperText={helperText}
                />
              </MuiPickersUtilsProvider>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
}
