import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory } from 'react-router';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import AceEditor from 'react-ace';
import SelectFormField from '../../../components/forms/SelectFormField';
import PageTitle from '../../../components/PageTitle';
import InputFormField from '../../../components/forms/InputFormField';
import { ADD_CATEGORY, UPDATE_CATEGORY, GET_SINGLE_CATEGORY } from '../gql';

import 'ace-builds/src-noconflict/mode-jsx';
import 'ace-builds/src-noconflict/theme-textmate';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function CategoryEdit() {
  const classes = useStyles();
  const params = useParams();
  const snackbar = useSnackbar();
  const history = useHistory();

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };
  const [err] = useState(false);
  const [input, setInput] = useState({
    name: '',
    code: '',
    status: 'inactive',
    sequenceId: '',
  });

  const [addCategory] = useMutation(ADD_CATEGORY, {
    onCompleted() {
      history.push(`/${params.company}/${params.project}/mediathek/edit/category/${input.code}`);
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === (409)) {
        snackbar.showMessage('Fehler. Die Kategorie existiert bereits');
      } else {
        snackbar.showMessage('Fehler beim Erstellen der Kategorie.');
      }
    },
  });

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    onCompleted() {
      showSuccessMessage();
    },
    onError: () => {
      snackbar.showMessage('Fehler beim Bearbeiten der Kategorie.');
    },
  });

  useQuery(GET_SINGLE_CATEGORY, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      code: params.code,
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setInput({
        name: data.getSingleMediaLibraryCategory.name,
        code: params.code,
        status: data.getSingleMediaLibraryCategory.status,
        content: data.getSingleMediaLibraryCategory.content,
        moreContent: data.getSingleMediaLibraryCategory.moreContent,
        sequenceId: data.getSingleMediaLibraryCategory.sequenceId,
      });
    },
  });

  const parse = (string) => parseInt(string);

  const onSave = () => {
    if (params.code) {
      updateCategory({
        variables: {
          companyCode: params.company,
          projectCode: params.project,
          code: input.code,
          category: {
            name: input.name,
            status: input.status,
            content: input.content,
            moreContent: input.moreContent,
            sequenceId: parse(input.sequenceId),
          },
        },
      });
      return;
    }

    addCategory({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        category: {
          code: input.code,
          name: input.name,
          status: input.status,
          content: input.content,
          moreContent: input.moreContent,
          sequenceId: parse(input.sequenceId),
        },
      },
    });
  };

  return (
    <>
      <PageTitle
        title={(params.code) ? 'Kategorie bearbeiten' : 'Kategorie hinzufügen'}
        button={(
          <Button
            variant="contained"
            color="primary"
            onClick={
                onSave
            }
          >
            Speichern
          </Button>
          )}
      />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputFormField
              error={(err && input.code === '')}
              label="Code"
              name="code"
              value={input.code}
              required
              disabled={!!(params.code)}
              onChange={((e) => {
                setInput({
                  ...input,
                  code: e.target.value,
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              error={(err && input.name === '')}
              label="Name"
              name="name"
              value={input.name}
              required
              onChange={((e) => {
                setInput({
                  ...input,
                  name: e.target.value,
                });
              })}
            />
          </Grid>

          <Grid item xs={6}>
            <SelectFormField
              label="Status"
              name="status"
              value={input.status}
              options={[{ label: 'aktiv', value: 'active' }, { label: 'inaktiv', value: 'inactive' }]}
              required
              onChange={((e) => {
                setInput({
                  ...input,
                  status: e.target.value,
                });
              })}
            />
          </Grid>

          <Grid item xs={6}>
            <InputFormField
              error={(err && input.sequenceId === '')}
              inputProps={{ type: 'number' }}
              label="Sequenz"
              name="id"
              value={input.sequenceId}
              required
              onChange={((e) => {
                setInput({
                  ...input,
                  sequenceId: e.target.value,
                });
              })}
            />
          </Grid>
          <Grid item xs={12} />
        </Grid>
        <Typography variant="h6" style={{ marginTop: 30, marginBottom: 10 }}>Inhalt</Typography>
        <AceEditor
          mode="jsx"
          theme="textmate"
          onChange={(content) => setInput({ ...input, content })}
          editorProps={{ $blockScrolling: false }}
          width="100%"
          height="250px"
          value={input.content}
          enableBasicAutocompletion
          enableLiveAutocompletion
          tabSize={2}
        />

        <Typography variant="h6" style={{ marginTop: 30, marginBottom: 10 }}>Weiterer Inhalt</Typography>
        <AceEditor
          mode="jsx"
          theme="textmate"
          onChange={(moreContent) => setInput({ ...input, moreContent })}
          editorProps={{ $blockScrolling: false }}
          width="100%"
          height="250px"
          value={input.moreContent}
          enableBasicAutocompletion
          enableLiveAutocompletion
          tabSize={2}
        />
      </Paper>
    </>
  );
}
