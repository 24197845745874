import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import SelectFormField from '../../../components/forms/SelectFormField';
import { allFormsGql } from '../../Forms/gql';

export default function FormElement(props) {
  const [params, setParams] = useState(props.element?.params);

  const urlParams = useParams();
  const { companyCode, projectCode } = urlParams;

  const { loading, data } = useQuery(allFormsGql, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const onChangeParams = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(props.index, 'params', newState);
  };

  if (loading) return 'Loading';

  if (data) {
    return (
      <>
        <Grid item xs={11} md={3}>
          <Typography variant="h6">
            Formular
          </Typography>
        </Grid>
        <Grid item xs={11} md={4}>
          <SelectFormField
            label="Verknüptes Formular"
            name="code"
            required
            value={params?.code || ''}
            options={data.getAllForms?.forms?.map((form) => ({ label: form.title, value: form.code })) || []}
            onChange={({ target }) => onChangeParams('code', target.value)}
          />
        </Grid>
      </>
    );
  }
  return null;
}
