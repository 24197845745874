import { Paper, Grid, Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import AceEditor from 'react-ace';
import SelectFormField from '../../../components/forms/SelectFormField';
import PageTitle from '../../../components/PageTitle';
import { addPageGQL, getPageGQL, editPageGQL } from '../gql';
import InputFormField from '../../../components/forms/InputFormField';
import PageBottomGraphics from '../components/BottomGraphics';
import PageButton from '../components/Button';

import 'ace-builds/src-noconflict/mode-jsx';
import 'ace-builds/src-noconflict/theme-textmate';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function PageEdit() {
  const [page, setPage] = useState({ content: {}, status: 'active' });
  const history = useHistory();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const match = useRouteMatch();
  const matchEdit = useRouteMatch('/:company/:project/pages/edit/:ctaId');
  const matchCopy = useRouteMatch('/:company/:project/pages/copy/:ctaId');

  const params = useParams();
  const { loading } = useQuery(getPageGQL, {
    skip: !params.pageCode,
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      pageCode: params.pageCode,
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getPage }) => {
      matchCopy ? setPage({ ...getPage, code: '' }) : setPage(getPage);
    },
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [addPage, { loading: addStreamLoading }] = useMutation(addPageGQL, {
    onCompleted: () => {
      matchCopy ? history.replace(match.url.replace(`/copy/${params.pageCode}`, '')) : history.replace(match.url.replace('/add', ''));
      showSuccessMessage();
    },
  });
  const [updatePage, { loading: updateStreamLoading }] = useMutation(editPageGQL, {
    onCompleted: () => {
      history.replace(match.url.replace(`/edit/${params.pageCode}`, ''));
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Seite mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim Speichern der Seite');
    },
  });

  const save = (e) => {
    e.preventDefault();
    if (!matchEdit) {
      const cleanedContent = Object.fromEntries(Object.entries(page.content).filter(([_, v]) => v != null));
      addPage({
        variables: {
          companyCode: params.company,
          projectCode: params.project,
          page: { ...page, __typename: undefined, content: cleanedContent },
        },
      });
      return;
    }
    updatePage({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        pageCode: params.pageCode,
        page: { ...page, code: undefined },
      },
    });
  };

  if (loading) return (<p>Loading</p>);

  return (
    <>
      <form onSubmit={save}>
        <PageTitle
          title={matchEdit ? 'Seite bearbeiten' : 'Neue Seite hinzufügen'}
          button={(
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              {
              (addStreamLoading || updateStreamLoading)
                ? <CircularProgress size={14} style={{ color: 'white' }} /> : (matchEdit ? 'Speichern' : 'Erstellen')
              }
            </Button>
          )}
        />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputFormField
                label="Code"
                name="code"
                value={page.code}
                onChange={({ target }) => setPage({ ...page, code: target.value })}
                required
                disabled={matchEdit}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormField
                label="Name"
                name="name"
                value={page.name}
                onChange={({ target }) => setPage({ ...page, name: target.value })}
                required
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <SelectFormField
                label="Status"
                name="status"
                value={page.status}
                options={[{ label: 'Aktiv', value: 'active' }, { label: 'Inaktiv', value: 'inactive' }]}
                onChange={({ target }) => setPage({ ...page, status: target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormField
                label="Navbar-Titel"
                name="navTitle"
                value={page.navTitle || ''}
                onChange={({ target }) => setPage({ ...page, navTitle: target.value })}
              />
            </Grid>
          </Grid>

          <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>Einstellungen für Ansicht auf der Übersichtsseite</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputFormField
                label="Übersichtsbild URL"
                name="coverImg"
                type="url"
                value={page.coverImg ? decodeURI(page.coverImg) : null}
                onChange={({ target }) => setPage({ ...page, coverImg: encodeURI(target.value) })}
                required
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormField
                label="Reihenfolge"
                name="sequenceId"
                type="number"
                value={page.sequenceId}
                onChange={({ target }) => setPage({ ...page, sequenceId: parseInt(target.value) })}
                required

              />
            </Grid>
          </Grid>

          <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>Einstellungen für Ansicht auf der Detailseite</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputFormField
                label="Titelbild URL"
                name="coverImg"
                type="url"
                value={page.content.titleImg ? decodeURI(page.content.titleImg) : null}
                onChange={({ target }) => setPage({ ...page, content: { ...page.content, titleImg: encodeURI(target.value) } })}
                required
              />
            </Grid>
          </Grid>

          {/* Buttons */}
          <PageButton
            buttons={page.content && page.content.buttons}
            onChange={(buttons) => setPage({ ...page, content: { ...page.content, buttons } })}
          />

          <Typography variant="h6" style={{ marginTop: 30, marginBottom: 10 }}>Inhalt</Typography>
          <AceEditor
            mode="jsx"
            theme="textmate"
            onChange={(content) => setPage({ ...page, content: { ...page.content, content } })}
            editorProps={{ $blockScrolling: false }}
            width="100%"
            value={page.content.content}
            enableBasicAutocompletion
            enableLiveAutocompletion
            tabSize={2}
          />

          <PageBottomGraphics
            bottomGraphics={page.content && page.content.bottomGraphics}
            onChange={(bottomGraphics) => setPage({ ...page, content: { ...page.content, bottomGraphics } })}
          />

        </Paper>
      </form>
    </>
  );
}
