import { Grid, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import InputFormField from '../../../components/forms/InputFormField';

export default function PageBottomGraphics(props) {
  const [bottomGraphics, setBottomGraphics] = useState(props.bottomGraphics || []);

  const onAdd = () => {
    const newState = [...bottomGraphics];
    newState.push({ link: '', img: '', gridAttr: {} });
    setBottomGraphics(newState);
    props.onChange && props.onChange(newState);
  };
  const onDelete = (index) => {
    const newState = [...bottomGraphics];
    newState.splice(index, 1);
    setBottomGraphics(newState);
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = [...bottomGraphics];
    newState[index] = { ...newState[index], [field]: value };
    setBottomGraphics(newState);
    props.onChange && props.onChange(newState);
  };

  useEffect(() => {
    setBottomGraphics(props.bottomGraphics || []);
  }, [props.bottomGraphics]);

  return (
    <>
      <Typography variant="h6" style={{ marginTop: 30, marginBottom: 10 }}>
        Bilder am Ende der Seite
        <Button
          variant="contained"
          style={{ borderRadius: 50, minWidth: 0, marginLeft: 5, padding: '0px 8px' }}
          color="primary"
          onClick={onAdd}
        >
          +
        </Button>
      </Typography>
      {bottomGraphics && bottomGraphics.map((button, index) => (
        <Grid container spacing={3} key={index} style={{ marginBottom: '30px' }}>
          <Grid item xs={0.5}>
            <Typography style={{ marginTop: '15px' }}>
              {index + 1}
              .
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputFormField
                  label="Link"
                  name="link"
                  value={button.link ? decodeURI(button.link) : null}
                  onChange={({ target }) => onChange(index, 'link', encodeURI(target.value))}
                />
              </Grid>
              <Grid item xs={6}>
                <InputFormField
                  label="Bild"
                  name="img"
                  type="url"
                  value={button.img ? decodeURI(button.img) : null}
                  onChange={({ target }) => onChange(index, 'img', encodeURI(target.value))}
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <InputFormField
                  label="Größe (Desktop)"
                  name="md"
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 12 } }}
                  value={button.gridAttr.md}
                  onChange={({ target }) => onChange(index, 'gridAttr', { ...button.gridAttr, md: target.value })}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <InputFormField
                  label="Größe (Mobile)"
                  name="xs"
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 12 } }}
                  value={button.gridAttr.xs}
                  onChange={({ target }) => onChange(index, 'gridAttr', { ...button.gridAttr, xs: target.value, sm: target.value })}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" style={{ marginTop: '8px' }} color="secondary" onClick={() => onDelete(index)}>Löschen</Button>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
