import { gql } from '@apollo/client';

export const allPagesGQL = gql`

query getCustomPages($companyCode: String!, $projectCode: String!){
  getCustomPages(companyCode: $companyCode, projectCode: $projectCode) {
    code
    path
    status
    publishedCustomPageRevision {
      revNo
      status
      pageType {code}
      title
    }
    previewCustomPageRevision {
      revNo
      status
      title
    }
  }
}`;

export const getCustomPageGQL = gql`
query getCustomPageDetail($companyCode:String!, $projectCode: String!, $code: String!){
    getCustomPageDetail(companyCode: $companyCode, projectCode: $projectCode, code: $code) {
    code
    path
    name
    status
    publishedCustomPageRevision {
      revNo
      pageType {code}
      title
      schedulingMode
      startDate
      endDate
      accessRestrictionType
      accessRestrictionParams
      elements {
        code
        component
        params
      }
    }
    previewCustomPageRevision {
      revNo
      pageType {code}
      title
      schedulingMode
      startDate
      endDate
      accessRestrictionType
      accessRestrictionParams
      elements {
        code
        component
        params
      }
    }
  }
}`;

export const setCustomPageRevisionGQL = gql`
mutation setCustomPageRevision($companyCode: String!, $projectCode: String!, $pageCode: String!, $revision: AddCustomPageRevision, $publish: Boolean){
  setCustomPageRevision(companyCode: $companyCode, projectCode: $projectCode, pageCode: $pageCode, revision: $revision, publish: $publish)
}`;

export const updateCustomPageGQL = gql`
mutation updateCustomPage(
  $companyCode: String!, $projectCode: String!, $code: String!, $page: UpdateCustomPage!, $revision: AddCustomPageRevision, $publish: Boolean
){
  updateCustomPage(companyCode: $companyCode, projectCode: $projectCode, code: $code, page: $page)
  setCustomPageRevision(companyCode: $companyCode, projectCode: $projectCode, pageCode: $code, revision: $revision, publish: $publish)
}`;

export const deletePageGQL = gql`
mutation deletePage($companyCode: String!, $projectCode: String!, $pageCode: String!){
  deletePage(companyCode: $companyCode, projectCode: $projectCode, pageCode: $pageCode)
}`;

export const deleteCustomPageGQL = gql`
mutation deleteCustomPage($companyCode: String!, $projectCode: String!, $code: String!){
  deleteCustomPage(companyCode: $companyCode, projectCode: $projectCode, code: $code)
}`;

export const addCustomPageGQL = gql`
mutation addCustomPage($companyCode: String!, $projectCode: String!, $page: AddCustomPage!){
  addCustomPage(companyCode: $companyCode, projectCode: $projectCode, page: $page){
    code
  }
}`;

export const editorNewCustomPagesGQL = gql`
query getEditorCustomPagesData($companyCode: String!, $projectCode: String!) {
  getTicketCategories(
      companyCode: $companyCode
      projectCode: $projectCode
    ) {
      code
      name
      status
      ticketCount
      isDefault
    }
    getPageTypes(companyCode: $companyCode, projectCode: $projectCode) {
      code
      name
      status
      schedulingMode
      startDate
      endDate
      accessRestrictionType
      accessRestrictionParams
      showLogo
    }
  }
`;

export const editorExistingCustomPagesGQL = gql`
query getEditorCustomPagesData($companyCode: String!, $projectCode: String!, $code: String!) {
    getPageTypeDetail(
      companyCode: $companyCode,
      projectCode: $projectCode,
      code: $code
    ) {
      code
      name
      status
      schedulingMode
      startDate
      endDate
      accessRestrictionType
      accessRestrictionParams
      showLogo
    }
    getTicketCategories(
      companyCode: $companyCode
      projectCode: $projectCode
    ) {
      code
      name
      status
      ticketCount
      isDefault
    }
    getPageTypes(companyCode: $companyCode, projectCode: $projectCode) {
      code
      name
      status
      schedulingMode
      startDate
      endDate
      accessRestrictionType
      accessRestrictionParams
      showLogo
    }
  }
`;
