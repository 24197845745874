import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { DataGrid } from '@material-ui/data-grid';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PageTitle from '../../../components/PageTitle';
import { datagrid } from '../../../util/language';
import { GET_CATEGORIES, deleteMediaLibraryCategory } from '../gql';

export default function ShowCategories() {
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();

  const { error, loading, data, refetch } = useQuery(GET_CATEGORIES, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
  });

  const [deleteCategory] = useMutation(deleteMediaLibraryCategory, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: () => refetch() && snackbar.showMessage('Kategorie wurde erfolgreich gelöscht'),
    onError: () => snackbar.showMessage('Fehler beim Löschen des Kategorie'),
  });

  if (loading) return (<p>Loading</p>);
  if (error) return (<p>Fehler beim laden</p>);

  const columns = [
    { field: 'sequenceId', headerName: 'ID', type: 'string', flex: 0.5 },
    { field: 'code', headerName: 'Code', type: 'string', flex: 0.5 },
    { field: 'name', headerName: 'Bezeichnung', type: 'string', flex: 0.5 },
    { field: 'status', headerName: 'Status', type: 'string', flex: 0.5 },
    {
      field: 'actions',
      headerName: 'Aktionen',
      flex: 0.3,
      valueGetter: (params) => params.getValue('code'),
      renderCell: ({ value }) => (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={async (e) => {
            e.preventDefault();
            await confirm({
              description: `Wollen Sie die Kategorie mit dem Code ${value} wirklich löschen?`,
              confirmationText: 'Löschen',
              confirmationButtonProps: { color: 'secondary' },
            });
            deleteCategory({ variables: { code: value } });
          }}
        >
          Löschen
        </Button>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Kategorien"
        button={<Button variant="contained" color="primary" onClick={() => history.push(`${match.url}/add/category/`)}>Kategorie hinzufügen</Button>}
      />
      <DataGrid
        columns={columns}
        rows={data?.getAllMediaLibraryCategories?.categories || []}
        getRowId={(row) => row.code}
        componentsProps={{ toolbar: { add: true } }}
        pageSize={100}
        autoHeight
        hideFooterRowCount="false"
        localeText={datagrid}
        onCellClick={(selection) => selection.colIndex !== 4 && history.push(`${match.url}/edit/category/${selection.row.id}`)}
      />
    </>
  );
}
