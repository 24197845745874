import { gql } from '@apollo/client';

export const allCTAGQL = gql`
query getAllConversionCards($companyCode: String!, $projectCode: String!) {
    getAllConversionCards(companyCode: $companyCode, projectCode: $projectCode) {
      conversionCards {
        code
        name
        posted
        sequenceId
        pageCode
      }
    }
  }`;

export const getCTAGQL = gql`
query getConversionCard($companyCode: String!, $projectCode: String!, $code: String!){
  getConversionCard(companyCode: $companyCode, projectCode: $projectCode, code: $code){
    code
    name
    type
    params  
    sequenceId
    posted
    pageCode
    }
}`;

export const getProjectGQL = gql`
query getProject($companyCode: String!, $projectCode: String!){
  getProject(companyCode: $companyCode, projectCode: $projectCode) {
    projectCode
    type
    domain
  }
}`;

export const addCTAGQL = gql`
mutation createConversionCard($companyCode: String!, $projectCode: String!, $cta: ConversionCardInput!){
  createConversionCard(companyCode: $companyCode, projectCode: $projectCode, conversionCard:$cta)    
}`;

export const editCTAGQL = gql`
mutation updateConversionCard($companyCode: String!, $projectCode: String!, $cta: UpdateConversionCardInput!, $code: String!){
  updateConversionCard(companyCode: $companyCode, projectCode: $projectCode, conversionCard:$cta, code: $code)
}`;

export const deleteCTAGQL = gql`
mutation deleteConversionCard($companyCode: String!, $projectCode: String!, $code:String!){
  deleteConversionCard(companyCode: $companyCode, projectCode: $projectCode, code:$code)
  }`;

export const updateMultipleConversionCardsGQL = gql`
mutation updateMultipleConversionCards(
  $companyCode: String!, 
  $projectCode: String!, 
  $conversionCards: [UpdateMultipleConversionCardInput!]!) 
  { updateMultipleConversionCards( companyCode: $companyCode projectCode: $projectCode conversionCards: $conversionCards ) }
`;

export const allFormsGql = gql`
query getAllForms($companyCode: String!, $projectCode: String!) {
  getAllForms(companyCode: $companyCode, projectCode: $projectCode) {
      forms {
        formCode: code
        title
      }
    }
  }`;

export const allEmojisGql = gql`
query getAllEmojis($companyCode: String!, $projectCode: String!) {
  getAllEmojis(companyCode: $companyCode, projectCode: $projectCode) {
    code
    name
    image
    sequenceId
    status
  }
}`;

export const allPollsGql = gql`
query getAllPolls($companyCode: String!, $projectCode: String!) {
  getAllPolls(companyCode: $companyCode, projectCode: $projectCode) {
      polls {
        pollCode: code
        name
        status
      }
    }
  }`;

export const getConfigGql = gql`
  query getConfig($companyCode: String!, $projectCode: String!){
    getConfig(companyCode:$companyCode, projectCode:$projectCode)
}`;

export const allPagesGQL = gql`
  query getCustomPages($companyCode: String!, $projectCode: String!){
    getCustomPages(companyCode: $companyCode, projectCode: $projectCode) {
      code
      path
      name
      status
    }
  }
`;
