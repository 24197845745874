import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import SelectFormField from '../../../components/forms/SelectFormField';
import { allStreamsGQL } from '../../Streams/gql';

export default function StreamWithCtaElement(props) {
  const [params, setParams] = useState(props.element?.params);

  const urlParams = useParams();
  const { companyCode, projectCode } = urlParams;

  const { loading, data } = useQuery(allStreamsGQL, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const onChangeParams = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(props.index, 'params', newState);
  };

  if (loading) return 'Loading';

  if (data) {
    return (
      <>
        <Grid item xs={11} md={3}>
          <Typography variant="h6">
            Stream mit Karten
          </Typography>
        </Grid>
        <Grid item xs={11} md={4}>
          <SelectFormField
            label="Verknüpter Stream"
            name="code"
            required
            value={params?.code || ''}
            options={data.getAllStreams?.streams?.map((stream) => ({ label: stream.code, value: stream.code })) || []}
            onChange={({ target }) => onChangeParams('code', target.value)}
          />
        </Grid>
      </>
    );
  }
  return null;
}
