import { gql } from '@apollo/client';

export const allPageTypesGQL = gql`
  query getPageTypes($companyCode: String!, $projectCode: String!) {
    getPageTypes(companyCode: $companyCode, projectCode: $projectCode) {
      code
      name
      status
      schedulingMode
      startDate
      endDate
      accessRestrictionType
      accessRestrictionParams
      showLogo
    }
}`;

export const getPageTypeDetailGQL = gql`
  query getPageTypeDetail($companyCode: String!, $projectCode: String!, $code: String!) {
    getPageTypeDetail(companyCode: $companyCode, projectCode: $projectCode, code: $code) {
      code
      name
      status
      schedulingMode
      startDate
      endDate
      accessRestrictionType
      accessRestrictionParams
      showLogo
    }
  }
`;

export const addPageTypeGQL = gql`
  mutation addPageType($companyCode: String!, $projectCode: String!, $pageType: AddPageType!) {
    addPageType(companyCode: $companyCode, projectCode: $projectCode, pageType: $pageType) {
      code
    }
  }
`;

export const updatePageTypeGQL = gql`
  mutation updatePageType($companyCode: String!, $projectCode: String!, $code: String!, $pageType: AddPageType!) {
    updatePageType(companyCode: $companyCode, projectCode: $projectCode, code: $code, pageType: $pageType) 
  }
`;

export const deletePageTypeGQL = gql`
  mutation deletePageType($companyCode: String!, $projectCode: String!, $code: String!){
    deletePageType(companyCode: $companyCode, projectCode: $projectCode, code: $code)
}`;
