import { gql } from '@apollo/client';

export const allEmojisGql = gql`
query getAllEmojis($companyCode: String!, $projectCode: String!) {
  getAllEmojis(companyCode: $companyCode, projectCode: $projectCode) {
    code
    name
    image
    sequenceId
    status
  }
}`;

export const emojiGql = gql`
query getEmoji($companyCode: String!, $projectCode: String!, $code: String!) {
  getEmoji(companyCode: $companyCode, projectCode: $projectCode, code: $code) {
    code
    name
    image
    sequenceId
    status
  }
}`;

export const addEmojiGql = gql`
mutation addEmoji($companyCode: String!, $projectCode: String!, $emoji: AddEmoji!){
  addEmoji(companyCode: $companyCode, projectCode: $projectCode, emoji: $emoji)
}`;

export const updateEmojiGql = gql`
mutation updateEmoji($companyCode: String!, $projectCode: String!, $code: String!, $emoji: UpdateEmoji!){
  updateEmoji(companyCode: $companyCode, projectCode: $projectCode, code: $code, emoji: $emoji)
}`;

export const deleteEmojiGql = gql`
mutation deleteEmoji($companyCode: String!, $projectCode: String!, $code: String!){
  deleteEmoji(companyCode: $companyCode, projectCode: $projectCode, code: $code)    
}`;
