import { Paper, Grid, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SelectFormField from '../../../components/forms/SelectFormField';
import PageTitle from '../../../components/PageTitle';

import { getTicketCategoryGQL, addTicketCategoryMutation, updateTicketCategoryMutation } from '../gql';
import InputFormField from '../../../components/forms/InputFormField';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function TicketCategoryEdit() {
  const [ticketCategory, setTicketCategory] = useState({ status: 'active', isDefault: false });
  const history = useHistory();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const params = useParams();

  const { refetch, loading } = useQuery(getTicketCategoryGQL, {
    skip: !params.ticketCategoryCode,
    variables: { companyCode: params.company, projectCode: params.project, code: params.ticketCategoryCode },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getTicketCategory }) => setTicketCategory(getTicketCategory),
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const showErrorMessage = (err) => {
    if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Ein Stream mit dem selben Code existiert bereits');
    return snackbar.showMessage('Fehler beim speichern des Streams');
  };

  const [addTicketCategory, { loading: addTicketCategoryLoading }] = useMutation(addTicketCategoryMutation, {
    onCompleted: () => {
      history.replace(`/${params.company}/${params.project}/ticketCategories`);
      showSuccessMessage();
    },
    onError: (err) => showErrorMessage(err),
  });
  const [updateTicketCategory, { loading: updateTicketCategoryLoading }] = useMutation(updateTicketCategoryMutation, {
    onCompleted: () => {
      refetch();
      showSuccessMessage();
    },
    onError: () => showErrorMessage(),
  });

  const save = (e) => {
    e.preventDefault();
    if (!params.ticketCategoryCode) {
      addTicketCategory({
        variables: {
          companyCode: params.company,
          projectCode: params.project,
          ticketCategory: { ...ticketCategory, __typename: undefined },
        },
      });
      return;
    }
    updateTicketCategory({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        code: params.ticketCategoryCode,
        ticketCategory: {
          ...ticketCategory,
          __typename: undefined,
          code: undefined,
          ticketCount: undefined,
        },
      },
    });
  };

  if (loading || (params.ticketCategoryCode && !ticketCategory.code)) return (<p>Loading</p>);

  return (
    <form onSubmit={save}>
      <PageTitle
        title={params.ticketCategoryCode ? 'Ticketkategorie bearbeiten' : 'Neuen Ticketkategorie hinzufügen'}
        button={(
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            {(addTicketCategoryLoading || updateTicketCategoryLoading)
              ? <CircularProgress size={14} /> : (params.ticketCategoryCode ? 'Speichern' : 'Erstellen')}
          </Button>
        )}
      />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputFormField
              label="Code"
              name="code"
              value={ticketCategory.code}
              onChange={({ target }) => setTicketCategory({ ...ticketCategory, code: target.value })}
              disabled={params.ticketCategoryCode}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              label="Name"
              name="name"
              value={ticketCategory.name}
              onChange={({ target }) => setTicketCategory({ ...ticketCategory, name: target.value })}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SelectFormField
              label="Status"
              name="status"
              value={ticketCategory.status}
              required
              options={[
                { label: 'Aktiv', value: 'active' },
                { label: 'Inaktiv', value: 'inactive' },
              ]}
              onChange={({ target }) => setTicketCategory({ ...ticketCategory, status: target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectFormField
              label="Ist Standardkategorie?"
              name="isDefault"
              value={ticketCategory.isDefault}
              required
              options={[
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
              ]}
              onChange={({ target }) => setTicketCategory({ ...ticketCategory, isDefault: target.value === 'true' })}
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
}
