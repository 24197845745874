import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Fab } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory, useLocation, useParams } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getSubmissionsForSpeakerGql, formGql } from '../gql';
import DialogFieldSelection from '../components/DialogFieldSelection';

const TITLE = 'WIRKUNG';
// import Search from "./Search"

const useStyles = makeStyles((theme) => ({
  aligning: {
    display: 'flex',
    justifyContent: 'flex-end', // oder 'flex-start'
    flexDirection: 'column', // oder 'row'Did you mean: font awesome pin
  },
  gridRefresh: {
    position: 'fixed',
    zIndex: 1,
  },
  cardParticipant: {
    backgroundColor: '#007aff',
    color: 'white',
    fontSize: '17pt',
  },
  cardModerator: {
    backgroundColor: '#f1f0f0',
    fontSize: '17pt',
  },
  tableColumn: {
    paddingRight: '20px',
    height: '40px',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  flexbox: {
    display: 'flex',
    position: 'fixed',
    top: theme.spacing(2),
    width: '100%',
    justifyContent: 'center',
  },
  container: {
    marginTop: theme.spacing(2),
  },
  containerWithMargin: {
    marginTop: 70,
    [theme.breakpoints.up('md')]: {
      marginTop: 95,
    },
  },
  fabProgress: {
    position: 'absolute',
    top: 15,
    left: 15,
    color: 'white',
    zIndex: 1,
  },
  hrParticipant: {
    height: '2px',
    backgroundColor: 'white',
    border: 'none',
  },
  hrModerator: {
    height: '2px',
    backgroundColor: 'black',
    border: 'none',
  },
}));

export default function SpeakerView() {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [fieldsToShow, setFieldsToShow] = useState([]);
  const [settings, setSettings] = useState({});
  const [customLoading, setCustomLoading] = useState(true);

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { data, startPolling, stopPolling, refetch, loading } = useQuery(getSubmissionsForSpeakerGql, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      code: params.code,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const { data: formData } = useQuery(formGql, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      code: params.code,
    },
    onCompleted: ({ getForm }) => {
      const key = `form-speaker-fields-${[params.company, params.project, params.code].join('-')}`;
      if (!query.has('fields') && !localStorage.getItem(key)) return setFieldsToShow(getForm.fields);

      const queryIds = query.get('fields')?.split(',')?.map((id) => parseInt(id));
      // load saved fields
      const fieldIds = queryIds || localStorage.getItem(key);
      return setFieldsToShow(getForm.fields.filter(({ id }) => fieldIds.includes(id)));
    },
  });
  const renderValue = (submissionField, field) => {
    let content = submissionField?.updatedContent || submissionField?.content;
    if (['checkbox', 'anonymCheckbox'].includes(field.type)) content = content === 'true' ? '✅' : '❌';
    if (field.type === 'number') content = parseInt(content).toLocaleString('de-de');
    return content;
  };

  useEffect(() => {
    if (loading) {
      setCustomLoading(true);
      return;
    }
    setTimeout(() => setCustomLoading(false), 500);
  }, [loading]);

  useEffect(() => {
    if (!settings?.polling) stopPolling();
    if (settings?.polling) startPolling(3000);
    return () => {
      stopPolling();
    };
  }, [settings]);

  useEffect(() => {
    for (const parameter of query.entries()) {
      localStorage.setItem(`form-speaker-setting-${[params.company, params.project, params.code, parameter[0]].join('-')}`, parameter[1]);
    }
    const entries = Object.fromEntries(query.entries());
    entries.polling = (entries.polling === 'true');
    setSettings(entries);
  }, []);

  const colors = {
    cardParticipant: { backgroundColor: `#${settings.colorPB}`, color: `#${settings.colorPF}` },
    cardModerator: { backgroundColor: `#${settings.colorMB}`, color: `#${settings.colorMF}` },
    hrParticipant: { backgroundColor: `#${settings.colorPF}` },
    hrModerator: { backgroundColor: `#${settings.colorMF}` },
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div>
        {!settings?.polling
          && (
          <div className={classes.flexbox}>
            <Fab color="secondary" onClick={() => refetch()}>
              {(customLoading) ? <CircularProgress size={25} className={classes.fabProgress} /> : <RefreshIcon />}
            </Fab>
          </div>
          )}
        <Container maxWidth="lg" className={settings?.polling ? classes.container : classes.containerWithMargin}>
          <Grid container item spacing={3} sm={12} md={12} lg={12}>
            {data?.getFormSubmissionsForSpeaker?.submissions?.map((val) => (
              <>
                {val.authorType !== 'customer' ? <Grid item xs={12} md={4} /> : null}
                <Grid item xs={12} md={8}>
                  <Card
                    className={val.authorType === 'customer' ? classes.cardParticipant : classes.cardModerator}
                    style={val.authorType === 'customer' ? colors.cardParticipant : colors.cardModerator}
                  >
                    <CardContent>
                      <Typography variant="h5" style={{ fontSize: (24 * (settings.fontScale / 100)) }} color="text.secondary" gutterBottom>
                        {val.author}
                        <span style={{ float: 'right' }}>{moment(val.createdAt).format('HH:mm')}</span>
                      </Typography>
                      <hr
                        className={val.authorType === 'customer' ? classes.hrParticipant : classes.hrModerator}
                        style={val.authorType === 'customer' ? colors.hrParticipant : colors.hrModerator}
                      />
                      <table>
                        {fieldsToShow.map((field) => {
                          const submissionField = val?.fields?.find(({ formFieldId }) => formFieldId === field.id);
                          if (!submissionField?.updatedContent && !submissionField?.content && field.type !== 'checkbox') return null;
                          return (
                            <tr>
                              <td
                                className={classes.tableColumn}
                                style={{ fontSize: (22 * (settings.fontScale / 100)) }}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{ __html: `${field.label}:` }}
                              />
                              <td style={{ fontSize: (22 * (settings.fontScale / 100)) }}>{renderValue(submissionField, field)}</td>
                            </tr>
                          );
                        })}
                      </table>
                    </CardContent>
                  </Card>
                </Grid>
                {val.authorType === 'customer' ? <Grid item xs={12} md={4} /> : null}
              </>
            ))}
          </Grid>
        </Container>
        <Fab className={classes.fab} onClick={() => setSettingsModalOpen(true)}>
          <SettingsIcon />
        </Fab>
      </div>
      <DialogFieldSelection
        open={settingsModalOpen}
        fields={formData?.getForm?.fields || []}
        selectedFields={fieldsToShow}
        showSettings
        settings={settings}
        onClose={() => setSettingsModalOpen(false)}
        onFinish={(selectedFields, newSettings) => {
          setSettings(newSettings);
          setSettingsModalOpen(false);
          setFieldsToShow(selectedFields);
          const key = `form-speaker-fields-${[params.company, params.project, params.code].join('-')}`;
          const ids = selectedFields.map(({ id }) => id);
          localStorage.setItem(key, ids);
          const settingsParameter = [];
          for (const row of Object.keys(newSettings)) {
            const value = newSettings[row];
            localStorage.setItem(`form-speaker-setting-${[params.company, params.project, params.code, row].join('-')}`, value);
            settingsParameter.push(`${row}=${value}`);
          }
          history.replace({
            search: `?fields=${ids.join(',')}${settingsParameter.length && `&${settingsParameter.join('&')}`}`,
          });
        }}
      />
    </>
  );
}
