import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';

export default function AccessRestriction({ currentPageRevision, onChange, accessRestrictionData, loading }) {
  const displayedInputField = useMemo(() => {
    if (loading) return (<Skeleton height={59} />);
    switch (currentPageRevision?.accessRestrictionType) {
      case 'inherited':
        if (accessRestrictionData?.getPageTypeDetail?.accessRestrictionType === 'password') {
          return (
            <InputFormField
              label="Passwort"
              name="password"
              value={accessRestrictionData?.getPageTypeDetail?.accessRestrictionParams?.password}
              disabled
              helperText="vom Seitentyp geerbt"
            />
          );
        }
        if (accessRestrictionData?.getPageTypeDetail?.accessRestrictionType === 'ticketCategory') {
          return (
            <SelectFormField
              label="Berechtigte Tickets"
              name="ticketCategoryAccess"
              multiple
              value={accessRestrictionData?.getPageTypeDetail?.accessRestrictionParams?.ticketCategories}
              options={accessRestrictionData?.getTicketCategories?.map((cat) => ({ label: cat.name, value: cat.code })) || []}
              disabled
              helperText="vom Seitentyp geerbt"
            />
          );
        }
        break;
      case 'password':
        return (
          <InputFormField
            label="Passwort"
            name="password"
            value={currentPageRevision?.accessRestrictionParams?.password}
            onChange={({ target }) => onChange({ ...currentPageRevision, accessRestrictionParams: { password: target.value } })}
            required
          />
        );
      case 'ticketCategory':
        return (
          <SelectFormField
            label="Berechtigte Tickets"
            name="ticketCategoryAccess"
            multiple
            value={currentPageRevision?.accessRestrictionParams?.ticketCategories?.length
              ? currentPageRevision?.accessRestrictionParams?.ticketCategories
              : []}
            options={accessRestrictionData?.getTicketCategories?.map((cat) => ({ label: cat.name, value: cat.code }))
            || []}
            onChange={({ target }) => onChange(
              { ...currentPageRevision,
                accessRestrictionParams: {
                  ...currentPageRevision?.accessRestrictionParams,
                  ticketCategories: target.value,
                },
              },
            )}
            required
          />
        );
      default:
        return null;
    }

    return null;
  }, [
    currentPageRevision.accessRestrictionType,
    currentPageRevision.accessRestrictionParams?.password,
    currentPageRevision.accessRestrictionParams?.ticketCategories,
    loading,
  ]);

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" style={{ marginBottom: -10, fontWeight: 'normal' }}>Zugriffsbeschänkung</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        {loading ? <Skeleton height={59} /> : (
          <SelectFormField
            label="Typ"
            name="accessRestrictionType"
            value={currentPageRevision.accessRestrictionType}
            options={[
              { label: 'Offen', value: 'open' },
              { label: 'Von Seitentyp übernehmen', value: 'inherited' },
              { label: 'Von Projekt übernehmen', value: 'event' },
              { label: 'Ticketkategorien einschränken', value: 'ticketCategory' },
              { label: 'Mit Passwort schützen', value: 'password' }]}
            onChange={({ target }) => onChange({ ...currentPageRevision, accessRestrictionType: target.value })}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {displayedInputField}
      </Grid>
    </>
  );
}
