import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import InputFormField from '../../../components/forms/InputFormField';

export default function YoutubeParams(props) {
  const [params, setParams] = useState(props.params);

  const onChange = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(newState);
  };

  return (
    <>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>YouTube Stream Parameter</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InputFormField
            label="Video ID"
            name="videoId"
            value={params?.videoId}
            onChange={({ target }) => onChange('videoId', target.value)}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
