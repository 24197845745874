import { Button, Paper, Tooltip, IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import { allStreamsGQL, deleteStreamGql } from './gql';
import LoadingTable from '../../components/LoadingTable';

export default function Streams() {
  const history = useHistory();
  const params = useParams();
  const { companyCode, projectCode } = params;
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();

  const { loading, data, error, refetch } = useQuery(allStreamsGQL, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const [deleteStream] = useMutation(deleteStreamGql, {
    variables: { companyCode, projectCode },
    onCompleted: () => {
      refetch();
      snackbar.showMessage('Erfolgreich gelöscht');
    },
    onError: () => snackbar.showMessage('Fehler beim löschen des Streams'),
  });

  if (error) return (<p>Fehler beim laden</p>);

  const columns = [
    { field: 'code', headerName: 'Code', type: 'string', flex: 0.5 },
    { field: 'streamId', headerName: '', hide: true, type: 'string', flex: 0.5 },
    { field: 'platform', headerName: 'Platform', type: 'string', flex: 0.5 },
    { field: 'type', headerName: 'Type', type: 'string', flex: 0.5 },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 120,
      renderCell: ({ row }) => {
        if (row.type === 'main') return null;
        return (
          <Tooltip title="Löschen">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                await confirm({
                  description: `Wollen Sie den Stream mit dem Code ${row.code} wirklich löschen?`,
                  confirmationText: 'Löschen',
                  confirmationButtonProps: { color: 'secondary' },
                });
                deleteStream({ variables: { streamId: row.streamId } });
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <PageTitle
        title="Streams"
        button={(
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`${match.url}/add`)}
          >
            Erstellen
          </Button>
        )}
      />
      {loading ? <LoadingTable columns={columns.length} /> : (
        <Paper>
          <DataGrid
            columns={columns}
            rows={data?.getAllStreams?.streams || []}
            getRowId={(row) => row.streamId}
            componentsProps={{ toolbar: { add: true } }}
            pageSize={100}
            autoHeight
            hideFooterRowCount="false"
            localeText={datagrid}
            onCellClick={(selection) => selection.colIndex !== 3 && history.push(`${match.url}/edit/${selection.row.id}`)}
          />
        </Paper>
      )}
    </>
  );
}
