import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoginForm from '../components/LoginForm';

const TITLE = 'Login - Wirkung Plattform';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10vh',
  },

}));

export default function LoginPage() {
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Container>
        <Grid
          className={classes.root}
          container
          spacing={0}
          justify="center"
          direction="row"
        >
          <Grid item xs={12} sm={6} md={5} lg={5} xl={5} style={{ textAlign: 'center' }}>
            <img
              src="https://public.storage.wirkung.stream/_platform/WD_logo.svg"
              alt=""
              style={{ width: '30%', marginBottom: 10 }}
            />
            <LoginForm />
          </Grid>
        </Grid>
      </Container>

    </>
  );
}
