import { Button, Paper, IconButton, Tooltip } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import DeleteIcon from '@material-ui/icons/Delete';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import {
  getTicketCategoriesGQL,
  deleteTicketCategoryMutation,
} from './gql';

export default function TicketCategories() {
  const params = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  const snackBar = useSnackbar();
  const confirm = useConfirm();

  const { loading, data, error, refetch } = useQuery(getTicketCategoriesGQL, {
    fetchPolicy: 'no-cache',
    variables: {
      companyCode: params.company,
      projectCode: params.project,
    },
  });

  const [deleteTicketCategory] = useMutation(deleteTicketCategoryMutation, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: () => {
      refetch();
      snackBar.showMessage('Die Ticketkategory wurde erfolgreich gelöscht');
    },
    onError: () => {
      refetch();
      snackBar.showMessage('Fehler beim Löschen der Ticketkategory');
    },
  });

  if (error) return <p>Fehler beim laden</p>;

  const columns = [
    { field: 'code', headerName: 'Code', type: 'string', flex: 0.5 },
    { field: 'name', headerName: 'Name', type: 'string', flex: 0.5 },
    { field: 'ticketCount', headerName: 'Ticket mit der Kategorie', type: 'string', flex: 0.5 },
    {
      field: 'isDefault',
      headerName: 'Ist Standardkategorie?',
      type: 'string',
      flex: 0.2,
      valueFormatter: ({ value }) => (value ? 'Ja' : 'Nein') },
    {
      field: 'actions',
      headerName: 'Aktionen',
      flex: 0.2,
      renderCell: (params) => (
        <>
          {!params.row.ticketCount && !params.row.isDefault
            && (
            <Tooltip title="Kategorie löschen">
              <IconButton
                onClick={async () => {
                  await confirm({
                    description: `Wollen Sie die Kategorie ${params.getValue('name')} wirklich löschen?`,
                    confirmationText: 'Löschen',
                    confirmationButtonProps: { color: 'secondary' },
                  });
                  await deleteTicketCategory({
                    variables: {
                      code: params.getValue('code'),
                    },
                  });
                  refetch();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            )}
        </>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Ticketkategorien"
        button={(
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push(`${match.url}/add`)}
            >
              Erstellen
            </Button>
          </div>
        )}
      />
      <Paper elevation={3} style={{ padding: 20 }}>
        <DataGrid
          loading={loading}
          style={{ marginTop: '20px' }}
          columns={columns}
          rows={data?.getTicketCategories || []}
          getRowId={(row) => row.code}
          pagination={false}
          autoHeight
          hideFooterRowCount="false"
          localeText={datagrid}
          onCellClick={(selection) => selection.colIndex !== 3 && history.push(`${match.url}/edit/${selection.row.id}`)}
        />
      </Paper>
    </>
  );
}
