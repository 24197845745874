import React, { useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SelectFormField from '../../../components/forms/SelectFormField';
import PageTitle from '../../../components/PageTitle';
import { addCustomPageGQL, getCustomPageGQL, updateCustomPageGQL } from '../gql';
import InputFormField from '../../../components/forms/InputFormField';
import PageDetails from '../components/PageDetails';
import SectionLayout from '../../../components/SectionLayout';

const defaults = {
  page: {
    path: '/',
    status: 'draft',
    currentPageRevision: {
      schedulingMode: 'inherited',
      accessRestrictionType: 'event',
    },
  },
};

export default function CustomPageEdit() {
  const history = useHistory();
  const match = useRouteMatch();
  const matchEdit = useRouteMatch('/:companyCode/:projectCode/customPages/edit/:code');
  const matchCopy = useRouteMatch('/:companyCode/:projectCode/customPages/copy/:code');
  const matchAdd = useRouteMatch('/:companyCode/:projectCode/customPages/add');
  const params = useParams();
  const { companyCode, projectCode, code } = params;
  const theme = useTheme();
  const snackbar = useSnackbar();
  const [page, setPage] = useState(defaults.page);
  const [chip, setChip] = useState({ style: { backgroundColor: theme.palette.error.dark, color: '#fff', fontWeight: 'bold' }, label: 'Entwurf' });
  const [eventSubmitter, setEventSubmitter] = useState();
  const [isPathManualTyped, setIsPathManualTyped] = useState(false);

  const applyFetchedData = (page) => {
    const currentPageRevision = page?.previewCustomPageRevision || page?.publishedCustomPageRevision;

    if (matchCopy) {
      return setPage({ ...page, code: undefined, path: '/', currentPageRevision });
    }

    if (page?.publishedCustomPageRevision && !(page?.previewCustomPageRevision)) {
      setChip({ style: { backgroundColor: theme.palette.success.dark, color: '#fff', fontWeight: 'bold' }, label: 'Veröffentlicht' });
    }

    return setPage({ ...page, currentPageRevision });
  };

  const { loading, refetch } = useQuery(getCustomPageGQL, {
    skip: !code,
    variables: { companyCode, projectCode, code },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getCustomPageDetail }) => applyFetchedData(getCustomPageDetail),
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [addPage, { loading: addStreamLoading }] = useMutation(addCustomPageGQL, {
    onCompleted: () => {
      matchCopy ? history.replace(match.url.replace(`/copy/${code}`, '')) : history.replace(match.url.replace('/add', ''));
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Seite auf demselben Pfad existiert bereits');
      return snackbar.showMessage('Fehler beim Speichern der Seite');
    },
  });

  const [updateCustomPage, { loading: updatePageStreamLoading }] = useMutation(updateCustomPageGQL, {
    onCompleted: () => {
      // history.replace(match.url.replace(`/edit/${code}`, ''));
      refetch();
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 404) return snackbar.showMessage('Die Seite, die du speichern möchtest, existiert nicht');
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Seite mit demselben Pfad existiert bereits');
      return snackbar.showMessage('Fehler beim Speichern der Seite');
    },
  });

  const handleNameAndPathInput = (changes) => {
    const updates = { ...changes };

    if (typeof updates.path === 'string') {
      if (updates.path === '') updates.path = '/';
      if (!/^\/[0-9/A-Za-z_-]*$/.test(updates.path)) return;
      if (!isPathManualTyped) setIsPathManualTyped(true);
      if (updates.path === '/') setIsPathManualTyped(false);
    }

    if (updates.name && !isPathManualTyped) {
      updates.path = `/${updates.name.replaceAll(' ', '-').replaceAll(/[^0-9/A-Za-z_-]/g, '').toLowerCase()}`;
    }

    setPage({ ...page, ...updates });
  };

  const save = (e) => {
    e.preventDefault();
    setEventSubmitter(e.nativeEvent.submitter.name);

    let elements = page.currentPageRevision.elements || [];
    elements = elements.map(({ code, ...rest }) => rest);

    const revision = {
      ...page.currentPageRevision,
      __typename: undefined,
      revNo: undefined,
      pageType: page?.currentPageRevision?.pageType,
      elements,
    };

    if (matchAdd?.isExact) {
      addPage({
        variables: {
          companyCode,
          projectCode,
          page: {
            ...page,
            revision,
            currentPageRevision: undefined,
          },
        },
      });
    }

    if (matchCopy?.isExact) {
      addPage({
        variables: {
          companyCode,
          projectCode,
          page: {
            ...page,
            revision,
            currentPageRevision: undefined,
            previewCustomPageRevision: undefined,
            publishedCustomPageRevision: undefined,
            code: undefined,
          },
        },
      });
    }

    if (matchEdit?.isExact) {
      updateCustomPage({
        variables: {
          companyCode,
          projectCode,
          code,
          page: {
            name: page.name,
            path: page.path,
            status: page.status,
          },
          publish: e.nativeEvent.submitter.name === 'saveAndPublish',
          revision: {
            ...page.currentPageRevision,
            __typename: undefined,
            revNo: undefined,
            pageType: page?.currentPageRevision?.pageType,
            elements,
          },
        },
      });
    }
  };

  const buttons = useMemo(() => {
    if (matchEdit) {
      return [
        {
          caption: 'Speichern',
          color: 'primary',
          type: 'submit',
          name: 'save',
          loading: (addStreamLoading || updatePageStreamLoading) && eventSubmitter === 'save',
          disabled: addStreamLoading || updatePageStreamLoading,
        },
        {
          caption: 'Speichern und Veröffentlichen',
          color: 'primary',
          type: 'submit',
          name: 'saveAndPublish',
          loading: (addStreamLoading || updatePageStreamLoading) && eventSubmitter === 'saveAndPublish',
          disabled: addStreamLoading || updatePageStreamLoading,
        },
      ];
    }

    return [
      {
        caption: 'Erstellen',
        color: 'primary',
        type: 'submit',
        name: 'save',
        loading: addStreamLoading || updatePageStreamLoading,
      },
    ];
  }, [addStreamLoading, updatePageStreamLoading, eventSubmitter]);

  if (loading) return (<p>Loading</p>);

  return (
    <form onSubmit={save}>
      <PageTitle
        title={matchEdit ? 'Seite bearbeiten' : 'Neue Seite hinzufügen'}
        chip={chip}
        buttons={buttons}
      />
      <SectionLayout>
        <Typography variant="h5" style={{ marginBottom: 10 }}>Meta Daten</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputFormField
              label="Name"
              name="name"
              value={page?.name}
              onChange={({ target }) => handleNameAndPathInput({ name: target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputFormField
              label="Pfad"
              name="path"
              value={page?.path}
              onChange={({ target }) => handleNameAndPathInput({ path: target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectFormField
              label="Status"
              name="status"
              value={page.status}
              options={[
                { label: 'Erreichbar', value: 'published' },
                { label: 'Nicht erreichbar', value: 'draft' },
              ]}
              onChange={({ target }) => setPage({ ...page, status: target.value })}
            />
          </Grid>
        </Grid>
      </SectionLayout>
      <PageDetails
        currentPageRevision={page.currentPageRevision}
        onChange={(pageRevision) => setPage({ ...page, currentPageRevision: { ...pageRevision } })}
      />
    </form>
  );
}
