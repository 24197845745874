import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import InputFormField from '../../../components/forms/InputFormField';
import SelectFormField from '../../../components/forms/SelectFormField';
import { allFormsGql } from '../gql';

export default function BasicCTAParams({ params, onChange, formAction }) {
  const [forms, setForms] = useState([]);
  const pageParams = useParams();

  const { companyCode, projectCode } = pageParams;

  const { loading: loadingForms } = useQuery(allFormsGql, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getAllForms }) => {
      const formCodes = getAllForms.forms.map(({ title, formCode }) => ({ label: title, value: formCode }));
      setForms(formCodes);
    },
  });

  const createFormOption = {
    label: 'Neues Formular anlegen',
    value: 'create',
    style: { color: 'gray' },
  };

  if (loadingForms) return (<p>Loading</p>);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Aktion</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectFormField
          label="Bei Klick"
          name="action"
          value={params?.actionType}
          options={[
            { label: 'Keine Aktion', value: 'none' },
            { label: 'Seite öffnen', value: 'link' },
            { label: 'Seite aus Linkliste öffnen', value: 'linkRotation' },
            { label: 'Formular öffnen', value: 'form' },
          ]}
          onChange={({ target }) => onChange({ ...params, actionType: target.value })}
        />
      </Grid>
      {params?.actionType !== 'none' && (
      <>
        <Grid item xs={12} md={6}>
          <SelectFormField
            label="Klickbares Element"
            name="actionEmitter"
            required
            value={params?.actionEmitter}
            options={[
              { label: 'Bild', value: 'image' },
              { label: 'Button', value: 'button' },
            ]}
            onChange={({ target }) => onChange({ ...params, actionEmitter: target.value })}
          />
        </Grid>
        {params?.actionEmitter === 'button'
          && (
          <Grid item xs={12} md={6}>
            <InputFormField
              label="Button Label"
              name="buttonLabel"
              inputProps={{
                maxLength: 75,
              }}
              value={params?.buttonLabel}
              onChange={({ target }) => onChange({ ...params, buttonLabel: target.value })}
            />
          </Grid>
          )}
      </>
      )}
      {params?.actionType === 'link'
      && (
        <Grid item xs={12} md={6}>
          <InputFormField
            label="Link URL"
            name="link"
            required
            inputProps={{
              maxLength: 500,
            }}
            type="url"
            value={params?.link}
            placeholder="https://wirkung.group/my-landing-page"
            onChange={({ target }) => onChange({ ...params, link: target.value })}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={params.openNewTab || false}
                onChange={({ target }) => onChange({ ...params, openNewTab: target.checked })}
                color="primary"
              />
            )}
            label="Im neuen Tab öffnen"
          />
        </Grid>
      )}
      {!(params?.actionType === 'none' || params?.actionType === 'form') && (
      <Grid item xs={12} md={6}>
        <InputFormField
          label="Aria Label"
          name="ariaLabel"
          inputProps={{
            maxLength: 75,
          }}
          value={params?.ariaLabel}
          onChange={({ target }) => onChange({ ...params, ariaLabel: target.value })}
        />
      </Grid>
      )}
      {params?.actionType === 'form'
      && (
        <>
          <Grid item xs={12} md={6}>
            <SelectFormField
              label="Formular"
              name="formCode"
              value={params?.formCode}
              options={[...forms, createFormOption]}
              onChange={({ target }) => formAction(target)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectFormField
              label="Formular automatisch öffnen"
              name="autoOpen"
              value={params?.autoOpen}
              options={[
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' },
              ]}
              onChange={({ target }) => onChange({ ...params, autoOpen: target.value })}
            />
          </Grid>
        </>
      )}
    </>
  );
}
