import React, { useState } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { getMailTemplates, setMailTemplates, getSettings, setSettings, clearMailTemplateCache } from '../gql';
import PageTitle from '../../../components/PageTitle';
import InputFormField from '../../../components/forms/InputFormField';
import SelectFormField from '../../../components/forms/SelectFormField';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function MailSettings() {
  const params = useParams();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const [input, setInput] = useState({});
  const [emailSettings, setEmailSettings] = useState({ mailProvider: 'mailjet', mailSettings: {} });

  const [setMail] = useMutation(setMailTemplates, {
    variables: { companyCode: params.company, projectCode: params.project },
  });

  const [setSettingsMutation] = useMutation(setSettings, {
    variables: { companyCode: params.company, projectCode: params.project },
  });

  const [clearMailTemplateCacheMutation] = useMutation(clearMailTemplateCache, {
    variables: { companyCode: params.company, projectCode: params.project },
  });

  const { loading: loadingSettings, error: errSettings } = useQuery(getSettings, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      settingKeys: ['mailProvider', 'mailSettings'],
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getSettings }) => setEmailSettings({
      ...emailSettings,
      ...getSettings.settings,
      mailSettings: getSettings.settings.mailSettings || {},
    }),
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    await setMail({ variables: { templates: input } });
    await setSettingsMutation({ variables: { settings: emailSettings } });
    snackbar.showMessage('Die Mail Einstellungen wurden erfolgreich gespeichert!');
  };

  const onClearMailTemplateCache = async () => {
    await clearMailTemplateCacheMutation();
    snackbar.showMessage('Der Template Cache wurde erfolgreich gelöscht!');
  };

  const { error, loading } = useQuery(getMailTemplates, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
    },
    onCompleted: (data) => {
      setInput({ ...data?.getMailTemplates });
    },
  });

  if (loading || loadingSettings) return (<p>Loading</p>);
  if (error || errSettings) return (<p>Fehler beim laden</p>);

  return (
    <form onSubmit={onSubmit}>
      <PageTitle
        title="E-Mail Einstellungen"
        button={(
          <div>
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Speichern
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: '15px' }}
              onClick={() => onClearMailTemplateCache()}
            >
              Template Cache löschen
            </Button>
          </div>
        )}
      />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h6" component="h2">E-Mail Server</Typography>
          </Grid>
          <Grid item xs={6}>
            <SelectFormField
              label="E-Mail Provider"
              name="mailProvider"
              value={emailSettings.mailProvider}
              options={[
                { label: 'Mailjet', value: 'mailjet' },
                { label: 'SMTP', value: 'smtp' },
              ]}
              onChange={({ target }) => setEmailSettings({ ...emailSettings, mailProvider: target.value })}
            />
          </Grid>
          {(emailSettings.mailProvider === 'smtp') ? (
            <>
              <Grid item xs={6}>
                <InputFormField
                  label="SMTP-Servername"
                  name="mailHost"
                  required
                  value={emailSettings.mailSettings.host}
                  onChange={({ target }) => setEmailSettings({
                    ...emailSettings,
                    mailSettings: { ...emailSettings.mailSettings, host: target.value },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectFormField
                  label="SMTP-Port"
                  name="mailPort"
                  required
                  value={emailSettings.mailSettings.port}
                  options={[
                    { label: '25', value: 25 },
                    { label: '465', value: 465 },
                    { label: '587', value: 587 },
                  ]}
                  onChange={({ target }) => setEmailSettings({
                    ...emailSettings,
                    mailSettings: { ...emailSettings.mailSettings, port: target.value },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectFormField
                  label="SSL/TLS"
                  name="secure"
                  required
                  value={emailSettings.mailSettings.secure || false}
                  options={[
                    { label: 'Ja', value: true },
                    { label: 'Nein', value: false },
                  ]}
                  onChange={({ target }) => setEmailSettings({
                    ...emailSettings,
                    mailSettings: { ...emailSettings.mailSettings, secure: target.value },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <InputFormField
                  label="SMTP-Benutzername"
                  name="username"
                  required
                  value={emailSettings.mailSettings.username}
                  onChange={({ target }) => setEmailSettings({
                    ...emailSettings,
                    mailSettings: { ...emailSettings.mailSettings, username: target.value },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <InputFormField
                  label="SMTP-Passwort"
                  name="password"
                  type="password"
                  required
                  value={emailSettings.mailSettings.password}
                  onChange={({ target }) => setEmailSettings({
                    ...emailSettings,
                    mailSettings: { ...emailSettings.mailSettings, password: target.value },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <InputFormField
                  label="Sendeverzögerung pro E-Mail (in Sekunden)"
                  name="delayInSeconds"
                  type="number"
                  required
                  value={emailSettings.mailSettings.delayInSeconds}
                  onChange={({ target }) => setEmailSettings({
                    ...emailSettings,
                    mailSettings: { ...emailSettings.mailSettings, delayInSeconds: parseInt(target.value) },
                  })}
                />
              </Grid>
            </>
          ) : null}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h6" component="h2">Templates</Typography>
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              label="Account Aktivierung"
              name="registerCustomer"
              value={input.registerCustomer}
              onChange={((e) => {
                setInput({
                  ...input,
                  registerCustomer: parseInt(e.target.value),
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              label="Passwort zurücksetzen"
              name="resetPassword"
              value={input.resetPassword}
              onChange={((e) => {
                setInput({
                  ...input,
                  resetPassword: parseInt(e.target.value),
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              label="Account Information (mit Passwort)"
              name="addCustomer"
              value={input.addCustomer}
              onChange={((e) => {
                setInput({
                  ...input,
                  addCustomer: parseInt(e.target.value),
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              label="Erinnerungsmail: Account Information (mit Passwort)"
              name="addCustomerReminder"
              value={input.addCustomerReminder}
              onChange={((e) => {
                setInput({
                  ...input,
                  addCustomerReminder: parseInt(e.target.value),
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              label="Teilnehmereinladung"
              name="customerInvite"
              value={input.customerInvite}
              onChange={((e) => {
                setInput({
                  ...input,
                  customerInvite: parseInt(e.target.value),
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              label="Marketing E-Mail"
              name="marketing"
              value={input.marketing}
              onChange={((e) => {
                setInput({
                  ...input,
                  marketing: parseInt(e.target.value),
                });
              })}
            />
          </Grid>
        </Grid>

      </Paper>
    </form>
  );
}
