import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useState, useMemo } from 'react';
import Switch from '@material-ui/core/Switch';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import arrayMove from 'array-move';
import PageTitle from '../../components/PageTitle';
import { allCTAGQL, deleteCTAGQL, editCTAGQL, updateMultipleConversionCardsGQL, allPagesGQL } from './gql';
import LoadingTable from '../../components/LoadingTable';

const StyledTable = styled(Table)`
  tbody td {
    padding-bottom: 0;
    padding-top: 0
  }
`;

export default function CTA() {
  const history = useHistory();
  const pageParams = useParams();
  const { companyCode, projectCode } = pageParams;
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const [ctaCards, setCtaCards] = useState([]);

  const { loading, error, refetch } = useQuery(allCTAGQL, {
    fetchPolicy: 'no-cache',
    variables: { companyCode, projectCode },
    onCompleted: (data) => setCtaCards(data.getAllConversionCards.conversionCards),
  });

  const { loadingPages, data: { getCustomPages: customPages = [] } = [] } = useQuery(allPagesGQL, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const [deleteCTA] = useMutation(deleteCTAGQL, {
    variables: { companyCode, projectCode },
    onCompleted: () => refetch() && snackbar.showMessage('Karte wurde erfolgreich gelöscht'),
    onError: () => snackbar.showMessage('Fehler beim Löschen der Karte'),
  });

  const [updateCTA] = useMutation(editCTAGQL, {
    variables: { companyCode, projectCode },
    onCompleted: () => refetch() && snackbar.showMessage('Die Sichtbarkeit der Karte wurde erfolgreich geändert'),
    onError: () => snackbar.showMessage('Fehler beim Ändern der Sichtbarkeit der Karte'),
  });

  const [updateCTAs] = useMutation(updateMultipleConversionCardsGQL, {
    onError: () => snackbar.showMessage('Fehler beim Ändern der Reihenfolge der Karte'),
  });

  const pageNamesByCode = useMemo(() => customPages.reduce((acc, page) => {
    acc[page.code] = page.name;
    return acc;
  }, {}), [customPages]);

  // if (loading) return (<p>Loading</p>);
  if (error) return (<p>Fehler beim laden</p>);

  const onDrop = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    let previousState = [...ctaCards];
    previousState = arrayMove(previousState, result.source.index, result.destination.index);

    const conversionCards = previousState.map((card, sequenceId) => ({ ...card, sequenceId }));

    setCtaCards(conversionCards);
    updateCTAs({
      variables: {
        companyCode,
        projectCode,
        conversionCards,
      },
    });
  };

  const columns = [
    { field: 'id', headerName: '', width: 10 },
    { field: 'name', headerName: 'Name', type: 'string', flex: 0.5 },
    { field: 'pageCode', headerName: 'Sichtbar auf Seite', type: 'string', flex: 0.5 },
    { field: 'posted', headerName: 'Posten', width: 120 },
    { field: 'actions', headerName: 'Aktionen', width: 120 },
  ];

  const renderContent = () => {
    if (loading || loadingPages) return <LoadingTable columns={columns.length} />;

    return (
      <TableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow style={{ paddingBottom: 0, paddingTop: 0 }}>
              {columns.map((column) => (
                <TableCell
                  datatype={column.type}
                  key={column.field}
                  width={column.width}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDrop}>
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided) => (
                <TableBody
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {ctaCards.map((card, index) => (
                    <Draggable draggableId={card.code} key={card.code} index={index}>
                      {(draggableProvided, snapshot) => (
                        <TableRow
                          key={`cta-row${index}`}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          style={{
                            ...draggableProvided.draggableProps.style,
                            height: '52px',
                            background: snapshot.isDragging
                              ? 'rgba(245,245,245, 0.9)'
                              : 'none',
                          }}
                        >
                          <TableCell onClick={() => history.push(`${match.url}/edit/${card.code}`)}>
                            <IconButton style={{ cursor: 'grab' }} {...draggableProvided.dragHandleProps}>
                              <DragIndicatorIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell onClick={() => history.push(`${match.url}/edit/${card.code}`)}>
                            {card.name}
                          </TableCell>
                          <TableCell onClick={() => history.push(`${match.url}/edit/${card.code}`)}>
                            {pageNamesByCode[card.pageCode] || 'Auf allen Seiten'}
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={card.posted}
                              onChange={((e) => {
                                e.preventDefault();
                                updateCTA({
                                  variables: {
                                    code: card.code,
                                    cta: { posted: !card.posted },
                                  },
                                });
                              })}
                              color="primary"
                              name="checked"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Duplizieren">
                              <IconButton
                                onClick={async (e) => {
                                  e.preventDefault();
                                  history.push(`${match.url}/copy/${card.code}`);
                                }}
                              >
                                <FileCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Löschen">
                              <IconButton
                                onClick={async (e) => {
                                  e.preventDefault();
                                  await confirm({
                                    description: (
                                      <span>
                                        Wollen Sie die Karte mit dem Namen &quot;
                                        <strong>{card.name}</strong>
                                        &quot; wirklich löschen?
                                      </span>),
                                    confirmationText: 'Löschen',
                                    confirmationButtonProps: { color: 'secondary' },
                                  });
                                  deleteCTA({ variables: { code: card.code } });
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </StyledTable>
      </TableContainer>
    );
  };

  return (
    <>
      <PageTitle
        title="Karten"
        button={(
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`${match.url}/add`)}
          >
            Erstellen
          </Button>
        )}
      />
      {renderContent()}
    </>
  );
}
