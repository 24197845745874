import React from 'react';
import { Grid,
  Typography,
} from '@material-ui/core';

export default function CTAElement() {
  return (
    <Grid item xs={11} md={3}>
      <Typography variant="h6">
        Karten
      </Typography>
    </Grid>
  );
}
