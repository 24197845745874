import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';

export default function OnDemandPlayerElement(props) {
  const [params, setParams] = useState(props.element?.params);

  const onChangeParams = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(props.index, 'params', newState);
  };

  return (
    <>
      <Grid item xs={11} md={3}>
        <Typography variant="h6">
          Video
        </Typography>
      </Grid>
      <Grid item xs={11} md={4}>
        <SelectFormField
          label="Plattform"
          name="plattform"
          required
          value={params?.plattform || ''}
          options={[{ label: 'Vimeo', value: 'vimeo' }]}
          onChange={({ target }) => onChangeParams('plattform', target.value)}
        />
      </Grid>
      <Grid item xs={11} md={4}>
        <InputFormField
          error={(props.err && params?.videoId === '')}
          label="Media ID"
          name="mediaId"
          value={params?.videoId}
          required
          onChange={({ target }) => onChangeParams('videoId', target.value)}
        />
      </Grid>

    </>
  );
}
