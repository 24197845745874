import { gql, useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useAuthDispatch } from '../context/auth';

const LOGIN_USER = gql`
  query login($email: String!, $password: String!){
    login(email: $email, password: $password){
      userId, role, roleCode, token 
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 1,
  },
  grid: {
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridElement: {
    width: '100%',
  },

  button: {
    width: '80%',
  },

}));

export default function Login() {
  const classes = useStyles();
  const [error, setErorr] = useState();
  const authDispatch = useAuthDispatch();

  const [variables, setVariables] = useState({
    email: '',
    password: '',
  });

  const [loginUser, { loading }] = useLazyQuery(LOGIN_USER, {
    onError(err) {
      if (err.graphQLErrors[0]) {
        setErorr(err.graphQLErrors[0].extensions.code);
      } else {
        setErorr(503);
      }
    },
    onCompleted(data) {
      if (!data?.login) return;
      authDispatch({ type: 'LOGIN', payload: data.login });
      window.location.href = '/';
    },
  });

  const submitLoginForm = (e) => {
    e.preventDefault();
    loginUser({ variables });
  };

  return (
    <Paper className={classes.paper} elevation={5}>
      <form onSubmit={submitLoginForm} autoComplete="off" className={classes.form}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h6">
              Login - WIRKUNG.hub
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              autoComplete="username"
              className={classes.gridElement}
              id="email"
              type="email"
              label="E-Mail-Adresse"
              onChange={(e) => setVariables({ ...variables, email: e.target.value })}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              autoComplete="current-password"
              className={classes.gridElement}
              id="password"
              type="password"
              label="Passwort"
              onChange={(e) => setVariables({ ...variables, password: e.target.value })}
              required
            />
          </Grid>
          <Grid item>
            <Button className={classes.gridElement} variant="contained" type="submit" disabled={loading}>Einloggen</Button>
            <Typography
              style={{ color: '#8f0000' }}
            >
              &nbsp;{(error === 503) && 'Service vorübergehend nicht erreichbar.. '}{(error === 400) && 'Ungültige Zugangsdaten'} {error?.data}&nbsp;
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Paper>

  );
}
