import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import SelectFormField from '../../../components/forms/SelectFormField';
import { allPollsGql } from '../gql';

export default function PollCTAParams({ params, onChange }) {
  const [polls, setPolls] = useState([]);
  const pageParams = useParams();
  const { companyCode, projectCode } = pageParams;

  const handlePolls = (pollArray) => {
    const createPollOption = {
      label: 'Neue Umfrage anlegen',
      value: 'create',
      style: { color: 'gray' },
    };

    const pollCodes = pollArray
      .filter((poll) => poll.status === 'active' || poll.pollCode === params?.pollCode)
      .map(({ name, pollCode, status }) => {
        const poll = { value: pollCode, label: name };

        if (status === 'inactive') {
          poll.label = `${name} (inaktiv)`;
          poll.style = { color: 'gray' };
        }

        return poll;
      }) || [];

    setPolls([...pollCodes, createPollOption]);
  };

  const { loading: loadingPolls } = useQuery(allPollsGql, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getAllPolls }) => handlePolls(getAllPolls.polls),
  });

  if (loadingPolls) return (<p>Loading</p>);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Umfrage</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} xl={6}>
        <SelectFormField
          label="Verknüpfte Umfrage"
          name="pollCode"
          value={params?.pollCode}
          options={polls}
          onChange={({ target }) => onChange(target)}
        />
      </Grid>
    </>
  );
}
