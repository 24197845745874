import { Button, Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { allPagesGQL, deletePageGQL } from './gql';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import LoadingTable from '../../components/LoadingTable';

export default function Pages() {
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();

  const { loading, data, error, refetch } = useQuery(allPagesGQL, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
  });

  const [deletePage] = useMutation(deletePageGQL, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: () => refetch() && snackbar.showMessage('Seite wurde erfolgreich gelöscht'),
    onError: () => snackbar.showMessage('Fehler beim Löschen der Seite'),
  });

  if (error) return (<p>Fehler beim laden</p>);

  const columns = [
    { field: 'code', headerName: 'Code', type: 'string', flex: 1 },
    { field: 'name', headerName: 'Name', type: 'string', flex: 1 },
    { field: 'status', headerName: 'Status', type: 'string', flex: 1, valueFormatter: ({ value }) => (value === 'active' ? 'Aktiv' : 'Inaktiv') },
    { field: 'sequenceId', headerName: 'Reihenfolge', type: 'number', flex: 0.5 },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 120,
      valueGetter: (params) => params.getValue('code'),
      renderCell: ({ value }) => (
        <>
          <Tooltip title="Duplizieren">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                history.push(`${match.url}/copy/${value}`);
              }}
            >
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Löschen">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                await confirm({
                  description: `Wollen Sie die Seite mit dem Code ${value} wirklich löschen?`,
                  confirmationText: 'Löschen',
                  confirmationButtonProps: { color: 'secondary' },
                });
                deletePage({ variables: { pageCode: value } });
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Seiten"
        button={(
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`${match.url}/add`)}
          >
            Erstellen
          </Button>
        )}
      />
      {loading ? <LoadingTable /> : (
        <Paper>
          <DataGrid
            columns={columns}
            rows={data?.getAllPages?.pages || []}
            getRowId={(row) => row.code}
            componentsProps={{ toolbar: { add: true } }}
            pageSize={100}
            autoHeight
            hideFooterRowCount="false"
            localeText={datagrid}
            onCellClick={(selection) => selection.colIndex !== 4 && history.push(`${match.url}/edit/${selection.row.id}`)}
          />
        </Paper>
      )}
    </>
  );
}
