import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { MenuItem } from '@material-ui/core';
import LoadingScreen from './Loading';
import { useAuthState } from '../../context/auth';

const CREATE_PROJECT = gql`
mutation createProject($companyCode: String!, $project: ProjectInput){
    createProject(companyCode: $companyCode, project: $project) 
  }`;

const useStyles = makeStyles((theme) => ({
  action: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  divider: {
    margin: theme.spacing(5),
  },
  switch: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#000',
  },

}));

export default function FormDialog({ open, onClose, onFinish }) {
  const { user } = useAuthState();
  const [errors, setErorrs] = useState(null);
  const [variables, setVariables] = useState({
    projectName: '',
    projectCode: '',
    domain: '',
    subdomain: '',
    type: 'standard',
  });

  const [createProject, { loading }] = useMutation(CREATE_PROJECT, {
    onError(err) {
      if (err.graphQLErrors[0]) {
        setErorrs(err.graphQLErrors[0].extensions.errors);
      } else {
        setErorrs('API ERROR');
      }
    },
    onCompleted() {
      onFinish();
    },
  });

  const classes = useStyles();

  if (loading) return <LoadingScreen />;

  return (

    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createProject({
            variables: {
              companyCode: user.companyCode,
              project: {
                code: variables.projectCode,
                name: variables.projectName,
                domain: variables.domain,
                subdomain: variables.subdomain,
                type: variables.type,
              },
            },
          });
        }}
      >
        <DialogTitle id="form-dialog-title">Neues Projekt erstellen</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Projekt Bezeichnung"
            type="text"
            fullWidth
            required
            value={variables.projectName}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => setVariables({ ...variables, projectName: e.target.value })}
          />
          <TextField
            margin="dense"
            id="code"
            label="Projekt Shortcode"
            type="text"
            fullWidth
            required
            value={variables.projectCode}
            inputProps={{ maxLength: 10 }}
            onChange={(e) => setVariables({ ...variables, projectCode: e.target.value })}
          />
          <TextField
            margin="dense"
            id="domain"
            label="Domain"
            type="text"
            fullWidth
            required
            value={variables.domain}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => setVariables({ ...variables, domain: e.target.value })}
          />
          <TextField
            margin="dense"
            id="subdomain"
            label="Subdomain"
            type="text"
            fullWidth
            required
            value={variables.subdomain}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => setVariables({ ...variables, subdomain: e.target.value })}
          />
          <TextField
            label="Type"
            name="type"
            margin="dense"
            value={variables.type}
            fullWidth
            select
            required
            onChange={({ target }) => setVariables({ ...variables, type: target.value })}
          >
            <MenuItem key="standard" value="standard">Stan­dard</MenuItem>
            <MenuItem key="parent" value="parent">Gruppenevent</MenuItem>
          </TextField>
          {errors && <div><br /> Bitte Felder ausfüllen</div>}

        </DialogContent>
        <DialogActions className={classes.action}>

          <div>
            <Button onClick={onClose} color="primary">
              Abbrechen
            </Button>
            <Button type="submit" color="primary">
              Erstellen
            </Button>
          </div>
        </DialogActions>
      </form>

    </Dialog>
  );
}
