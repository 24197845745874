import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import InputFormField from '../../../components/forms/InputFormField';

export default function VimeoParams(props) {
  const [params, setParams] = useState(props.params);

  const onChange = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(newState);
  };

  return (
    <>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>Vimeo Stream Parameter</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InputFormField
            label="Link"
            name="link"
            value={params?.link}
            onChange={({ target }) => onChange('link', target.value)}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
