import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { gql, useQuery, useMutation } from '@apollo/client';
import Divider from '@material-ui/core/Divider';
import Clear from '@material-ui/icons/Clear';
import { useParams } from 'react-router';
import { IconButton } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';

const GETLINKS = gql`
query getAllZoomExchangeMeetings($companyCode: String!, $projectCode: String!) {
  getAllZoomExchangeMeetings(companyCode: $companyCode, projectCode: $projectCode){
    zoomExchangeMeetings {
      id
      url
    }
  }
}`;

const ADDLINK = gql`
mutation addZoomExchangeMeeting($companyCode: String!, $projectCode: String!, $url: String!){
  addZoomExchangeMeeting (companyCode: $companyCode, projectCode: $projectCode, url: $url)
}
`;
const DELETELINKS = gql`
mutation deleteZoomExchangeMeeting($companyCode: String!, $projectCode: String!, $id: ID!){
  deleteZoomExchangeMeeting(companyCode: $companyCode, projectCode: $projectCode, id: $id)
}
`;

export default function OverviewLinks() {
  const params = useParams();
  const confirm = useConfirm();
  const [getLink, setLink] = useState('');

  const { error, loading, data, refetch } = useQuery(GETLINKS, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
  });
  const [addLink] = useMutation(ADDLINK, { onCompleted: () => refetch() });
  const [deleteLinks] = useMutation(DELETELINKS, { onCompleted: () => refetch() });

  const onDelete = async (meeting) => {
    await confirm({ description: `Wollen Sie den Link "${meeting.url}" wirklich löschen?` });
    deleteLinks({
      variables: { companyCode: params.company, projectCode: params.project, id: meeting.id },
    });
  };

  if (loading) return (<p>Die Daten werden geladen</p>);
  if (error) return (<p>Fehler beim laden</p>);
  if (!data) return (<p />);

  const links = data.getAllZoomExchangeMeetings.zoomExchangeMeetings;
  const mapLinks = links.map((link, index) => (
    <li key={index} style={{ marginTop: 4 }}>
      <Typography variant="body1" component="p">
        {link.url}
        <IconButton size="small" onClick={() => onDelete(link)}>
          <Clear style={{ fontSize: '1.25rem', marginBottom: '1px' }} />
        </IconButton>
      </Typography>
    </li>
  ));
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Divider variant="middle" style={{ marginBottom: 30, marginTop: 30 }} />
          <Typography variant="h6" component="h2">Austausch Links</Typography>
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} spacing={2}>
          <Grid container item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ul>{mapLinks}</ul>
          </Grid>
        </Grid>
      </Grid>
      <form>
        <Grid container style={{ marginTop: 30 }} spacing={2} direction="row">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="outlined-textarea"
              label="Link"
              value={getLink}
              multiline
              fullWidth
              type="text"
              onChange={((e) =>
                setLink(e.target.value)
                      )}
              required
              variant="outlined"
            />
          </Grid>
          <Grid container alignItems="flex-end" item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              onClick={
                    ((e) => {
                      e.preventDefault();
                      if (getLink === '') return;
                      if (!getLink.includes('http://') && !getLink.includes('https://')) return alert('Bitte http:// oder https:// anfügen');
                      addLink({
                        variables: {
                          companyCode: params.company,
                          projectCode: params.project,
                          url: getLink,
                        },
                      });
                      setLink('');
                    })
                    }
            >Link hinzufügen
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
