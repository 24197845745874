import { TextField, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1, 0),
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));


function QuickSearchToolbar(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TextField
        variant="standard"
        onChange={(event) => {
          setSearchTerm(event.target.value)
          props.onChange(event.target.value)
        }}
        placeholder="Search…"
        className={classes.textField}
        value={searchTerm}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: searchTerm ? 'visible' : 'hidden' }}
              onClick={() => {
                setSearchTerm('')
                props.onChange('')
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default QuickSearchToolbar;