import { gql } from '@apollo/client';

export const getFullStreamGQL = gql`
query getFullStream($companyCode: String!, $projectCode: String!, $streamId: String!){
  getFullStream(companyCode:$companyCode, projectCode:$projectCode, streamId: $streamId) {
    streamId
    code
    platform
    type
    params
    zoomMeetings
    actions {
      type
      label
      url
      openInNewTab
      formCode
    }
    content
    cometChatGroupCode
    navTitle
    showVisitorCount
  }
}`;

export const updateStreamGql = gql`
mutation updateStream($companyCode: String!, $projectCode: String!, $streamId: String!, $stream: UpdateStream){
  updateStream(companyCode:$companyCode, projectCode:$projectCode, streamId: $streamId, stream: $stream)
}`;

export const deleteStreamGql = gql`
mutation deleteStream($companyCode: String!, $projectCode: String!, $streamId: String!){
  deleteStream(companyCode:$companyCode, projectCode:$projectCode, streamId: $streamId)
}`;

export const addStreamGql = gql`
mutation addStream($companyCode: String!, $projectCode: String!, $stream: AddStream){
  addStream(companyCode:$companyCode, projectCode:$projectCode, stream: $stream)
}`;

export const allStreamsGQL = gql`
query getAllStreams($companyCode: String!, $projectCode: String!){
  getAllStreams(companyCode:$companyCode, projectCode:$projectCode) {
    streams {
      streamId
      code
      type
      platform
      params
      zoomMeetings
    }
  }
}
`;

export const allGroupsGql = gql`
query getCometChatSettings($companyCode: String!, $projectCode: String!){
  getCometChatSettings(companyCode:$companyCode, projectCode:$projectCode) {
    groups {
      value: code
      label: name
    }
  }
}
`;

export const allFormsGql = gql`
query getAllForms($companyCode: String!, $projectCode: String!) {
  getAllForms(companyCode: $companyCode, projectCode: $projectCode) {
      forms {
        formCode: code
        title
        status
      }
    }
  }`;
