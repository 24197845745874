import { Grid, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import InputFormField from '../../../components/forms/InputFormField';

export default function PageButton(props) {
  const [buttons, setButtons] = useState(props.buttons || []);

  const onAdd = () => {
    const newState = [...buttons];
    newState.push({ link: '', label: '' });
    setButtons(newState);
    props.onChange && props.onChange(newState);
  };
  const onDelete = (index) => {
    const newState = [...buttons];
    newState.splice(index, 1);
    setButtons(newState);
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = [...buttons];
    newState[index] = { ...newState[index], [field]: value };
    setButtons(newState);
    props.onChange && props.onChange(newState);
  };

  useEffect(() => {
    setButtons(props.buttons || []);
  }, [props.buttons]);

  return (
    <>
      <Typography variant="h6" style={{ marginTop: 30, marginBottom: 10 }}>
        Schaltflächen (oben)
        <Button
          variant="contained"
          style={{ borderRadius: 50, minWidth: 0, marginLeft: 5, padding: '0px 8px' }}
          color="primary"
          onClick={onAdd}
        >
          +
        </Button>
      </Typography>
      {buttons && buttons.map((button, index) => (
        <Grid container spacing={3}>
          <Grid item xs={0.5}>
            <Typography style={{ marginTop: '15px' }}>
              {index + 1}
              .
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <InputFormField
              label="Link"
              name="link"
              value={button.link ? decodeURI(button.link) : null}
              onChange={({ target }) => onChange(index, 'link', encodeURI(target.value))}
              required
            />
          </Grid>
          <Grid item xs={5}>
            <InputFormField
              label="Label"
              name="label"
              value={button.label}
              onChange={({ target }) => onChange(index, 'label', target.value)}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" style={{ marginTop: '8px' }} color="secondary" onClick={() => onDelete(index)}>Löschen</Button>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
