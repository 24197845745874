import React from 'react';
import { FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select } from '@material-ui/core';

export default function SelectFormField(props) {
  return (
    <FormControl fullWidth variant="outlined" color={props.warning}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        label={props.label}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        multiple={props.multiple}
        required={props.required}
        disabled={props.disabled}
        style={props.style}
      >
        {props.options.map((option, index) => (
          option.optionType === 'subheader' && props.hasGroups
            ? <ListSubheader>{option.value}</ListSubheader>
            : <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
