import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputFormField from '../../../components/forms/InputFormField';

export default function VimeoParams(props) {
  const [params, setParams] = useState(props.params);

  const onChange = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(newState);
  };

  return (
    <>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>VIMEO Parameter</Typography>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <InputFormField
            error={(props.err && params?.videoId === '')}
            label="Media ID"
            name="mediaId"
            value={params?.videoId}
            required
            onChange={({ target }) => onChange('videoId', target.value)}
          />
        </Grid>
      </Grid>
    </>

  );
}
