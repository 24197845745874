import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import InputFormField from '../../../components/forms/InputFormField';

export default function ThreeQParams(props) {
  const [params, setParams] = useState(props.params);

  const onChange = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(newState);
  };

  return (
    <>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>3Q Stream Parameter</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InputFormField
            label="Data ID"
            name="dataId"
            value={params?.dataId}
            onChange={({ target }) => onChange('dataId', target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <InputFormField
            label="Project ID"
            name="projectId"
            value={params?.projectId}
            onChange={({ target }) => onChange('projectId', target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <InputFormField
            label="Project Key"
            name="projectKey"
            value={params?.projectKey}
            onChange={({ target }) => onChange('projectKey', target.value)}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
