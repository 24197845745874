import React from 'react';
import { Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

export default function SectionLayout({ children }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      {children}
    </Paper>
  );
}
