import React, { useState } from 'react';
import { Paper, makeStyles, Button, CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useQuery, useApolloClient } from '@apollo/client';
import { useParams } from 'react-router';
import moment from 'moment';
import 'moment/locale/de';
import PageTitle from '../../../components/PageTitle';
import { datagrid } from '../../../util/language';
import TimeframeSelector from '../components/TimeframeSelector';
import { getCustomerLoginHistoryGql, exportCustomersLoginHistoryGql } from '../gql';

const useStyles = makeStyles(() => ({
  customerAttribute: {
    lineHeight: '20px',
    whiteSpace: 'nowrap',
  },
}));

export default function LoginHistory() {
  const params = useParams();
  const classes = useStyles();
  const [pageLimit, setPageLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [exportLoading, setExportLoading] = useState(false);
  const client = useApolloClient();

  const [dateVariables, setDateVariables] = useState({
    startDate: moment().subtract(1, 'weeks').toDate(),
    endDate: moment().toDate(),
    loggedInCustomerOnly: false,
  });

  const { loading, error, data } = useQuery(getCustomerLoginHistoryGql, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      limit: pageLimit,
      offset: pageLimit * page,
      showOnlyLoggedInCustomer: dateVariables.loggedInCustomerOnly,
      startDate: dateVariables.startDate || undefined,
      endDate: dateVariables.endDate || undefined,
    },
  });

  const columns = [
    { field: 'email', headerName: 'E-Mail', type: 'string', flex: 0.6 },
    { field: 'firstname', headerName: 'Vorname', type: 'string', flex: 0.4 },
    { field: 'lastname', headerName: 'Nachname', type: 'string', flex: 0.4 },
    {
      field: 'customAttributes',
      headerName: 'Attribute',
      type: 'string',
      flex: 0.5,
      renderCell: ({ value }) => {
        if (!value) return null;
        return (
          <ul>
            {Object.entries(value).map(([key, value]) => {
              if (key === 'inviteCode') return null;
              return (
                <li key={key} className={classes.customerAttribute}>
                  {key}: {value}
                </li>
              );
            })}
          </ul>
        );
      },
    },
    {
      field: 'loggedInDate',
      headerName: 'Login Datum',
      type: 'string',
      flex: 0.3,
      valueFormatter: ({ value }) => (value ? moment(value).format('L LTS') : ''),
    },
  ];

  if (error) {
    return <p>Fehler beim laden</p>;
  }

  return (
    <div>
      <PageTitle
        title="Login Historie"
        button={(
          <Button
            variant="contained"
            color="default"
            onClick={async () => {
              setExportLoading(true);
              const { data } = await client.query({
                query: exportCustomersLoginHistoryGql,
                variables: {
                  companyCode: params.company,
                  projectCode: params.project,
                  startDate: dateVariables.startDate || undefined,
                  endDate: dateVariables.endDate || undefined,
                },
              });
              window.open(data.exportCustomersLoginHistory.url, '_blank');
              setExportLoading(false);
            }}
          >
            {exportLoading ? (
              <CircularProgress size={14} />
            ) : (
              'Liste exportieren'
            )}
          </Button>
        )}
      />

      <TimeframeSelector
        value={dateVariables}
        onStartChange={(value) => setDateVariables({ ...dateVariables, startDate: value })}
        onEndChange={(value) => setDateVariables({ ...dateVariables, endDate: value })}
        onLoggedInCustomerOnlyChange={(value) => setDateVariables({ ...dateVariables, loggedInCustomerOnly: value })}
      />
      <Paper elevation={3}>
        <DataGrid
          style={{ marginTop: '20px' }}
          columns={columns}
          loading={loading}
          rows={data?.getCustomerLoginHistory?.history || []}
          getRowId={(row) => row.email}
          pageSize={pageLimit}
          page={page}
          rowCount={data?.getCustomerLoginHistory?.totalCount}
          autoHeight
          hideFooterRowCount="false"
          localeText={datagrid}
          paginationMode="server"
          pagination
          disableColumnMenu
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          onPageSizeChange={(size) => {
            setPageLimit(size.pageSize);
            setPage(0);
          }}
          onPageChange={(params) => setPage(params.page)}
        />
      </Paper>
    </div>
  );
}
