import { Button, CircularProgress, Paper, IconButton, Tooltip } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useCallback, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import debounce from 'lodash.debounce';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadDialog from './components/UploadDialog';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import {
  getInvitesGQL,
  sendInviteMailsMutation,
  sendMarketingMailToInvitesMutation,
  sendInviteMailMutation,
  deleteInviteMutation,
} from './gql';
import QuickSearchToolbar from '../../components/QuickSearchToolbar';

export default function Invites() {
  const params = useParams();
  const match = useRouteMatch();
  const history = useHistory();
  const snackBar = useSnackbar();
  const [pageLimit, setPageLimit] = useState(100);
  const [page, setPage] = useState(0);
  const confirm = useConfirm();
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { loading, data, error, refetch } = useQuery(getInvitesGQL, {
    fetchPolicy: 'no-cache',
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      limit: pageLimit,
      offset: pageLimit * page,
      search: searchTerm || undefined,
    },
  });

  const [sendInviteMails, { loading: invitesLoading }] = useMutation(
    sendInviteMailsMutation,
    {
      variables: { companyCode: params.company, projectCode: params.project },
      onCompleted: () => {
        snackBar.showMessage(
          'E-Mails werden erfolgreich versendet. Es kann mehrere Minuten daueren bis alle Mails versendet worden sind',
        );
      },
      onError: () => {
        snackBar.showMessage('Fehler beim Versenden der E-Mail');
      },
    },
  );

  const [sendInviteMail] = useMutation(sendInviteMailMutation, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: () => {
      refetch();
      snackBar.showMessage('E-Mail wird erfolgreich versendet. Es kann mehrere Minuten daueren die Mail versendet worden ist');
    },
    onError: () => {
      refetch();
      snackBar.showMessage('Fehler beim Versenden der E-Mail');
    },
  });

  const [deleteInvite] = useMutation(deleteInviteMutation, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: () => {
      refetch();
      snackBar.showMessage('Die Einladung wurde erfolgreich gelöscht');
    },
    onError: () => {
      refetch();
      snackBar.showMessage('Fehler beim Löschen der Einladung');
    },
  });

  const [sendMarketingMailToInvites, { loading: marketingLoading }] = useMutation(sendMarketingMailToInvitesMutation, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: () => {
      snackBar.showMessage(
        'Marketing E-Mails werden erfolgreich versendet. Es kann mehrere Minuten daueren bis alle Mails versendet worden sind',
      );
    },
    onError: () => {
      snackBar.showMessage('Fehler beim Versenden der Marketing E-Mail');
    },
  });

  const throttleSearchTermChange = useCallback(debounce((value) => {
    setSearchTerm(value);
  }, 200), [debounce]);

  if (error) return <p>Fehler beim laden</p>;

  const columns = [
    {
      field: 'inviteToken',
      headerName: 'Einladungstoken',
      type: 'string',
      flex: 0.6,
    },
    { field: 'firstname', headerName: 'Vorname', type: 'string', flex: 0.5 },
    { field: 'lastname', headerName: 'Nachname', type: 'string', flex: 0.5 },
    { field: 'email', headerName: 'E-Mail', type: 'string', flex: 0.5 },
    { field: 'ticketCategory', headerName: 'Ticketkategorie', type: 'string', flex: 0.5, valueFormatter: ({ value }) => (value?.name) },
    { field: 'mailSend', headerName: 'Mail gesendet?', type: 'string', flex: 0.3, valueFormatter: ({ value }) => (value ? 'Ja' : 'Nein') },
    { field: 'customer', headerName: 'Benutzt', type: 'string', flex: 0.3, valueFormatter: ({ value }) => (value ? 'Ja' : 'Nein') },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 120,
      renderCell: (params) => (
        <>
          <Tooltip title="Einladung senden">
            <IconButton
              onClick={async () => {
                await confirm({
                  description: `Wollen Sie die Einladung an die E-Mail Addresse ${params.getValue('email')} wirklich versenden?`,
                  confirmationText: 'Senden',
                  confirmationButtonProps: { color: 'secondary' },
                });
                sendInviteMail({
                  variables: {
                    inviteToken: params.getValue('inviteToken'),
                  },
                });
              }}
            >
              <EmailIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {!params.row.customer
            && (
            <Tooltip title="Einladung löschen">
              <IconButton
                onClick={async () => {
                  await confirm({
                    description: `Wollen Sie die Einladung an die E-Mail Addresse ${params.getValue('email')} wirklich löschen?`,
                    confirmationText: 'Löschen',
                    confirmationButtonProps: { color: 'secondary' },
                  });
                  await deleteInvite({
                    variables: {
                      inviteToken: params.getValue('inviteToken'),
                    },
                  });
                  refetch();
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            )}
        </>
      ),
    },
  ];

  const onSendMail = async () => {
    await confirm({
      description:
        'Wollen Sie wirklich alle Einladungen versenden? Bereits gesendete Einladungen werden übersprungen',
    });
    sendInviteMails();
  };

  const onSendMarketingMails = async () => {
    await confirm({
      description: 'Wollen Sie wirklich die Marketing Mail an alle versenden?',
    });
    sendMarketingMailToInvites();
  };

  return (
    <>
      <PageTitle
        title="Einladungen"
        button={(
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setImportDialogOpen(true)}
            >
              Importieren
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: '15px' }}
              onClick={() => history.push(`${match.url}/add`)}
            >
              Hinzufügen
            </Button>
            <Button
              style={{ marginLeft: '15px' }}
              variant="contained"
              onClick={() => onSendMail(true)}
            >
              {invitesLoading ? (
                <CircularProgress size={14} />
              ) : (
                'E-Mails versenden'
              )}
            </Button>
            <Button
              style={{ marginLeft: '15px' }}
              variant="contained"
              onClick={() => onSendMarketingMails(true)}
            >
              {marketingLoading ? (
                <CircularProgress size={14} />
              ) : (
                'Marketing E-Mails versenden'
              )}
            </Button>
          </div>
        )}
      />
      <Paper elevation={3}>
        <DataGrid
          loading={loading}
          style={{ marginTop: '20px' }}
          columns={columns}
          rows={data?.getInvites?.invites || []}
          getRowId={(row) => row.inviteToken}
          pageSize={pageLimit}
          page={page}
          rowCount={data?.getInvites?.totalCount}
          autoHeight
          hideFooterRowCount="false"
          localeText={datagrid}
          paginationMode="server"
          pagination
          /* disableColumnMenu */
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          onPageSizeChange={(size) => {
            setPageLimit(size.pageSize);
            setPage(0);
          }}
          onPageChange={(params) => setPage(params.page)}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              onChange: (value) => {
                throttleSearchTermChange(value);
              },
            },
          }}
        />
      </Paper>
      <UploadDialog
        open={importDialogOpen}
        handleClose={() => setImportDialogOpen(false)}
        onNewImport={() => refetch()}
      />
    </>
  );
}
