import { useMutation, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Button, Grid, InputLabel, Paper, Switch, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import InputFormField from '../../../components/forms/InputFormField';
import PageTitle from '../../../components/PageTitle';
import CometChatGroups from '../components/cometChatGroups';
import { getChatSettings, setCometChatSettings, getAvailableCustomerAttributes, setTawkToSettingsGql } from '../gql';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function ChatSettings() {
  const params = useParams();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const [input, setInput] = useState({});
  const [tawkToSettings, setTawkToSettings] = useState({});

  const { error, loading, refetch } = useQuery(getChatSettings, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setInput({ customAttributeSelection: {}, ...data?.getCometChatSettings });
      setTawkToSettings({ ...data?.getTawkToSettings });
    },
  });

  const [setCometChat] = useMutation(setCometChatSettings, {
    variables: { companyCode: params.company, projectCode: params.project },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Gruppe mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim speichern der Multi-User Chat Einstellungen');
    },
  });

  const [setTawkTo] = useMutation(setTawkToSettingsGql, {
    variables: { companyCode: params.company, projectCode: params.project },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Gruppe mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim speichern der Multi-User Chat Einstellungen');
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(input).length > 1) {
      const resultComet = await setCometChat({ variables: { settings: input } });
      if (resultComet.errors) return;
    }

    await setTawkTo({ variables: { tawkToSettings } });
    snackbar.showMessage('Die Chat Einstellungen wurden erfolgreich gespeichert!');
    refetch();
  };

  const { data: customerAttributes } = useQuery(getAvailableCustomerAttributes, {
    variables: { companyCode: params.company, projectCode: params.project },
  });

  if (loading) return (<p>Loading</p>);
  if (error) return (<p>Fehler beim laden</p>);

  return (
    <form onSubmit={onSubmit}>
      <PageTitle
        title="tawk.to"
        button={
          (
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Speichern
            </Button>
          )
        }
      />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SwitchBox>
              <InputLabel>Aktiv: </InputLabel>
              <Switch
                checked={!!tawkToSettings.enabled}
                onChange={((e) => {
                  setTawkToSettings({ ...tawkToSettings, enabled: e.target.checked });
                })}
                color="primary"
                name="checked"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </SwitchBox>

          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Property ID"
              fullWidth
              value={tawkToSettings.propertyId || ''}
              onChange={({ target }) => setTawkToSettings({ ...tawkToSettings, propertyId: target.value })}
              variant="outlined"
              disabled={!tawkToSettings.enabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Key"
              fullWidth
              value={tawkToSettings.key || ''}
              onChange={({ target }) => setTawkToSettings({ ...tawkToSettings, key: target.value })}
              variant="outlined"
              disabled={!tawkToSettings.enabled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Api Key"
              fullWidth
              value={tawkToSettings.apiKey || ''}
              onChange={({ target }) => setTawkToSettings({ ...tawkToSettings, apiKey: target.value })}
              variant="outlined"
              disabled={!tawkToSettings.enabled}
            />
          </Grid>
        </Grid>
      </Paper>

      <PageTitle
        title="Comet Chat"
      />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputFormField
              label="App-ID"
              name="appId"
              value={input.appId}
              onChange={((e) => {
                setInput({
                  ...input,
                  appId: e.target.value,
                });
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <InputFormField
              label="API Key"
              name="apiKey"
              value={input.apiKey}
              onChange={((e) => {
                setInput({
                  ...input,
                  apiKey: e.target.value,
                });
              })}
            />
          </Grid>
          <CometChatGroups
            groups={input.groups}
            onChange={(groups) => setInput({ ...input, groups })}
          />
        </Grid>
        <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>Teilnehmer Attribute anzeigen</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <List>
              {customerAttributes?.getAvailableCustomerAttributes?.availableAttributes?.map((value) => {
                const labelId = `checkbox-list-label-${value.code}`;
                return (
                  <ListItem key={value.code} style={{ minHeight: 72 }}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={input?.customAttributeSelection?.[value.code] !== undefined}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        onChange={((e) => {
                          const newInput = { ...input, customAttributeSelection: { ...input?.customAttributeSelection } };
                          if (!e.target.checked) {
                            delete newInput.customAttributeSelection[value.code];
                            setInput(newInput);
                            return;
                          }
                          newInput.customAttributeSelection[value.code] = value.label;
                          setInput(newInput);
                        })}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value.label} style={{ minWidth: 150 }} />
                    {input?.customAttributeSelection?.[value.code] !== undefined
                      && (
                      <InputFormField
                        label="Attributename im Chat"
                        name={value.code}
                        value={input.customAttributeSelection[value.code]}
                        required
                        onChange={((e) => {
                          setInput({
                            ...input,
                            customAttributeSelection: {
                              ...input.customAttributeSelection,
                              [value.code]: e.target.value,
                            },
                          });
                        })}
                      />
                      )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
}

const SwitchBox = styled.div`
  display: flex;
  align-items: center;
`;
