import React from 'react';
import { Switch } from 'react-router-dom';
import DynamicRoute from '../../util/DynamicRoute';
import Companys from '../../Pages/company/Companys';
import Login from '../../Pages/Login';
import SetPasswordPage from '../../Pages/SetPassword';
import Project from '../../Pages/project/Project';
import Projects from '../../Pages/project/Projects';
import PollResult from '../../Pages/Poll/subpages/result';
import SpeakerView from '../../Pages/Forms/subpages/speakerView';
import EmojiResult from '../../Pages/Emojis/subpages/result';

export default function Router() {
  return (
    <Switch>
      <DynamicRoute exact path="/login" guest><Login /></DynamicRoute>
      <DynamicRoute exact path="/user/setPassword/:verificationToken" guest><SetPasswordPage /></DynamicRoute>
      <DynamicRoute exact path="/" authenticated neededRole={6}><Companys /></DynamicRoute>
      <DynamicRoute exact path="/:company/" authenticated neededRole={5}><Projects /></DynamicRoute>
      <DynamicRoute exact path="/:company/:project/poll/edit/:code/results" authenticated neededRole={5}><PollResult /></DynamicRoute>
      <DynamicRoute exact path="/:company/:project/forms/edit/:code/results" authenticated neededRole={5}><SpeakerView /></DynamicRoute>
      <DynamicRoute exact path="/:company/:project/emojis/results" authenticated neededRole={5}><EmojiResult /></DynamicRoute>
      <DynamicRoute path="/:company/:project/" authenticated neededRole={5}><Project /></DynamicRoute>
    </Switch>
  );
}
