import { Container } from '@material-ui/core';
import { deDE } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'material-ui-snackbar-provider';
import { ConfirmProvider } from 'material-ui-confirm';
import ApolloProvider from './ApolloProvider';
import './App.css';
import Router from './components/Navigation/Router';
import { AuthProvider } from './context/auth';
import { NavigationProvider } from './context/navigation';
import { PopupProvider } from './context/popup';

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, deDE);

function App() {
  return (
    <ApolloProvider>
      <AuthProvider>
        <NavigationProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <SnackbarProvider SnackbarProps={{ autoHideDuration: 4000 }}>
                <Container style={{ padding: '0', maxWidth: '100%' }}>
                  <ConfirmProvider defaultOptions={{ cancellationText: 'Abbrechen', title: 'Sind Sie sich sicher?' }}>
                    <PopupProvider>
                      <Router />
                    </PopupProvider>
                  </ConfirmProvider>
                </Container>
              </SnackbarProvider>
            </ThemeProvider>
          </BrowserRouter>
        </NavigationProvider>
      </AuthProvider>
    </ApolloProvider>

  );
}

export default App;
