import { gql } from '@apollo/client';

export const allCompanyUsersGQL = gql`
  query getUsers($companyCode: String!) {
    getUsers(companyCode: $companyCode) {
      id: userId
      email
      firstname
      lastname
      roleName
      roleCode
      companyCodes
      status
      token
    }
}`;

export const allUserRolesGQL = gql`
  query getUserRoles{
    getUserRoles {
        roleCode
        roleName
    }
}`;

export const getCompanyUserGQL = gql`
  query getCompanyUser($companyCode: String!, $userId: Int!) {
    getCompanyUser(companyCode: $companyCode, userId: $userId) {
      userId
      email
      firstname
      lastname
      role
      roleName
      roleCode
      companyCodes
      status
      token
    }
}`;

export const addUserGQL = gql`
  mutation addUser($companyCode: String!, $userInput: CreateUser!) {
    addUser(companyCode: $companyCode, userInput: $userInput)     
  }
`;

export const updateUserGQL = gql`
  mutation updateUser($companyCode: String!, $email: String!, $userInput: UpdateUser!) {
    updateUser(companyCode: $companyCode, email: $email, userInput: $userInput) 
  }
`;

export const deleteCompanyUserGQL = gql`
  mutation deleteCompanyUser($companyCode: String!, $email: String!){
    deleteCompanyUser(companyCode: $companyCode, email: $email)
}`;
