import { gql, useQuery, useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { datagrid } from '../../util/language';
import DialogCreateCompany from '../../components/dialogs/DialogCreateCompany';
import LoadingScreen from '../../components/dialogs/Loading';
import Surface from '../../components/MainSurface';
import CompanysContextSwitch from './CompanyContextSwitch';
import AppBar from '../../components/Navigation/AppBar';
import PageTitle from '../../components/PageTitle';
import { useAuthState } from '../../context/auth';

const GET_COMPANYS = gql`
query getCompanys{
  getCompanys{
    id,
    companyName,
    companyCode,
    createdAt,
  }
}`;

const DELETE_COMPANYS = gql`
mutation deleteCompany($companyCode: String!){
  deleteCompany(companyCode: $companyCode)
}`;

export default function Companys() {
  let companys = [];
  const { user } = useAuthState();

  const [openDialog, setDialogOpen] = React.useState(false);
  const [selection, setSelection] = React.useState(null);

  const { loading, error, data, refetch, networkStatus } = useQuery(GET_COMPANYS, {
    fetchPolicy: 'network-only',
  });
  const [deleteCompany] = useMutation(DELETE_COMPANYS, {
    onCompleted() {
    },
  });

  const columns = [

    { field: 'id', headerName: 'id', hide: 'true', type: 'int', filterable: false },
    {
      field: 'select',
      headerName: 'Auswählen',
      width: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelection(params.row.companyCode);
            }}
          >
            Auswählen
          </Button>
        </strong>
      ),
    },
    { field: 'companyCode', headerName: 'Code', type: 'string', flex: 1.5 },
    { field: 'companyName', headerName: 'Organisation', type: 'dateTime', flex: 0.8 },
    { field: 'createdAt',
      headerName: 'Angelegt',
      type: 'dateTime',
      flex: 0.8,
      valueFormatter: ({ value }) => new Date(parseInt(value)).toLocaleDateString() },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 150,
      renderCell: (params) => (
        user.roleCode === 0 && (
          <strong>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                deleteCompany({
                  variables: {
                    companyCode: params.row.companyCode,
                  },
                  refetchQueries: [{
                    query: GET_COMPANYS,
                  }],
                });
              }}
            >
              Löschen
            </Button>
          </strong>
        )

      ),
    },

  ];

  const handleAddCompany = () => {
    setDialogOpen(true);
  };
  const closeDialog = () => {
    setDialogOpen(false);
  };
  const handleSubmit = () => {
    setDialogOpen(false);
    refetch();
  };

  if (error) return `Error! ${error}`;

  if (data) {
    companys = data.getCompanys.map((item) =>
      ({ ...item, selected: false }));
  }

  return (
    <>
      <AppBar />
      <Surface>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <PageTitle
              title="Organisationen"
              button={user.roleCode === 0 && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddCompany}
                >
                  Erstellen
                </Button>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ widht: '100%' }}>
            <DataGrid
              rows={companys}
              columns={columns}
              componentsProps={{ toolbar: { filter: true, remove: false, add: handleAddCompany, edit: false } }}
              pageSize={100}
              autoHeight
              hideFooterRowCount="false"
              localeText={datagrid}
            />
          </Grid>
        </Grid>
      </Surface>
      <DialogCreateCompany open={openDialog} onClose={closeDialog} onFinish={handleSubmit} />

      {selection && <CompanysContextSwitch companyCode={selection} />}
      {(loading || networkStatus === 4) && <LoadingScreen /> }
    </>
  );
}
