import { makeStyles } from '@material-ui/core/styles';
import { React, useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Fab, Card, CardContent, Container, Grid } from '@material-ui/core';
import { useHistory, useParams, useLocation } from 'react-router';
import SettingsIcon from '@material-ui/icons/Settings';
import { getPollForResult } from '../gql';
import WordCloudResult from '../components/WordCloudResult';
import MultipleChoiceResult from '../components/MultipleChoiceResult';
import DialogWordCloudSettings from '../components/DialogWordCloudSettings';
import DialogMultipleChoiceSettings from '../components/DialogMultipleChoiseSettings';

export default function PollResult() {
  const params = useParams();
  const history = useHistory();
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [settings, setSettings] = useState({ pollIntervall: 2000, elevation: 0 });
  const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: theme.spacing(3),
    },
    containerWithMargin: {
      marginTop: 70,
      [theme.breakpoints.up('md')]: {
        marginTop: 95,
      },
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const { loading, data, startPolling, stopPolling } = useQuery(getPollForResult, {
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      code: params.code,
    },
  });

  useEffect(() => {
    if (settings.backgroundColor) {
      document.body.style.backgroundColor = `#${settings.backgroundColor}`;
    }
    startPolling(settings.pollIntervall);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, settings]);

  useEffect(() => {
    for (const parameter of query.entries()) {
      localStorage.setItem(`poll-result-setting-${[params.company, params.project, params.code, parameter[0]].join('-')}`, parameter[1]);
    }
    const entries = Object.fromEntries(query.entries());
    if (Object.keys(entries).length !== 0) {
      if (entries.elevation) entries.elevation = parseInt(entries.elevation);
      if (entries.pollIntervall) entries.pollIntervall = parseInt(entries.pollIntervall);
      if (entries.colors) entries.colors = entries.colors.split(',');
      setSettings({ ...settings, ...entries });
    }
  }, [query]);

  if (loading) return (<p>Loading</p>);
  if (!data?.getSinglePollForResultView) return null;

  const renderResult = () => {
    switch (data?.getSinglePollForResultView?.type) {
      case 'wordCloud':
        return <WordCloudResult poll={data?.getSinglePollForResultView} settings={settings} />;

      case 'singleChoice':
        return <MultipleChoiceResult poll={data?.getSinglePollForResultView} settings={settings} />;

      case 'multipleChoice':
        return <MultipleChoiceResult poll={data?.getSinglePollForResultView} settings={settings} />;

      default: return null;
    }
  };

  return (
    <>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={12} lg={12}>
            <Card elevation={settings.elevation} style={{ maxWidth: 610, margin: '0 auto' }}>
              <CardContent style={{ backgroundColor: `#${settings.backgroundColor}` || 'white' }}>
                {renderResult()}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {!(settings?.hideFAB === 'true')
            && (
            <Fab className={classes.fab} onClick={() => setSettingsModalOpen(true)}>
              <SettingsIcon />
            </Fab>
            )}
      {
        data?.getSinglePollForResultView?.type === 'wordCloud'
          ? (
            <DialogWordCloudSettings
              open={settingsModalOpen}
              settings={settings}
              onClose={() => setSettingsModalOpen(false)}
              onFinish={(newSettings) => {
                setSettings(newSettings);
                setSettingsModalOpen(false);
                const settingsParameter = [];
                for (const row of Object.keys(newSettings)) {
                  const value = newSettings[row];
                  localStorage.setItem(`poll-result-setting-${[params.company, params.project, params.code, row].join('-')}`, value);
                  settingsParameter.push(`${row}=${value}`);
                }
                history.replace({
                  search: `?settings=${settingsParameter.length && `&${settingsParameter.join('&')}`}`,
                });
              }}
            />
          )
          : (
            <DialogMultipleChoiceSettings
              open={settingsModalOpen}
              settings={settings}
              onClose={() => setSettingsModalOpen(false)}
              onFinish={(newSettings) => {
                setSettings(newSettings);
                setSettingsModalOpen(false);
                const settingsParameter = [];
                for (const row of Object.keys(newSettings)) {
                  const value = newSettings[row];
                  localStorage.setItem(`poll-result-setting-${[params.company, params.project, params.code, row].join('-')}`, value);
                  settingsParameter.push(`${row}=${value}`);
                }
                history.replace({
                  search: `?settings=${settingsParameter.length && `&${settingsParameter.join('&')}`}`,
                });
              }}
            />
          )
      }
    </>
  );
}
