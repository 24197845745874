import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import { Alert } from '@material-ui/lab';
import { importCustomerGQL } from '../gql';

function UploadDialog(props) {
  const params = useParams();
  const [file, setFile] = useState();
  const [state, setState] = useState('waitingForUpload');
  const [uploadFile, { data }] = useMutation(importCustomerGQL, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: () => {
      setState('success');
      props.onNewImport && props.onNewImport();
    },
    onError: () => setState('error'),
  });

  const onClose = (e) => {
    e.preventDefault();
    props.handleClose && props.handleClose();
    setState('waitingForUpload');
    setFile(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    uploadFile({ variables: { file } });
    setState('loading');
  };

  const renderButtons = () => {
    if (['success', 'error'].includes(state)) {
      return (
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Schließen
          </Button>
        </DialogActions>
      );
    }

    return (
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button type="submit" color="primary">
          Importieren
        </Button>
      </DialogActions>
    );
  };

  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Teilnehmer Importieren</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          {state === 'loading' && <CircularProgress />}
          {state === 'waitingForUpload' && (
          <>
            <DialogContentText>
              Laden Sie eine CSV oder XLSX Datei mit Personen.
              Die CSV muss folgende Spalte besitzen: Vorname, Nachname, E-Mail, Ticketkategorie
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="file"
              fullWidth
              required
              onChange={(e) => setFile(e.target.files[0])}
            />
          </>
          )}

          {state === 'success'
          && (
          <>
            {!data.importCustomer.hasErrors && (
            <Alert variant="outlined" severity="success">
              <Typography paragraph>
                Es wurde
                {' '}
                {data.importCustomer.overallCounts.imported}
                {' '}
                Teilnehmer importiert und
                {' '}
                {data.importCustomer.overallCounts.failed}
                {' '}
                Teilnehmer konnten nicht importiert werden.
                {data.importCustomer.overallCounts.skipped
                  ? `${data.importCustomer.overallCounts.skipped} Teilnehmer existieren bereits und wurden übersprungen.`
                  : ''}
              </Typography>
            </Alert>
            )}

            {data.importCustomer.hasErrors && (
            <Alert variant="outlined" severity="error">
              <Typography paragraph>Fehler:</Typography>
              {data.importCustomer.errors.map((log) => (<Typography paragraph>{log}</Typography>))}
            </Alert>
            )}
          </>
          )}
        </DialogContent>
        {renderButtons()}
      </form>

    </Dialog>
  );
}

export default React.forwardRef(UploadDialog);
