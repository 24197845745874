/* eslint template-curly-spacing: off */
/* eslint indent: off */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Box, Button, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { getConfigGql } from '../gql';

const calcPercentage = (val, totalVal) => {
  if (!totalVal) return 0;
  return ((val / totalVal) * 100).toFixed(1);
};

export default function MultipleChoiceResult({ poll, settings }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(4),
    },
    fill: {
      backgroundColor: theme.palette.primary.main,
      height: '100%',
      position: 'absolute',
      left: 0,
      borderRadius: theme.shape.borderRadius - 1,
    },
  }));
  const classes = useStyles();
  const params = useParams();

  const { data: configData, loading } = useQuery(getConfigGql, {
    variables: { companyCode: params.company, projectCode: params.project },

  });
if (loading) return (<p>Loading</p>);

  return (
    <>
      <Grid container direction="column" spacing={1} style={{ marginTop: '15px' }}>
        {
          poll?.params?.pollItems.map((item) => (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                fullWidth
                style={{ textTransform: 'none', border: '2px solid', borderColor: settings.fontColor }}
              >
                <Box alignItems="center" display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                  <Box alignItems="center" display="flex" justifyContent="flex-start">
                    {item.image && <Avatar alt="" src={item.image} style={{ marginRight: '15px', zIndex: 100 }} />}
                    {item.label && <Typography variant="h6" style={{ zIndex: 100, color: settings.fontColor }}>{item.label}</Typography>}
                    {!!item.voted && <Typography variant="h6" style={{ zIndex: 100, color: settings.fontColor }}>{item.voted}</Typography>}
                  </Box>
                  {
                    poll?.params?.isAbsolute
                      ? (
                        <Typography
                          alignSelf="flex-end"
                          variant="body1"
                          style={{ zIndex: 100, color: settings.fontColor }}
                        >{`${item.votes} ${configData?.getConfig?.i18n?.overrides?.['poll.votes'] || 'Stimmen'}`}
                        </Typography>
                      )
                      : (
                        <Typography
                          alignSelf="flex-end"
                          variant="body1"
                          style={{ zIndex: 100, color: settings.fontColor }}
                        >{`${calcPercentage(item.votes, poll?.params?.totalVotes)}%`}
                        </Typography>
                      )
                  }
                </Box>
                <div
                  className={`${classes.fill}`}
                  style={{ width: `${calcPercentage(item.votes, poll?.params?.totalVotes)}%`, backgroundColor: settings.fillColor }}
                />
              </Button>
            </Grid>
          ))
        }
        <Grid item xs={12}>
          <span>
            {
            poll?.params?.showTotal
            && (
              <Typography
                variant="body1"
                align="right"
                component="p"
                style={{ color: settings.fontColor }}
              >{`${configData?.getConfig?.i18n?.overrides?.['poll.totalVotes'] || 'Gesamte Abstimmungen: '}${poll?.params?.totalVotes ? poll?.params?.totalVotes : 0}`}
              </Typography>
            )
          }
          </span>
          <span>
            {
            poll?.params?.showCustomersVotedCount
            && (
              <Typography
                variant="body1"
                align="right"
                component="p"
                style={{ color: settings.fontColor }}
              >{`${configData?.getConfig?.i18n?.overrides?.['poll.customersVoted'] || 'Teilgenommen: '}${poll?.params?.customersVoted ? poll?.params?.customersVoted : 0}`}
              </Typography>
            )
          }
          </span>
        </Grid>
      </Grid>
    </>
  );
}
