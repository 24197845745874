import { gql } from '@apollo/client';

export const GET_MEDIA = gql`
query getAllMediaLibraryMedia($companyCode:String!,$projectCode:String!){
    getAllMediaLibraryMedia(companyCode:$companyCode, projectCode:$projectCode){
        media{
        code
        title
        status
        type
        params
        }
    }
}`;

export const GET_SINGLE_MEDIA = gql`
query getSingleMediaLibraryMedia($companyCode:String!,$projectCode:String!,$code:String!){
    getSingleMediaLibraryMedia(companyCode:$companyCode,projectCode:$projectCode,code:$code){
        code
        title
        type
        status
        params
        categories{
            code
            name
            status
            sequenceId
        }
    }
}`;

export const ADD_MEDIA = gql`
mutation addMediaLibraryMedia($companyCode:String!, $projectCode:String!, $media:AddMediaLibraryMedia!){
    addMediaLibraryMedia(companyCode:$companyCode, projectCode:$projectCode, media:$media)
}`;

export const UPDATE_MEDIA = gql`
mutation updateMediaLibraryMedia($companyCode:String!, $projectCode:String!,$code:String!, $media:UpdateMediaLibraryMedia!){
    updateMediaLibraryMedia(companyCode:$companyCode, projectCode:$projectCode,code:$code, media:$media)
}`;

export const deleteMediaLibraryMedia = gql`
mutation deleteMediaLibraryMedia($companyCode:String!, $projectCode: String!, $code:String!){
    deleteMediaLibraryMedia(companyCode:$companyCode, projectCode:$projectCode, code:$code)
}`;

export const GET_CATEGORIES = gql`
query getAllMediaLibraryCategories($companyCode:String!,$projectCode:String!){
    getAllMediaLibraryCategories(companyCode:$companyCode, projectCode:$projectCode){
        categories{
        code
        name
        status
        sequenceId
        }
    }
}`;

export const GET_SINGLE_CATEGORY = gql`
query getSingleMediaLibraryCategory($companyCode:String!,$projectCode:String!,$code:String!){
    getSingleMediaLibraryCategory(companyCode:$companyCode,projectCode:$projectCode,code:$code){
    code
    name
    status
    sequenceId
    content
    moreContent
    }
}`;

export const ADD_CATEGORY = gql`
mutation addMediaLibraryCategory($companyCode:String!, $projectCode:String!, $category:AddMediaLibraryCategory!){
    addMediaLibraryCategory(companyCode:$companyCode, projectCode:$projectCode, category:$category)
}`;

export const UPDATE_CATEGORY = gql`
mutation updateMediaLibraryCategory($companyCode:String!,$projectCode:String!,$code:String!,$category:UpdateMediaLibraryCategory!){
    updateMediaLibraryCategory(companyCode:$companyCode,projectCode:$projectCode,code:$code,category:$category)
}`;

export const deleteMediaLibraryCategory = gql`
mutation deleteMediaLibraryCategory($companyCode:String!, $projectCode: String!, $code:String!){
    deleteMediaLibraryCategory(companyCode:$companyCode, projectCode:$projectCode, code:$code)
}`;
