import { Paper, Grid, Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import AceEditor from 'react-ace';
import SelectFormField from '../../../components/forms/SelectFormField';
import PageTitle from '../../../components/PageTitle';
import ZoomParams from '../components/ZoomParams';
import ThreeQParams from '../components/3qParams';
import { getFullStreamGQL, updateStreamGql, addStreamGql, allStreamsGQL, allGroupsGql } from '../gql';
import ContentFlowParams from '../components/contentFlowParams';
import InputFormField from '../../../components/forms/InputFormField';

import 'ace-builds/src-noconflict/mode-jsx';
import 'ace-builds/src-noconflict/theme-textmate';
import ActionButtons from '../components/ActionButtons';
import YoutubeParams from '../components/YoutubeParams';
import VimeoParams from '../components/VimeoParams';
import HLSParams from '../components/HLSParams';
import IframeParams from '../components/IframeParams';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function StreamEdit(props) {
  const [stream, setStream] = useState({ platform: '3q', type: 'sub' });
  const [cometChatGroups, setCometChatGroups] = useState([]);
  const history = useHistory();
  const classes = useStyles();
  const snackbar = useSnackbar();

  const params = useParams();
  const { refetch, loading } = useQuery(getFullStreamGQL, {
    skip: !params.streamId,
    variables: { companyCode: params.company, projectCode: params.project, streamId: params.streamId },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getFullStream }) => setStream(getFullStream),
  });

  const { refetch: refetchGroups, loading: groupsLoading } = useQuery(allGroupsGql, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getCometChatSettings }) => {
      const newState = (getCometChatSettings?.groups?.map((e) => ({ ...e })) || []);
      newState.push({ label: 'Keine', value: null });
      setCometChatGroups(newState);
    },
  });

  const { data: allStreams } = useQuery(allStreamsGQL, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
  });

  const hideMainStageOption = allStreams?.getAllStreams?.streams?.find(({ streamId, type }) => type === 'main' && streamId !== params.streamId);
  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const showErrorMessage = (err) => {
    if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Ein Stream mit dem selben Code existiert bereits');
    return snackbar.showMessage('Fehler beim speichern des Streams');
  };

  const [addStream, { loading: addStreamLoading }] = useMutation(addStreamGql, {
    onCompleted: () => {
      history.replace('./streams');
      showSuccessMessage();
    },
    onError: (err) => showErrorMessage(err),
  });
  const [updateStream, { loading: updateStreamLoading }] = useMutation(updateStreamGql, {
    onCompleted: () => {
      refetch();
      refetchGroups();
      showSuccessMessage();
    },
    onError: () => showErrorMessage(),
  });

  const renderStreamSettings = () => {
    if (!stream.platform || stream.platform === '3q') {
      return (
        <ThreeQParams
          params={stream.params}
          onChange={(params) => setStream({ ...stream, params })}
        />
      );
    }

    if (stream.platform === 'zoom' || stream.platform === 'externZoom') {
      return (
        <ZoomParams
          zoomMeetings={stream.zoomMeetings}
          onChange={(zoomMeetings) => setStream({ ...stream, zoomMeetings })}
          isExtern={stream.type === 'extern'}
        />
      );
    }

    if (stream.platform === 'contentFlow') {
      return (
        <ContentFlowParams
          params={stream.params}
          onChange={(params) => setStream({ ...stream, params })}
        />
      );
    }

    if (stream.platform === 'youtube') {
      return (
        <YoutubeParams params={stream.params} onChange={(params) => setStream({ ...stream, params })} />
      );
    }

    if (stream.platform === 'vimeo') {
      return (
        <VimeoParams params={stream.params} onChange={(params) => setStream({ ...stream, params })} />
      );
    }

    if (stream.platform === 'hls') {
      return (
        <HLSParams params={stream.params} onChange={(params) => setStream({ ...stream, params })} />
      );
    }

    if (stream.platform === 'iframe') {
      return (
        <IframeParams params={stream.params} onChange={(params) => setStream({ ...stream, params })} />
      );
    }
    return null;
  };

  const save = (e) => {
    e.preventDefault();
    if (!params.streamId) {
      addStream({
        variables: {
          companyCode: params.company,
          projectCode: params.project,
          streamId: params.streamId,
          stream: { ...stream, params: stream.params || {}, __typename: undefined },
        },
      });
      return;
    }
    updateStream({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        streamId: params.streamId,
        stream: { ...stream, params: stream.params || {}, __typename: undefined, streamId: undefined },
      },
    });
  };

  if (loading || groupsLoading || (params.streamId && !stream.streamId)) return (<p>Loading</p>);

  return (
    <form onSubmit={save}>
      <PageTitle
        title={params.streamId ? 'Stream bearbeiten' : 'Neuen Stream hinzufügen'}
        button={(
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            {(addStreamLoading || updateStreamLoading) ? <CircularProgress size={14} /> : (params.streamId ? 'Speichern' : 'Erstellen')}
          </Button>
        )}
      />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <InputFormField
              label="Code"
              name="code"
              value={stream.code}
              onChange={({ target }) => setStream({ ...stream, code: target.value })}
              disabled={!!params.streamId}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SelectFormField
              label="Platform"
              name="platform"
              value={stream.platform || '3q'}
              options={stream.type !== 'extern' ? [
                { label: '3Q', value: '3q' },
                { label: 'Zoom', value: 'zoom' },
                { label: 'Contentflow', value: 'contentFlow' },
                { label: 'YouTube', value: 'youtube' },
                { label: 'Vimeo', value: 'vimeo' },
                { label: 'HLS Stream', value: 'hls' },
                { label: 'iFrame Stream', value: 'iframe' },
              ]
                : [
                  { label: 'Zoom', value: 'externZoom' },
                  { label: 'Unbekannt', value: 'none' },
                ]}
              onChange={({ target }) => setStream({ ...stream, platform: target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectFormField
              label="Bühne"
              name="type"
              value={stream.type || 'sub'}
              options={[
                ...(hideMainStageOption ? [] : [{ label: 'Hauptbühne', value: 'main' }]),
                { label: 'Nebenbühne', value: 'sub' },
                { label: 'Externe Bühne', value: 'extern' },
              ]}
              onChange={({ target }) => {
                if (target.value === 'extern') setStream({ ...stream, type: target.value, platform: 'externZoom' });
                else setStream({ ...stream, type: target.value });
              }}
            />
          </Grid>
          {
            stream.type !== 'extern'
              && (
                <>
                  <Grid item xs={6}>
                    <InputFormField
                      label="Navbar-Titel"
                      name="type"
                      value={stream.navTitle || ''}
                      onChange={({ target }) => setStream({ ...stream, navTitle: target.value })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectFormField
                      label="Besucheranzahl anzeigen"
                      name="showVisitorCount"
                      value={stream.showVisitorCount}
                      options={[
                        { label: 'Ja', value: true },
                        { label: 'Nein', value: false },
                      ]}
                      onChange={({ target }) => setStream({ ...stream, showVisitorCount: target.value })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectFormField
                      label="CometChat Gruppe"
                      name="type"
                      value={stream.cometChatGroupCode || ''}
                      options={cometChatGroups}
                      onChange={({ target }) => setStream({ ...stream, cometChatGroupCode: target.value })}
                    />
                  </Grid>
                </>
              )
            }

          {
            stream.platform === 'none'
              && (
              <Grid item xs={6}>
                <InputFormField
                  label="Externe URL "
                  name="externUrl"
                  value={stream.params.externUrl || ''}
                  onChange={({ target }) => setStream({ ...stream, params: { ...params, externUrl: target.value } })}
                />
              </Grid>
              )
              }
        </Grid>

        {renderStreamSettings(props)}
        {
            stream.type !== 'extern'
            && (
              <>
                <ActionButtons
                  actions={stream.actions}
                  params={params}
                  onChange={(actions) => setStream({ ...stream, actions })}
                />
                <Typography variant="h6" style={{ marginTop: 30, marginBottom: 10 }}>Zusätzliche Inhalt unter Stream</Typography>
                <AceEditor
                  mode="jsx"
                  theme="textmate"
                  onChange={(content) => setStream({ ...stream, content })}
                  editorProps={{ $blockScrolling: false }}
                  width="100%"
                  value={stream.content}
                  enableBasicAutocompletion
                  enableLiveAutocompletion
                  tabSize={2}
                />
              </>
            )
            }
      </Paper>
    </form>
  );
}
