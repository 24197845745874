import { Grid,
  Typography,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar } from '@material-ui/core';

import React, { useEffect, useState, useRef } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import arrayMove from 'array-move';
import { Container, Draggable } from 'react-smooth-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useSnackbar } from 'material-ui-snackbar-provider';
import ImageIcon from '@material-ui/icons/Image';
import InputFormField from '../../../components/forms/InputFormField';
import SelectFormField from '../../../components/forms/SelectFormField';

export default function PollItem(props) {
  const snackbar = useSnackbar();
  const [items, setItems] = useState(props?.items || []);
  const fileInputRefs = useRef([]);

  const onAdd = () => {
    if (items?.length >= 10) {
      snackbar.showMessage('Sie können nicht mehr als 10 Antwortmöglichkeiten hinzufügen');
      return;
    }

    let newState;

    if (!items?.length) {
      newState = [];
    } else {
      newState = items.map((e) => ({ ...e }));
    }

    const sequenceId = newState.length + 1;

    props.matchEdit
      ? newState.push({ sequenceId, label: '', status: 'active', image: '', pollItemId: null })
      : newState.push({ sequenceId, label: '', status: 'active', image: '' });

    setItems(newState);

    props.onChange && props.onChange(newState);
  };

  const onDelete = (index) => {
    const newState = items.map((e) => ({ ...e }));

    newState.splice(index, 1);
    setItems(newState);
    newState?.forEach((elem, index) => (elem.sequenceId = index + 1));
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = items.map((e) => ({ ...e }));

    newState[index] = { ...newState[index], [field]: value };
    setItems(newState);

    props.onChange && props.onChange(newState);
  };

  const onDrop = ({ removedIndex, addedIndex }) => {
    const newItems = items.map((e) => ({ ...e }));
    let newState = arrayMove(newItems, removedIndex, addedIndex);

    newState.forEach((elem, index) => (elem.sequenceId = index + 1));
    newState = newState.sort((a, b) => ((a.sequenceId > b.sequenceId) ? 1 : -1)) || [];
    setItems(newState);

    props.onChange && props.onChange(newState);
  };

  useEffect(() => {
    !items?.length && setItems(props.items);
  }, [items, props.items]);

  return (
    <>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>
        Abstimmmöglichkeiten
        <IconButton
          onClick={onAdd}
        >
          <AddCircleIcon color="primary" />
        </IconButton>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <List>
            <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
              {items?.map(({ label, sequenceId, status, image }, index) => (
                <Draggable key={sequenceId}>
                  <ListItem>
                    <IconButton className="drag-handle">
                      <DragIndicatorIcon />
                    </IconButton>
                    <ListItemAvatar>
                      <Avatar alt="Icon" src={typeof image === 'object' && image?.name ? URL.createObjectURL(image) : image}><ImageIcon /></Avatar>
                    </ListItemAvatar>
                    <Grid container spacing={2}>
                      <Grid container item md={12} lg={4}>
                        <Grid item xs={11} xl={11}>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Icon"
                            type="file"
                            fullWidth
                            inputProps={{ accept: '.png,.jpg,.jpeg' }}
                            inputRef={(ir) => fileInputRefs.current[index] = ir}
                            onChange={({ target }) => onChange(index, 'image', target.files[0])}
                          />
                        </Grid>
                        <Grid item xs={1} xl={1}>
                          <IconButton
                            onClick={() => {
                              fileInputRefs.current[index].value = '';
                              onChange(index, 'image', null);
                            }}
                            disabled={!image}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid item md={12} lg={4}>
                        <ListItemText>
                          <InputFormField
                            label="Beschriftung"
                            name="label"
                            value={label}
                            required
                            inputProps={{
                              maxLength: 50,
                            }}
                            onChange={({ target }) => onChange(index, 'label', target.value)}
                          />
                        </ListItemText>
                      </Grid>
                      <Grid item md={12} lg={4}>
                        <SelectFormField
                          label="Status"
                          name="status"
                          value={status || 'active'}
                          options={[
                            { label: 'Aktiv', value: 'active' },
                            { label: 'Inaktiv', value: 'inactive' },
                          ]}
                          onChange={({ target }) => onChange(index, 'status', target.value)}
                        />
                      </Grid>
                    </Grid>
                    <IconButton
                      onClick={() => onDelete(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                </Draggable>
              ))}
            </Container>
          </List>
        </Grid>
      </Grid>
    </>
  );
}
