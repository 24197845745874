import { Paper, Grid, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';
import PageTitle from '../../../components/PageTitle';
import { getCustomerGQL, editCustomerGql, addCustomerDirectlyGql, getAvailableCustomerAttributes } from '../gql';
import { getTicketCategoriesGQL } from '../../TicketCategories/gql';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function CustomerEdit() {
  const [customer, setCustomer] = useState({ isActivated: true, ticket: 'basic' });
  const classes = useStyles();
  const snackbar = useSnackbar();
  const matchEdit = useRouteMatch('/:company/:project/participants/edit/:participantEmail');
  const params = useParams();
  const history = useHistory();

  const { refetch, loading } = useQuery(getCustomerGQL, {
    skip: !params.participantEmail,
    variables: {
      companyCode: params.company,
      projectCode: params.project,
      email: params.participantEmail,
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getCustomer }) => {
      setCustomer({ ...getCustomer, ticketCategory: getCustomer.ticketCategory ? { code: getCustomer.ticketCategory.code } : null });
    },
  });

  const { data: getTicketCategoriesData = { getTicketCategories: [] } } = useQuery(getTicketCategoriesGQL, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
  });

  const { data: customerAttributes } = useQuery(getAvailableCustomerAttributes, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [updateCustomer, { loading: updateCustomerLoading }] = useMutation(editCustomerGql, {
    onCompleted: () => { refetch(); showSuccessMessage(); },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) {
        return snackbar.showMessage('Ein Teilnehmer mit der selben Email-Addresse existiert bereits');
      }
      return snackbar.showMessage('Fehler beim Speichern des Teilnehmers');
    },
  });

  const [addCustomer, { loading: addCustomerLoading }] = useMutation(addCustomerDirectlyGql, {

    onCompleted: () => { history.push(`/${params.company}/${params.project}/participants`); showSuccessMessage(); },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) {
        return snackbar.showMessage('Ein Teilnehmer mit der selben Email-Addresse existiert bereits');
      }
      return snackbar.showMessage('Fehler beim Hinzufügen des Teilnehmers');
    },
  });

  const save = (e) => {
    e.preventDefault();
    if (matchEdit) {
      let updatedCustomer = customer;
      if (!customerAttributes?.getAvailableCustomerAttributes?.availableAttributes?.length) {
        const { customAttributes, ...noAttributes } = customer;
        updatedCustomer = noAttributes;
      }
      updateCustomer({
        variables: {
          companyCode: params.company,
          projectCode: params.project,
          email: params.participantEmail,
          customer: { ...updatedCustomer, code: undefined, email: undefined, status: undefined },
        },
      });
      return;
    }
    addCustomer({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        customer: { ...customer, code: undefined, status: undefined },
      },
    });
  };

  if (loading) return (<p>Loading</p>);

  return (
    <>
      <form onSubmit={save}>
        <PageTitle
          title={matchEdit ? 'Teilnehmer bearbeiten' : 'Neuen Teilnehmer hinzufügen'}
          button={(
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              {(updateCustomerLoading || addCustomerLoading) ? <CircularProgress size={14} /> : (matchEdit ? 'Speichern' : 'Hinzufügen')}
            </Button>
          )}
        />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <InputFormField
                label="Email"
                name="email"
                value={customer.email}
                onChange={({ target }) => setCustomer({ ...customer, email: target.value })}
                required
                disabled={!!params.participantEmail}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <SelectFormField
                label="Type"
                name="type"
                value={customer.ticket}
                options={[{ label: 'Admin', value: 'admin' }, { label: 'Basic', value: 'basic' }]}
                onChange={({ target }) => setCustomer({ ...customer, ticket: target.value })}
                required
                fullwidth
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <SelectFormField
                label="Ticketkategorie"
                name="ticketCatgory"
                value={customer.ticketCategory?.code || ''}
                options={getTicketCategoriesData?.getTicketCategories?.map((cat) => ({ label: cat.name, value: cat.code })) || []}
                onChange={({ target }) => setCustomer({ ...customer, ticketCategory: { code: target.value } })}
                required={!!customer.ticketCategory?.code}
                fullwidth
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <InputFormField
                label="Vorname"
                name="firstname"
                value={customer.firstname}
                onChange={({ target }) => setCustomer({ ...customer, firstname: target.value })}
                required
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <InputFormField
                label="Nachname"
                name="lastname"
                value={customer.lastname}
                onChange={({ target }) => setCustomer({ ...customer, lastname: target.value })}
                required
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <SelectFormField
                label="Ist Aktiviert"
                name="isActivated"
                value={customer.isActivated}
                options={[{ label: 'Aktiviert', value: true }, { label: 'Nicht Aktiviert', value: false }]}
                onChange={({ target }) => setCustomer({ ...customer, isActivated: target.value })}
                required
              />
            </Grid>
            {!matchEdit && (
              <>
                <Grid item sm={12} md={6}>
                  <InputFormField
                    label="Passwort"
                    name="password"
                    type="password"
                    inputProps={{
                      minLength: 8,
                    }}
                    value={customer.password}
                    onChange={({ target }) => setCustomer({ ...customer, password: target.value })}
                    required
                  />
                </Grid>
              </>
            )}
            {(customerAttributes?.getAvailableCustomerAttributes?.availableAttributes || []).map((value) => (
              <Grid item sm={12} md={6}>
                <InputFormField
                  label={value.label}
                  name={value.code}
                  value={customer.customAttributes && customer.customAttributes[value.code]}
                  required
                  onChange={({ target }) => setCustomer({
                    ...customer,
                    customAttributes: {
                      ...customer.customAttributes,
                      [value.code]: target.value,
                    },
                  })}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </form>
    </>
  );
}
