import React, { useState } from 'react';
import { Button, CircularProgress, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import SendIcon from '@material-ui/icons/Send';
import { useConfirm } from 'material-ui-confirm';
import { useParams } from 'react-router';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useMutation } from '@apollo/client';
import { deleteAllCustomersGQL, sendInfoMailToAllCustomerGql } from '../gql';
import { useAuthState } from '../../../context/auth';

export default function ParticipantMoreMenu({ onDelete, onExportCustomers, exportLoading, onSetAllActive, activateAllLoading }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAuthState();
  const params = useParams();
  const snackbar = useSnackbar();
  const confirm = useConfirm();
  const open = Boolean(anchorEl);
  const roleCode = parseInt(user.roleCode);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [deleteCustomers] = useMutation(deleteAllCustomersGQL, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted() {
      onDelete && onDelete();
      snackbar.showMessage('Alle Teilnehmer erfolgreich gelöscht');
    },
  });

  const [sendInfoMailToAllCustomer] = useMutation(sendInfoMailToAllCustomerGql, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted() {
      snackbar.showMessage('Der Versand der E-Mails wurde gestartet. Es kann mehreren Stunden dauern bis alle E-Mails versendet wurden sind.');
    },
    onError: (err) => {
      if (err?.graphQLErrors[0]?.extensions.code === 40001) {
        snackbar.showMessage('Fehler beim Versenden der E-Mail! Es wurde kein Template festgelegt');
        return;
      }
      snackbar.showMessage('Fehler beim Versenden der E-Mail');
    },
  });

  const onSetAllActiveClick = () => {
    onSetAllActive();
    handleClose();
  };

  const onExportCustomersClick = () => {
    onExportCustomers();
    handleClose();
  };

  const onDeleteClick = async () => {
    await confirm({
      description: 'Wollen Sie wirklich alle Teilnehmer löschen?',
      confirmationText: 'Löschen',
      confirmationButtonProps: { color: 'secondary' },
    });
    deleteCustomers();
    handleClose();
  };

  const onInfoMailSend = async () => {
    await confirm({
      description: `Wollen Sie die Info-Mail mit den Zugangsinformationen an die Teilnehmer senden?
        Alle Teilnehmer, die bereits eine Info-Mail erhalten haben, werden übersprungen.`,
      confirmationText: 'Info-Mail versenden',
      confirmationButtonProps: { color: 'primary' },
    });
    sendInfoMailToAllCustomer({ variables: { type: 'addCustomer' } });
    handleClose();
  };

  const onInfoReminderMailSend = async () => {
    await confirm({
      description: `Wollen Sie die Erinnerungsmail für die Info-Mail mit den Zugangsinformationen an die Teilnehmer senden?
        Alle Teilnehmer, die bereits eine Info-Mail erhalten haben, werden übersprungen.`,
      confirmationText: 'Erinnerungsmail für Info-Mail versenden',
      confirmationButtonProps: { color: 'primary' },
    });
    sendInfoMailToAllCustomer({ variables: { type: 'addCustomerReminder' } });
    handleClose();
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
      >
        Mehr
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {([0, 1, 2].includes(roleCode)) ? (
          <>
            <MenuItem onClick={onInfoMailSend}>
              <ListItemIcon>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Info-Mail an alle Teilnehmer senden
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={onInfoReminderMailSend}>
              <ListItemIcon>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Erinnerungsmail für Info-Mail an alle Teilnehmer senden
              </ListItemText>
            </MenuItem>
            <Divider />
          </>
        ) : null}
        <MenuItem onClick={onExportCustomersClick}>
          <ListItemIcon>
            <CloudDownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {exportLoading ? (
              <CircularProgress size={14} />
            ) : (
              'Teilnehmer exportieren'
            )}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={onSetAllActiveClick}>
          <ListItemIcon>
            <VerifiedUserIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {activateAllLoading ? (
              <CircularProgress size={14} />
            ) : (
              'Alle Teilnehmer Aktivieren'
            )}
          </ListItemText>
        </MenuItem>
        {([0, 1].includes(roleCode)) ? (
          <MenuItem onClick={onDeleteClick}>
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Alle Teilnehmer löschen</ListItemText>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
}
