import React from 'react';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import InputFormField from '../../../components/forms/InputFormField';
import ColorList from './ColorList';
import SectionLayout from '../../../components/SectionLayout';

export default function WordCloudParams({ params, onChange }) {
  return (
    <SectionLayout>
      <Grid item xs={12} xl={12}>
        <Typography variant="h5" style={{ marginTop: 10, marginBottom: 30 }}>Einstellungen</Typography>
      </Grid>
      <Grid item xs={12} xl={12}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.allowMultipleAnswers}
                  onChange={({ target }) => onChange({ ...params, allowMultipleAnswers: target.checked })}
                  name="allowMultipleAnswers"
                />
                    )}
              label="Mehrfache Antworten erlauben"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.charLimit}
                  onChange={({ target }) => onChange({ ...params, charLimit: target.checked })}
                  name="charLimit"
                />
                    )}
              label="Zeichenanzahl limitieren"
            />
            {
              params?.charLimit
              && (
              <InputFormField
                label="Zeichenanzahl Limit"
                name="charLimitCount"
                value={params.charLimitCount || 1}
                InputProps={{ inputProps: { min: 1, max: 255 } }}
                onChange={({ target }) => onChange({ ...params, charLimitCount: target.value })}
                type="number"
                required
              />
              )
            }
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.wordLimit}
                  onChange={({ target }) => onChange({ ...params, wordLimit: target.checked })}
                  name="wordLimit"
                />
                    )}
              label="Maximal anzuzeigende Wörter limitieren"
            />
            {
              params?.wordLimit
              && (
              <InputFormField
                label="Anzuzeigende Wörter Limit"
                name="wordLimitCount"
                value={params.wordLimitCount || 1}
                onChange={({ target }) => onChange({ ...params, wordLimitCount: target.value })}
                type="number"
                required
              />
              )
            }
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} xl={12}>
        <InputFormField
          label="Abstand der Wörter"
          name="padding"
          value={params.padding || 1}
          InputProps={{ inputProps: { min: 0 } }}
          onChange={({ target }) => onChange({ ...params, padding: target.value })}
          type="number"
          required
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <ColorList
          colors={params.colors}
          onChange={(colors) => onChange({ ...params, colors })}
        />
      </Grid>
    </SectionLayout>
  );
}
