import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Container, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import { useMutation, gql } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '10vh',
  },
  form: {
    flexGrow: 1,
  },
  grid: {
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridElement: {
    width: '100%',
  },

  button: {
    width: '80%',
  },
}));

const SET_PASSWORD_GQL = gql`
  mutation setUserPassword($verificationToken: String!, $password: String!) {
    setUserPassword(verificationToken: $verificationToken, password: $password)
  }
`;

export default function SetPasswordPage() {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState(null);
  const [fieldError, setFieldError] = useState(null);
  const [success, setSuccess] = useState(false);
  const params = useParams();

  const [setPassword, { loading }] = useMutation(SET_PASSWORD_GQL);

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setFieldError(null);

    if (formValues.password !== formValues.passwordRepeat) {
      setFieldError('Die eingegebenen Passwörter stimmen nicht überein');
      return;
    }

    try {
      await setPassword({
        variables: { verificationToken: params.verificationToken, password: formValues.password },
      });
      setSuccess(true);
    } catch (err) {
      if (err?.graphQLErrors[0]?.extensions?.code === 400) {
        setError('Der Link zum Passwort setzen ist bereits abgelaufen.');
        return;
      }
      setError('Ein Fehler ist aufgetretten. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Passwort setzen - WIRKUNG.hub</title>
      </Helmet>
      <Container>
        <Grid
          className={classes.root}
          container
          spacing={0}
          justify="center"
          direction="row"
        >
          <Grid item xs={12} sm={6} md={5} lg={5} xl={5} style={{ textAlign: 'center' }}>
            <img
              src="https://public.storage.wirkung.stream/_platform/WD_logo.svg"
              alt=""
              style={{ width: '30%', marginBottom: 10 }}
            />

            <Paper className={classes.paper} elevation={5}>
              <form onSubmit={onSubmit} autoComplete="off" className={classes.form}>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Typography variant="h6">
                      Passwort setzen
                    </Typography>
                  </Grid>
                  {(success) ? (
                    <>
                      <Typography style={{ marginBottom: 20 }}>
                        Das Passwort wurde erfolgreich gesetzt! <br />
                        Ihr Benutzeraccount ist nun bereit zum Einloggen.
                      </Typography>
                      <Button className={classes.gridElement} variant="contained" href="/">Zum Login</Button>
                    </>

                  ) : (
                    <>
                      <Grid item>
                        <TextField
                          autoComplete="current-password"
                          className={classes.gridElement}
                          id="password"
                          type="password"
                          label="Passwort"
                          onChange={(e) => setFormValues({ ...formValues, password: e.target.value })}
                          required
                          inputProps={{ minLength: 6 }}
                          error={!!fieldError}
                          helperText={fieldError}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          autoComplete="current-password"
                          className={classes.gridElement}
                          id="passwordRepeat"
                          type="password"
                          label="Passwort wiederholen"
                          onChange={(e) => setFormValues({ ...formValues, passwordRepeat: e.target.value })}
                          required
                          inputProps={{ minLength: 6 }}
                          error={!!fieldError}
                          helperText={fieldError}
                        />
                      </Grid>
                      <Grid item style={{ marginTop: 10 }}>
                        <Button className={classes.gridElement} variant="contained" type="submit" disabled={loading}>Absenden</Button>
                        {(error) ? (
                          <Typography
                            style={{ color: '#8f0000', marginTop: 15 }}
                          >
                            &nbsp;{error}
                          </Typography>
                        ) : null}

                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
