import React from 'react';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import PollItems from './PollItems';
import SectionLayout from '../../../components/SectionLayout';

export default function SingleChoiceParams({ params, onChange, matchEdit }) {
  return (
    <SectionLayout>
      <Grid item xs={12} xl={12}>
        <Typography variant="h5" style={{ marginTop: 10, marginBottom: 30 }}>
          Einstellungen
        </Typography>
      </Grid>
      <Grid item xs={12} xl={12}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.canChangeVote}
                  onChange={({ target }) => onChange({ ...params, canChangeVote: target.checked })}
                  name="canChangeVote"
                />
                    )}
              label="Stimme nachträglich änderbar"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.showTotal}
                  onChange={({ target }) => onChange({ ...params, showTotal: target.checked })}
                  name="showTotal"
                />
                    )}
              label="Gesamtanzahl der Stimmen anzeigen"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={!params?.isAbsolute}
                  onChange={({ target }) => onChange({ ...params, isAbsolute: !target.checked })}
                  name="isAbsolute"
                />
                    )}
              label="Stimmen in Prozent anzeigen"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.hideVotes}
                  onChange={({ target }) => onChange({ ...params, hideVotes: target.checked })}
                  name="hideVotes"
                />
                    )}
              label="Stimmen bis zur Stimmabgabe verbergen"
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} xl={12}>
        <PollItems
          buttons={params?.pollItems}
          items={params?.pollItems}
          matchEdit={matchEdit}
          onChange={(pollItems) => onChange({ ...params, pollItems })}
        />
      </Grid>
    </SectionLayout>
  );
}
