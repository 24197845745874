import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Paper, Button, CircularProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useSnackbar } from 'material-ui-snackbar-provider';
import moment from 'moment';
import 'moment/locale/de';
import { getCustomerEventsGQL, exportCustomerEventsGQL, queryFilterDataGQL } from '../gql';
import PageTitle from '../../../components/PageTitle';
import ReportFilter from '../components/ReportFilter';
import { datagrid } from '../../../util/language';

export default function Report() {
  const params = useParams();
  const { companyCode, projectCode } = params;
  const snackbar = useSnackbar();
  const [pageLimit, setPageLimit] = useState(100);
  const [page, setPage] = useState(0);

  const [filterValues, setFilterValues] = useState({
    startDate: null,
    endDate: null,
    groupByCustomer: false,
    target: 'all',
    targetType: 'all',
    pageOptions: [],
    ctaOptions: [],
  });

  function dateIsValid(date) {
    if (!date || Number.isNaN(new Date(date).getTime())) return false;
    return true;
  }

  function createFilterQueryString() {
    const filterQuery = { $and: [] };

    if (dateIsValid(filterValues.startDate)) {
      filterQuery.$and.push({
        eventDate: {
          $gt: filterValues.startDate.toISOString(),
        },
      });
    }

    if (dateIsValid(filterValues.endDate)) {
      filterQuery.$and.push({
        eventDate: {
          $lt: filterValues.endDate.toISOString(),
        },
      });
    }

    if (filterValues.targetType !== 'all') {
      filterQuery.$and.push({
        targetType: filterValues.targetType,
      });
    }

    if (filterValues.target !== 'all') {
      filterQuery.$and.push({
        target: filterValues.target,
      });
    }

    return filterQuery;
  }

  const [exportReport, { loading: exportLoading }] = useLazyQuery(exportCustomerEventsGQL, {
    onCompleted: (data) => window.open(data.exportCustomerEvents.url, '_blank'),
    onError: () =>
      snackbar.showMessage('Fehler beim Exportieren der Auswertung'),
  });

  const { loading, error, data } = useQuery(getCustomerEventsGQL, {
    variables: {
      companyCode,
      projectCode,
      limit: pageLimit,
      offset: pageLimit * page,
      filter: createFilterQueryString(),
      groupByCustomer: filterValues.groupByCustomer,
    },
  });

  const {
    loading: loadingFilters,
  } = useQuery(queryFilterDataGQL, {
    fetchPolicy: 'no-cache',
    variables: { companyCode, projectCode },
    onCompleted: ({ getProjectTimes, getCustomPages, getAllConversionCards: { conversionCards } }) => {
      const pageOptions = getCustomPages.map(({ path, name }) => ({ value: path, label: name }));
      const ctaOptions = conversionCards
        .filter(({ params }) => params.actionEmitter)
        .map(({ code, name }) => ({ value: code, label: name }));

      pageOptions.unshift({ optionType: 'subheader', value: 'Projektseiten' });
      ctaOptions.unshift({ optionType: 'subheader', value: 'Karten' });

      const filterDates = getProjectTimes.reduce((acc, cur) => {
        if (Date.parse(cur.end) <= Date.parse(acc.end)) {
          acc.endDate = moment(cur.end).toDate();
        }

        if (Date.parse(cur.start) <= Date.parse(acc.startDate)) {
          acc.startDate = moment(cur.start).toDate();
        }

        if (Date.parse(cur.start) > Date.parse(acc.startDate)) {
          acc.startDate = null;
          acc.endDate = null;
        }

        return acc;
      }, { startDate: new Date(), endDate: new Date() });

      setFilterValues({ ...filterValues, pageOptions, ctaOptions, ...filterDates });
    },
  });

  const columns = [
    { field: 'id', hide: true, type: 'number' },
    { field: 'email', headerName: 'E-Mail', type: 'string', flex: 0.5 },
    { field: 'firstname', headerName: 'Vorname', type: 'string', flex: 0.5 },
    { field: 'lastname', headerName: 'Nachname', type: 'string', flex: 0.5 },
    {
      field: 'targetType',
      headerName: 'Eventtyp',
      type: 'string',
      width: 120,
      renderCell: ({ value }) => (
        value === 'page'
          ? 'Seitenaufruf' : 'Klick auf Karte'
      ),
    },
    { field: 'target', headerName: 'Eventziel', type: 'string', flex: 0.5 },
    {
      field: 'eventDate',
      headerName: 'Zeitpunkt',
      type: 'date',
      flex: 0.5,
      valueFormatter: ({ value }) => (value ? moment(value).format('L LTS') : ''),
    },
  ];

  if (error) {
    return <p>Fehler beim laden</p>;
  }

  return (
    <div>
      <PageTitle
        title="Auswertung"
        button={(
          <Button
            variant="contained"
            color="default"
            onClick={async () => exportReport({
              variables: {
                companyCode,
                projectCode,
                filter: createFilterQueryString(),
                groupByCustomer: filterValues.groupByCustomer,
              },
            })}
          >
            {exportLoading ? (
              <CircularProgress size={14} />
            ) : (
              'Liste exportieren'
            )}
          </Button>
        )}
      />
      <ReportFilter
        filters={filterValues}
        onChange={setFilterValues}
        loading={loadingFilters}
      />
      <Paper>
        <DataGrid
          style={{ marginTop: '20px' }}
          columns={columns}
          loading={loading}
          rows={data?.getCustomerEvents?.events.map((row, id) => (
            { ...row, id }
          )) || []}
          getRowId={(row) => row.email}
          pageSize={pageLimit}
          page={page}
          rowCount={data?.getCustomerEvents?.totalCount}
          autoHeight
          hideFooterRowCount="false"
          localeText={datagrid}
          paginationMode="server"
          pagination
          disableColumnMenu
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          onPageSizeChange={(size) => {
            setPageLimit(size.pageSize);
            setPage(0);
          }}
          onPageChange={(params) => setPage(params.page)}
        />
      </Paper>
    </div>
  );
}
