import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import InputFormField from '../../../components/forms/InputFormField';

export default function IFrameCTAParams({ params, onChange }) {
  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant="h5">Website Parameter</Typography>
      </Grid>
      <Grid item xs={12} xl={6}>
        <InputFormField
          label="IFrame Quelle"
          name="src"
          value={params?.iFrame?.src}
          onChange={({ target }) => onChange({ ...params, iFrame: { ...params.iFrame, src: target.value } })}
          required
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <InputFormField
          label="IFrame Höhe"
          inputProps={{ type: 'number' }}
          name="height"
          value={params?.iFrame?.height}
          onChange={({ target }) => onChange({ ...params, iFrame: { ...params.iFrame, height: target.value } })}
        />
      </Grid>
    </>
  );
}
