import { gql } from '@apollo/client';

export const getProjectTimesGql = gql`
  query getProjectTimes($companyCode: String!, $projectCode: String!){
    getProjectTimes(companyCode: $companyCode, projectCode: $projectCode){
      start
      end
    }
  }
`;

export const setProjectTimesGql = gql`
  mutation setProjectTimes($companyCode: String!, $projectCode: String!, $projectTimes: [SetProjectTime]!){
    setProjectTimes(companyCode: $companyCode, projectCode: $projectCode, projectTimes: $projectTimes)
  }
`;
