import { Grid,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import arrayMove from 'array-move';
import { Container, Draggable } from 'react-smooth-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import StreamElement from './StreamElement';
import CTAElement from './CTAElement';
import JSXElement from './JSXElement';
import OnDemandPlayerElement from './OnDemandPlayerElement';
import FormElement from './FormElement';
import StreamWithCtaElement from './StreamWithCtaElement';

export default function PageElements(props) {
  const [elements, setElements] = useState(props?.elements || []);
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const handleAddClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const onAdd = (type) => {
    let newState;

    if (!elements?.length) {
      newState = [];
    } else {
      newState = elements.map((e) => ({ ...e }));
    }

    const sequenceId = newState.length + 1;
    newState.push({ component: type, sequenceId });

    setElements(newState);

    props.onChange && props.onChange(newState);
    handleClose();
  };

  const onDelete = (index) => {
    const newState = elements.map((e) => ({ ...e }));

    newState.splice(index, 1);
    setElements(newState);

    newState?.forEach((elem, index) => { newState[index].sequenceId = index + 1; });
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = elements.map((e) => ({ ...e }));

    newState[index] = { ...newState[index], [field]: value };
    setElements(newState);

    props.onChange && props.onChange(newState);
  };

  const onDrop = ({ removedIndex, addedIndex }) => {
    const newItems = elements.map((e) => ({ ...e }));
    let newState = arrayMove(newItems, removedIndex, addedIndex);

    newState?.forEach((elem, index) => { newState[index].sequenceId = index + 1; });
    newState = newState.sort((a, b) => ((a.sequenceId > b.sequenceId) ? 1 : -1)) || [];
    setElements(newState);

    props.onChange && props.onChange(newState);
  };

  useEffect(() => {
    !elements?.length && setElements(props.elements);
  }, [elements, props.elements]);

  const renderElement = (index, element) => {
    switch (element.component) {
      case 'cta':
        return <CTAElement index={index} element={element} onChange={onChange} />;
      case 'stream':
        return <StreamElement index={index} element={element} onChange={onChange} />;
      case 'jsx':
        return <JSXElement index={index} element={element} onChange={onChange} />;
      case 'video':
        return <OnDemandPlayerElement index={index} element={element} onChange={onChange} />;
      case 'form':
        return <FormElement index={index} element={element} onChange={onChange} />;
      case 'streamWithCta':
        return <StreamWithCtaElement index={index} element={element} onChange={onChange} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Typography variant="h6" style={{ marginBottom: -10, fontWeight: 'normal' }}>
        Seitenelemente
        <IconButton
          onClick={handleAddClick}
        >
          <AddCircleIcon color="primary" />
        </IconButton>
        <Menu
          id="Add-Menue"
          anchorEl={anchorMenu}
          keepMounted
          open={Boolean(anchorMenu)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => onAdd('stream')}>Stream</MenuItem>
          <MenuItem onClick={() => onAdd('streamWithCta')}>Stream mit Karten</MenuItem>
          <MenuItem onClick={() => onAdd('cta')}>Karten</MenuItem>
          <MenuItem onClick={() => onAdd('jsx')}>Dynamisches HTML (JSX)</MenuItem>
          <MenuItem onClick={() => onAdd('video')}>Statisches Video</MenuItem>
          <MenuItem onClick={() => onAdd('form')}>Formular</MenuItem>
        </Menu>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <List>
            <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
              {elements?.map((element, index) => (
                <Draggable key={element.code}>
                  <ListItem>
                    <IconButton className="drag-handle">
                      <DragIndicatorIcon />
                    </IconButton>
                    <Grid container spacing={2} alignItems="center">
                      {renderElement(index, element)}
                    </Grid>
                    <IconButton
                      onClick={() => onDelete(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                </Draggable>
              ))}
            </Container>
          </List>
        </Grid>
      </Grid>
    </>
  );
}
