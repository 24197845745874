import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import TextField from '@material-ui/core/TextField';

const UploadMutation = gql`
  mutation uploadImage($file: Upload!) {
    uploadImage(file: $file) {       
      url
    }
  }
`;

export default function FileUpload(propsUpload) {
  const snackBar = useSnackbar();

  const [uploaded, setUploaded] = useState({
    name: '',
    url: '',
  });

  const [uploadFile] = useMutation(UploadMutation, {
    onCompleted(data) {
      setUploaded({
        url: data.uploadImage.url,
      });
      propsUpload.parentUploadHandler({
        name: uploaded.name,
        url: data.uploadImage.url,
      });
    },
    onError: () => {
      snackBar.showMessage('Fehler beim Hochladen des Bildes');
    },
  });

  return (
    <TextField
      margin="dense"
      id="name"
      label="Bild auswählen"
      type="file"
      accept="image/*"
      fullWidth
      onChange={({ target: { files } }) => {
        const file = files[0];
        file && uploadFile({ variables: { file } });
        if (file) {
          setUploaded({
            name: file.name,
          });
        }
      }}
    />
  );
}
