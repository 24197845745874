import React from 'react';
import {
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import PollItems from './PollItems';
import InputFormField from '../../../components/forms/InputFormField';
import SectionLayout from '../../../components/SectionLayout';

export default function MultipleChoiceParams({ params, onChange, matchEdit }) {
  return (
    <SectionLayout>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ marginTop: 10, marginBottom: 30 }}>
          Einstellungen
        </Typography>
      </Grid>
      <Grid item xs={12} xl={12}>
        <InputFormField
          label="Anzahl Antwortmöglichkeiten"
          name="voteCount"
          value={params.placeableVotesCount || 1}
          InputProps={{ inputProps: { min: 1, max: params?.pollItems?.length } }}
          onChange={({ target }) => onChange({ ...params, placeableVotesCount: parseInt(target.value) })}
          type="number"
          required
          error={params?.placeableVotesCount > params?.pollItems?.length}
          helperText={params?.placeableVotesCount > params?.pollItems?.length
           && 'Ein Teilnehmer kann maximal soviele Antworten auswählen wie es Antwortmöglichkeiten gibt.'}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.showTotal}
                  onChange={({ target }) => onChange({ ...params, showTotal: target.checked })}
                  name="showTotal"
                />
                    )}
              label="Gesamtanzahl der Stimmen anzeigen"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.showCustomersVotedCount}
                  onChange={({ target }) => onChange({ ...params, showCustomersVotedCount: target.checked })}
                  name="showCustomerVotedCount"
                />
                    )}
              label="Anzahl der Personen anzeigen"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={!params?.isAbsolute}
                  onChange={({ target }) => onChange({ ...params, isAbsolute: !target.checked })}
                  name="isAbsolute"
                />
                    )}
              label="Stimmen in Prozent anzeigen"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={params?.hideVotes}
                  onChange={({ target }) => onChange({ ...params, hideVotes: target.checked })}
                  name="hideVotes"
                />
                    )}
              label="Stimmen bis zur Stimmabgabe verbergen"
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} xl={12}>
        <PollItems
          buttons={params?.pollItems}
          items={params?.pollItems}
          matchEdit={matchEdit}
          onChange={(pollItems) => onChange({ ...params, pollItems })}
        />
      </Grid>
    </SectionLayout>
  );
}
