import React from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import UploadFile from './UploadFile';
import InputFormField from '../../../components/forms/InputFormField';

function ImageCTAParams({ params, onChange }) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Bild</Typography>
      </Grid>
      <Grid container item xs={12} md={6} direction="row">
        <Grid xs={11} item>
          <UploadFile parentUploadHandler={({ url }) => onChange({ ...params, imgURL: url })} />
        </Grid>
        <Grid xs={1} item>
          <IconButton
            onClick={((e) => {
              e.preventDefault();
              onChange({ ...params, imgURL: '' });
            })}
            disabled={!params?.imgURL}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      {params?.imgURL && (
      <Grid item xs={12} md={6}>
        <InputFormField
          label="Alt-Text"
          name="altText"
          value={params?.altText}
          onChange={({ target }) => onChange({ ...params, altText: target.value })}
          inputProps={{
            maxLength: 255,
          }}
        />
      </Grid>
      )}
    </>
  );
}

export default ImageCTAParams;
