import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import InputFormField from '../../../components/forms/InputFormField';

export default function ZoomParams(props) {
  const [zoomMeetings, setZoomMeetings] = useState(props.zoomMeetings || []);

  const onAdd = () => {
    const newState = [...zoomMeetings];
    newState.push({ meetingId: '', passcode: '' });
    setZoomMeetings(newState);
    props.onChange && props.onChange(newState);
  };
  const onDelete = (index) => {
    const newState = [...zoomMeetings];
    newState.splice(index, 1);
    setZoomMeetings(newState);
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = [...zoomMeetings];
    newState[index] = { ...newState[index], [field]: value };
    setZoomMeetings(newState);
    props.onChange && props.onChange(newState);
  };

  return (
    <>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>
        Zoom Meetings
        <Button
          variant="contained"
          style={{ borderRadius: 50, minWidth: 0, marginLeft: 5, padding: '0px 8px' }}
          color="primary"
          onClick={onAdd}
        >
          +
        </Button>
      </Typography>
      {zoomMeetings && zoomMeetings.map((meeting, index) => (
        <Grid container spacing={3}>
          <Grid item xs={0.5}>
            <Typography style={{ marginTop: '15px' }}>
              {index + 1}
              .
            </Typography>
          </Grid>
          <Grid item xs={3.5}>
            <InputFormField
              label="Meeting Id"
              name="meetingId"
              value={meeting.meetingId}
              onChange={({ target }) => onChange(index, 'meetingId', target.value.replace(/ /g, ''))}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <InputFormField
              label="Passcode"
              name="passcode"
              value={meeting.passcode}
              onChange={({ target }) => onChange(index, 'passcode', target.value)}
              required
            />
          </Grid>

          {props.isExtern
            && (
            <Grid item xs={4}>
              <InputFormField
                label="Einladungs Link"
                name="join_url"
                value={meeting.join_url}
                onChange={({ target }) => onChange(index, 'join_url', target.value)}
                required
              />
            </Grid>
            )}

          <Grid item xs={1}>
            <Button variant="contained" style={{ marginTop: '8px' }} color="secondary" onClick={() => onDelete(index)}>Löschen</Button>
          </Grid>
        </Grid>
      ))}

    </>
  );
}
