import { React, useMemo } from 'react';
import ReactWordcloud from 'react-wordcloud';

export default function MultipleChoiceResult({ poll, settings }) {
  const options = {
    deterministic: true,
    fontFamily: settings.fontFamily || 'Roboto',
    fontSizes: [settings.minFontSize || 15, settings.maxFontSize || 60],
    fontStyle: 'normal',
    fontWeight: settings.fontWeight || 'normal',
    padding: 4,
    rotations: 0,
    rotationAngles: [0, 90],
    scale: settings.scale || 'log',
    transitionDuration: 1000,
    enableTooltip: false,
  };
  if (poll?.params?.colors?.length) options.colors = poll.params.colors;
  else if (settings?.colors?.length) options.colors = settings.colors;

  const minSize = useMemo(() => (
    [400, settings.minHeight || 300]
  ), [settings.minHeight]);

  return (
    <ReactWordcloud
      options={options}
      words={poll?.params?.words}
      minSize={minSize}
    />
  );
}
