import React, { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { formGql, addFormGql, editFormGql, exportFormGql } from '../gql';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';
import PageTitle from '../../../components/PageTitle';
import FormFields from '../components/FormFields';
import SectionLayout from '../../../components/SectionLayout';

const useStyles = makeStyles((theme) => ({
  optionsButtonGroup: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      marginBlockStart: theme.spacing(1),
    },
  },
}));

const defaults = {
  status: 'active',
  automaticallySendToSpeaker: false,
};

export default function FormEdit() {
  const history = useHistory();
  const match = useRouteMatch();
  const matchEdit = useRouteMatch('/:company/:project/forms/edit/:code');
  const matchCopy = useRouteMatch('/:company/:project/forms/copy/:code');
  const matchAdd = useRouteMatch('/:company/:project/forms/add');
  const params = useParams();
  const { companyCode, projectCode, code } = params;
  const classes = useStyles();
  const snackbar = useSnackbar();
  const [form, setForm] = useState(defaults);

  const { loading } = useQuery(formGql, {
    skip: matchAdd,
    variables: { companyCode, projectCode, code },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getForm }) => {
      if (!matchCopy) return setForm(getForm);

      const fields = getForm.fields.map((field) => ({ ...field, id: undefined }));
      return setForm({ ...getForm, code: undefined, fields });
    },
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [exportForm, { loading: exportLoading }] = useLazyQuery(exportFormGql, {
    variables: { companyCode, projectCode, code },
    onCompleted: (data) => window.open(data.exportForm.url, '_blank'),
    onError: () =>
      snackbar.showMessage('Fehler beim exportieren des Formulars'),
  });

  const [addForm, { loading: addFormLoading }] = useMutation(addFormGql, {
    onCompleted: ({ createForm }) => {
      if (!window.sessionStorage.getItem('ctaInputs')) {
        matchCopy
          ? history.replace(match.url.replace(`/copy/${code}`, ''))
          : history.replace(match.url.replace('/add', ''));
      }

      if (window.sessionStorage.getItem('ctaInputs')) {
        const { data, url } = JSON.parse(window.sessionStorage.getItem('ctaInputs'));
        data.params.formCode = createForm.code;
        window.sessionStorage.setItem('ctaInputs', JSON.stringify({ data }));
        history.replace(match.url.replace(match.url, url));
      }
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Ein Formular mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim Erstellen des Formulars');
    },
  });

  const [editForm, { loading: editFormLoading }] = useMutation(editFormGql, {
    onCompleted: () => {
      history.replace(match.url.replace(`/edit/${code}`, ''));
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Ein Formular mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim Speichern des Formulars');
    },
  });

  const save = (e) => {
    e.preventDefault();

    if (matchEdit) {
      const { code, ...noCode } = form;

      editForm({ variables: { companyCode, projectCode, code, form: noCode } });
      return true;
    }

    addForm({ variables: { companyCode, projectCode, form: { ...form, __typename: undefined } } });
    return true;
  };

  if (loading) return (<p>Loading</p>);

  return (
    <form onSubmit={save}>
      <PageTitle
        title={matchEdit ? 'Formular bearbeiten' : 'Neues Formular hinzufügen'}
        button={(
          <div className={classes.optionsButtonGroup}>
            {matchEdit && (
            <>
              <Button
                variant="contained"
                color="default"
                onClick={async () => {
                  exportForm();
                }}
              >
                {exportLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  'Einreichungen exportieren'
                )}
              </Button>
              <Button
                variant="contained"
                onClick={() => history.push(`${match.url}/moderator`)}
              >
                Moderatorenansicht
              </Button>
              <Button
                variant="contained"
                onClick={() => history.push(`${match.url}/results`)}
              >
                Referentenansicht
              </Button>
            </>
            )}
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ marginLeft: '15px' }}
            >
              { (addFormLoading || editFormLoading) ? <CircularProgress size={14} /> : (matchEdit ? 'Speichern' : 'Erstellen') }
            </Button>
          </div>
          )}
      />
      <SectionLayout>
        <Grid container item spacing={3} sm={12} md={12} lg={12}>
          <Grid item xs={12} xl={6}>
            <InputFormField
              label="Titel"
              name="title"
              value={form.title}
              onChange={({ target }) => setForm({ ...form, title: target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <InputFormField
              label="Beschreibung"
              name="description"
              value={form.description}
              inputProps={{
                maxLength: 500,
              }}
              onChange={({ target }) => setForm({ ...form, description: target.value })}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SelectFormField
              label="Status"
              name="status"
              value={form.status}
              options={[
                { label: 'Aktiv', value: 'active' },
                { label: 'Inaktiv', value: 'inactive' },
              ]}
              onChange={({ target }) => setForm({ ...form, status: target.value })}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SelectFormField
              label="Verhalten bei neuen Einreichungen"
              name="automaticallySendToSpeaker"
              value={form.automaticallySendToSpeaker ? 'true' : 'false'}
              options={[
                { label: 'Automatisch posten', value: true },
                { label: 'Manuell posten', value: false },
              ]}
              onChange={({ target }) => setForm({ ...form, automaticallySendToSpeaker: target.value })}
            />
          </Grid>
          <FormFields fields={form.fields} onChange={(fields) => setForm({ ...form, fields })} />
        </Grid>
      </SectionLayout>
    </form>
  );
}
