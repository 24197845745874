import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputFormField from '../../../components/forms/InputFormField';

export default function CaptionCTAParams({ params, onChange }) {
  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant="h5">Text</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <InputFormField
          label="Überschrift"
          name="title"
          value={params?.title}
          inputProps={{
            maxLength: 255,
          }}
          onChange={({ target }) => onChange({ ...params, title: target.value })}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <InputFormField
          label="Inhalt"
          name="content"
          multiline
          inputProps={{
            maxLength: 500,
          }}
          rows={5}
          rowsMax={10}
          value={params?.content}
          onChange={({ target }) => onChange({ ...params, content: target.value })}
        />
      </Grid>

    </>
  );
}
