import { gql } from '@apollo/client';

export const getInvitesGQL = gql`
  query getInvites(
    $companyCode: String!
    $projectCode: String!
    $limit: Int
    $search: String
    $offset: Int
  ) {
    getInvites(
      companyCode: $companyCode
      projectCode: $projectCode
      limit: $limit
      search: $search
      offset: $offset
    ) {
      totalCount
      invites {
        firstname
        lastname
        email
        inviteToken
        customer {
          firstname
          lastname
        }
        ticketCategory {
          code
          name
        }
        mailSend
      }
    }
  }
`;

export const sendInviteMailsMutation = gql`
  mutation sendInviteMails($companyCode: String!, $projectCode: String!) {
    sendInviteMails(companyCode: $companyCode, projectCode: $projectCode)
  }
`;

export const sendInviteMailMutation = gql`
mutation sendInviteMail($companyCode: String!, $projectCode: String!, $inviteToken: String!){
  sendInviteMail(companyCode: $companyCode, projectCode: $projectCode, inviteToken: $inviteToken)
}`;

export const deleteInviteMutation = gql`
mutation deleteInvite($companyCode: String!, $projectCode: String!, $inviteToken: String!){
  deleteInvite(companyCode: $companyCode, projectCode: $projectCode, inviteToken: $inviteToken)
}`;

export const sendMarketingMailToInvitesMutation = gql`
  mutation sendMarketingMailToInvites(
    $companyCode: String!
    $projectCode: String!
  ) {
    sendMarketingMailToInvites(
      companyCode: $companyCode
      projectCode: $projectCode
    )
  }
`;

export const uploadMutation = gql`
  mutation importInviteCSV(
    $companyCode: String!
    $projectCode: String!
    $file: Upload!
  ) {
    importInviteCSV(
      companyCode: $companyCode
      projectCode: $projectCode
      file: $file
    ) {
      hasErrors
      errors
      overallCounts {
        imported
        failed
      }
    }
  }
`;

export const addInviteGql = gql`
  mutation addInvite(
    $companyCode: String!
    $projectCode: String!
    $invite: AddInvite!
  ) {
    addInvite(
      companyCode: $companyCode
      projectCode: $projectCode
      invite: $invite
    )
  }
`;
