import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import InputFormField from '../../../components/forms/InputFormField';
import { GET_CATEGORIES } from '../gql';
import SelectFormField from '../../../components/forms/SelectFormField';

export default function CategoryParams(props) {
  const [selectedCategories, setSelectedCategories] = useState(props.cat || []);
  const params = useParams();

  useEffect(() => {
    setSelectedCategories(props.cat || []);
  }, [props]);

  const {
    data: { getAllMediaLibraryCategories } = {},
  } = useQuery(GET_CATEGORIES, {
    variables: { companyCode: params.company, projectCode: params.project },
    fetchPolicy: 'no-cache',
  });
  const availableCategories = getAllMediaLibraryCategories?.categories || [];

  const onAdd = () => {
    const newState = [...selectedCategories];
    newState.push({ code: '', sequenceId: '' });
    setSelectedCategories(newState);
    props.onChange && props.onChange(newState);
  };

  const onDelete = (index) => {
    const newState = [...selectedCategories];
    newState.splice(index, 1);
    setSelectedCategories(newState);
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = [...selectedCategories];
    newState[index][field] = value;
    setSelectedCategories(newState);
    props.onChange && props.onChange(newState);
  };

  return (
    <>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>
        Kategorie
        <Button
          variant="contained"
          style={{ borderRadius: 50, minWidth: 0, marginLeft: 5, padding: '0px 8px' }}
          color="primary"
          onClick={onAdd}
        >
          +
        </Button>
      </Typography>
      {selectedCategories && selectedCategories.map((category, index) => (
        <Grid container spacing={3} style={{ marginTop: 10 }} key={category.code}>
          <Grid item xs={0.5}>
            <Typography style={{ marginTop: '15px' }}>
              {index + 1}
              .
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <SelectFormField
              label="Kategory"
              name="code"
              value={category.code}
              options={
                availableCategories
                  ? availableCategories.map(({ code, name }) => ({ label: name, value: code })) : []
              }
              required
              onChange={({ target }) => onChange(index, 'code', target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <InputFormField
              inputProps={{ type: 'number' }}
              label="Sequenz"
              name="sequenceId"
              value={category.sequenceId}
              onChange={({ target }) => onChange(index, 'sequenceId', parseInt(target.value))}
              required
            />
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              style={{ marginTop: '8px' }}
              color="secondary"
              onClick={() => onDelete(index)}
            >
              Löschen
            </Button>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
