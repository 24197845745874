import React, { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';
import PageTitle from '../../../components/PageTitle';
import SingleChoiceParams from '../components/SingleChoiceParams';
import MultipleChoiceParams from '../components/MultipleChoiceParams';
import WordCloudParams from '../components/WordCloudParams';
import SectionLayout from '../../../components/SectionLayout';
import { addPollGql, getPollGql, getFullPollGql, updatePollGql } from '../gql';

const defaults = {
  status: 'active',
  type: 'singleChoice',
  params: {},
};

export default function PollEdit() {
  const history = useHistory();
  const params = useParams();
  const { companyCode, projectCode, code } = params;
  const match = useRouteMatch();
  const matchAdd = useRouteMatch('/:company/:project/poll/add');
  const matchEdit = useRouteMatch('/:company/:project/poll/edit/:code');
  const matchCopy = useRouteMatch('/:company/:project/poll/copy/:code');
  const snackbar = useSnackbar();
  const [poll, setPoll] = useState(defaults);

  const { loading } = useQuery(matchCopy ? getPollGql : getFullPollGql, {
    skip: matchAdd,
    variables: { companyCode, projectCode, code },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getPoll }) => {
      if (matchEdit) return setPoll(getPoll);
      const { code, ...cleanedPoll } = getPoll;
      return setPoll(cleanedPoll);
    },
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [addPoll, { loading: addPollLoading }] = useMutation(addPollGql, {
    onCompleted: ({ createPoll }) => {
      if (!window.sessionStorage.getItem('ctaInputs')) {
        matchCopy
          ? history.replace(match.url.replace(`/copy/${code}`, ''))
          : history.replace(match.url.replace('/add', ''));
      }

      if (window.sessionStorage.getItem('ctaInputs')) {
        const { data, url } = JSON.parse(window.sessionStorage.getItem('ctaInputs'));
        data.params.pollCode = createPoll.code;
        window.sessionStorage.setItem('ctaInputs', JSON.stringify({ data }));
        history.replace(match.url.replace(match.url, url));
      }

      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Umfrage mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim Erstellen der Umfrage');
    },
  });

  const [updatePoll, { loading: updatePollLoading }] = useMutation(updatePollGql, {
    onCompleted: () => {
      history.replace(match.url.replace(`/edit/${code}`, ''));
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Umfrage mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim Speichern der Umfrage');
    },
  });

  const save = (e) => {
    e.preventDefault();

    if (poll.type === 'multipleChoice' && poll?.params?.placeableVotesCount > poll?.params?.pollItems?.length) {
      return snackbar.showMessage(
        'Fehler beim Speichern der Umfrage. Ein Teilnehmer darf nicht mehr stimmen setzen können, als es Antwortmöglichkeiten gibt!',
      );
    }

    if ((poll.type === 'multipleChoice' || poll.type === 'singleChoice') && (!poll.params?.pollItems || poll.params?.pollItems?.length < 2)) {
      return snackbar.showMessage('Fehler beim Speichern der Umfrage. Geben Sie mindestens zwei Antwortmöglichkeiten an!');
    }

    if (!matchEdit) {
      setPoll({ ...poll });

      addPoll({ variables: { companyCode, projectCode, poll: { ...poll, __typename: undefined } } });
      return true;
    }

    const { code, ...noCode } = poll;

    updatePoll({ variables: { companyCode, projectCode, code, poll: noCode } });
    return true;
  };

  const renderPollParams = () => {
    if (!poll.type || poll.type === 'singleChoice') {
      return (
        <SingleChoiceParams
          params={poll.params}
          matchEdit={matchEdit}
          onChange={(params) => setPoll({ ...poll, params })}
        />
      );
    }
    if (poll.type === 'multipleChoice') {
      return (
        <MultipleChoiceParams
          params={poll.params}
          matchEdit={matchEdit}
          onChange={(params) => setPoll({ ...poll, params })}
        />
      );
    }
    if (poll.type === 'wordCloud') {
      return (
        <WordCloudParams
          params={poll.params}
          onChange={(params) => setPoll({ ...poll, params })}
        />
      );
    }

    return null;
  };

  if (loading) return (<p>Loading</p>);

  return (
    <form onSubmit={save}>
      <PageTitle
        title={matchEdit ? 'Umfrage bearbeiten' : 'Neue Umfrage hinzufügen'}
        button={(
          <div>
            {matchEdit && (
            <Button
              variant="contained"
              onClick={() => history.push(`${match.url}/results`)}
            >
              Ergebnisse
            </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ marginLeft: '15px' }}
            >
              {(addPollLoading || updatePollLoading) ? <CircularProgress size={14} /> : (matchEdit ? 'Speichern' : 'Erstellen')}
            </Button>
          </div>
          )}
      />
      <SectionLayout>
        <Grid container item spacing={3} sm={12} md={12} lg={12}>
          <Grid item xs={12} xl={6}>
            <InputFormField
              label="Name"
              name="name"
              value={poll.name}
              inputProps={{
                maxLength: 500,
              }}
              onChange={({ target }) => setPoll({ ...poll, name: target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SelectFormField
              label="Status"
              name="status"
              value={poll.status}
              options={[
                { label: 'Aktiv', value: 'active' },
                { label: 'Inaktiv', value: 'inactive' },
                { label: 'Nur Sichtbar', value: 'visible_only' },
              ]}
              onChange={({ target }) => setPoll({ ...poll, status: target.value })}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SelectFormField
              label="Type"
              name="type"
              value={poll.type}
              options={[
                { label: 'Single Choice', value: 'singleChoice' },
                { label: 'Multiple Choice', value: 'multipleChoice' },
                { label: 'Wortwolke', value: 'wordCloud' },
              ]}
              onChange={({ target }) => setPoll({ ...poll, type: target.value })}
              disabled={!!matchEdit}
            />
          </Grid>
        </Grid>
      </SectionLayout>
      {renderPollParams()}
    </form>
  );
}
