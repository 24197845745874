import {
  Grid,
  Checkbox,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect, useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { ColorPicker } from 'material-ui-color';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 300,
    overflow: 'scroll',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const marks = [
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  },
  {
    value: 150,
    label: '150%',
  },
  {
    value: 200,
    label: '200%',
  },
  {
    value: 300,
    label: '300%',
  },
];

export default function DialogFieldSelection({ open, onClose, onFinish, fields, selectedFields, showSettings, settings }) {
  const classes = useStyles();
  const [checkedRight, setChecked] = useState([]);
  const [checkedLeft, setCheckedLeft] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [tmpSettings, setTmpSettings] = useState({});

  useEffect(() => {
    setTmpSettings(settings);
    setRight(selectedFields);
    setLeft(fields.filter(({ id }) => !selectedFields.find((field) => field.id === id)));
  }, [fields, selectedFields, settings]);

  const handleToggle = (value, side) => () => {
    const checkedList = side === 'left' ? checkedLeft : checkedRight;
    const currentIndex = checkedList.indexOf(value);
    const newChecked = [...checkedList];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (side === 'left') return setCheckedLeft(newChecked);
    return setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    const fieldsToMove = fields.filter(({ id }) => checkedLeft.includes(id));
    const newRightFields = right.concat(fieldsToMove);
    setRight(newRightFields);
    const newLeftFields = fields.filter(({ id }) => !newRightFields.find((field) => field.id === id));
    setLeft(newLeftFields);
    setCheckedLeft([]);
  };

  const handleCheckedLeft = () => {
    const fieldsToMove = fields.filter(({ id }) => checkedRight.includes(id));
    const newLeftFields = left.concat(fieldsToMove);
    setLeft(newLeftFields);
    const newRightFields = fields.filter(({ id }) => !newLeftFields.find((field) => field.id === id));
    setRight(newRightFields);
    setChecked([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };
  const handleUpdateSetting = (key, setting) => {
    const newSettings = { ...tmpSettings };
    newSettings[key] = setting;
    setTmpSettings(newSettings);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onFinish && onFinish(right, tmpSettings);
  };

  const customList = (items, side) => {
    const checkedList = side === 'left' ? checkedLeft : checkedRight;
    return (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map((field) => {
            const labelId = `transfer-list-item-${field.id}-label`;

            return (
              <ListItem
                key={field.id}
                role="listitem"
                button
                onClick={handleToggle(field.id, side)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checkedList.indexOf(field.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={field.label} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>{showSettings ? 'Konfiguration' : 'Felder'}</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
          >
            {showSettings
              && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={!!tmpSettings?.polling || false}
                        onChange={(e) => handleUpdateSetting('polling', e.target.checked)}
                        name="autoReloadCheckbox"
                      />
              )}
                    label="Formulare automatisch nachladen"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    Schriftgröße:
                  </Typography>
                  <Slider
                    value={tmpSettings?.fontScale || 100}
                    step={25}
                    marks={marks}
                    min={25}
                    max={350}
                    valueLabelDisplay="auto"
                    onChange={(_, value) => handleUpdateSetting('fontScale', value)}
                  />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <Typography gutterBottom>Farben Teilnehmer-Einreichungen:</Typography>
                  <Grid container item xs={12} fullWidth>
                    <div className={classes.flex}>
                      <div>
                        <Typography>Hintergrund:</Typography>
                      </div>
                      <ColorPicker
                        value={tmpSettings?.colorPB ? `#${tmpSettings?.colorPB}` : '#007aff'}
                        onChange={(e) => {
                          handleUpdateSetting('colorPB', e.hex);
                        }}
                      />
                    </div>
                    <div className={classes.flex}>
                      <div>
                        <Typography>Schrift:</Typography>
                      </div>
                      <ColorPicker
                        value={tmpSettings?.colorPF ? `#${tmpSettings?.colorPF}` : '#fff'}
                        onChange={(e) => {
                          handleUpdateSetting('colorPF', e.hex);
                        }}
                      />
                    </div>
                  </Grid>
                  <Typography>Farben Moderator-Einreichungen:</Typography>
                  <Grid container item xs={12}>
                    <div className={classes.flex}>
                      <div>
                        <Typography>Hintergrund:</Typography>
                      </div>
                      <ColorPicker
                        value={tmpSettings?.colorMB ? `#${tmpSettings?.colorMB}` : '#f1f0f0'}
                        onChange={(e) => {
                          handleUpdateSetting('colorMB', e.hex);
                        }}
                      />
                    </div>
                    <div className={classes.flex}>
                      <div>
                        <Typography>Schrift:</Typography>
                      </div>
                      <ColorPicker
                        value={tmpSettings?.colorMF ? `#${tmpSettings?.colorMF}` : '#000'}
                        onChange={(e) => {
                          handleUpdateSetting('colorMF', e.hex);
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" component="h6">Felder</Typography>
                </Grid>
              </>
              )}

            <Grid item>{customList(left, 'left')}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={left.length === 0}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={checkedLeft.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={checkedRight.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={right.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList(right)}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={onClose} color="primary">
              Abbrechen
            </Button>
            <Button type="submit" color="primary">
              Speichern
            </Button>
          </div>
        </DialogActions>
      </form>

    </Dialog>
  );
}
