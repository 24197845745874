import { gql } from '@apollo/client';

export const getConfig = gql`
query getConfig($companyCode: String!, $projectCode: String!){
  getConfig(companyCode:$companyCode, projectCode:$projectCode)
}`;

export const setConfig = gql`
mutation setConfig($companyCode: String!, $projectCode: String!, $config: Params!){
  setConfig(companyCode:$companyCode, projectCode:$projectCode, config: $config)
}`;

export const updateProjectMutation = gql`
mutation updateProject($companyCode: String!, $projectCode: String!, $project: UpdateProject!){
  updateProject(companyCode: $companyCode, projectCode: $projectCode, project: $project)
}`;

export const getProjects = gql`
query getProjects{
  getProjects{
    id
    projectName
    projectCode
    parentProjectCode
    allowedTicketCategories
    type
    domain
  }
}`;

export const getMailTemplates = gql`
query getMailTemplates($companyCode:String!, $projectCode:String!){
  getMailTemplates(companyCode:$companyCode, projectCode:$projectCode) {
    resetPassword
    registerCustomer
    customerInvite
    marketing
    addCustomer
  }
}`;

export const getSettings = gql`
  query getSettings(
    $companyCode: String!
    $projectCode: String!
    $settingKeys: [String]!
  ) {
    getSettings(
      companyCode: $companyCode
      projectCode: $projectCode
      settingKeys: $settingKeys
    ) {
      settings
    }
  }
`;

export const setSettings = gql`
  mutation setSettings(
    $companyCode: String!
    $projectCode: String!
    $settings: Params!
  ) {
    setSettings(
      companyCode: $companyCode
      projectCode: $projectCode
      settings: $settings
    )
  }
`;
export const setSettingsQuery = setSettings;

export const setMailTemplates = gql`
mutation setMailTemplates($companyCode:String!, $projectCode:String!,$templates:SetMailTemplates!){
  setMailTemplates(companyCode:$companyCode, projectCode:$projectCode,templates:$templates)
}`;

export const getChatSettings = gql`
query getChatSettings($companyCode:String!, $projectCode:String!){
  getCometChatSettings(companyCode:$companyCode, projectCode:$projectCode) {
    appId,
    apiKey,
    groups {
      cometChatGroupId
      code
      name
      guid
      
    },
    customAttributeSelection
  }
  getTawkToSettings(companyCode: $companyCode, projectCode: $projectCode) {
    enabled
    propertyId
    key
    apiKey
  }
}`;

export const getAvailableCustomerAttributes = gql`
query getAvailableCustomerAttributes($companyCode:String!, $projectCode:String!) {
  getAvailableCustomerAttributes(companyCode: $companyCode, projectCode: $projectCode) {
    availableAttributes {
      type
      default
      code
      label
    }
  }
}`;

export const setCometChatSettings = gql`
mutation setCometChatSettings($companyCode:String!, $projectCode:String!, $settings:SetCometChatSettings!){
  setCometChatSettings(companyCode:$companyCode, projectCode:$projectCode,settings:$settings)
}`;

export const setTawkToSettingsGql = gql`
mutation setTawkToSettings($companyCode:String!, $projectCode:String!,  $tawkToSettings: SetTawkToSettings!){
  setTawkToSettings(companyCode: $companyCode, projectCode: $projectCode, settings: $tawkToSettings)
}`;

export const clearMailTemplateCache = gql`
mutation clearMailTemplateCache($companyCode:String!, $projectCode:String!){
  clearMailTemplateCache(companyCode:$companyCode, projectCode:$projectCode)
}`;
