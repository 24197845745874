import { gql } from '@apollo/client';

export const allPollsGql = gql`
query getAllPolls($companyCode: String!, $projectCode: String!) {
    getAllPolls(companyCode: $companyCode, projectCode: $projectCode) {
      polls {
        id: pollId
        code
        name
        type 
        status
      }
    }
  }`;

export const getPollGql = gql`
query getPoll($companyCode: String!, $projectCode: String!, $code: String!){
    getPoll(companyCode: $companyCode, projectCode: $projectCode, code: $code){
      name
      type 
      params
      status
    }
}`;

export const getFullPollGql = gql`
query getPoll($companyCode: String!, $projectCode: String!, $code: String!){
    getPoll(companyCode: $companyCode, projectCode: $projectCode, code: $code){
      code
      name
      type
      params
      status

    }
}`;

export const getPollForResult = gql`
query getSinglePollForResultView ($companyCode: String!, $projectCode: String!, $code: String){
  getSinglePollForResultView(companyCode: $companyCode, projectCode: $projectCode, code: $code){
      pollId
      code
      name
      type
      params
      status    
  }
}`;

export const uploadPollImageMutationGql = gql`
  mutation uploadPollImage($companyCode: String!, $projectCode: String!, $code: String!, $file: Upload!) {
    uploadPollImage(companyCode: $companyCode, projectCode: $projectCode, code: $code, file: $file) {
      url      
    }
  }
`;
export const deletePollImageMutationGql = gql`
  mutation deletePollImage($companyCode: String!, $projectCode: String!, $code: String!) {
    deletePollImage(companyCode: $companyCode, projectCode: $projectCode, code: $code) 
  }
`;

export const addPollGql = gql`
mutation createPoll($companyCode: String!, $projectCode: String!, $poll: CreatePollInput!){
  createPoll(companyCode: $companyCode, projectCode: $projectCode, poll:$poll) {
    code
  }
}`;

export const updatePollGql = gql`
mutation updatePoll($companyCode: String!, $projectCode: String!, $code: String!, $poll: UpdatePollInput!){
  updatePoll(companyCode: $companyCode, projectCode: $projectCode, code: $code, poll:$poll)
}`;

export const deletePollGql = gql`
mutation deletePoll($companyCode: String!, $projectCode: String!, $code:String!){
  deletePoll(companyCode: $companyCode, projectCode: $projectCode, code:$code)
  }`;

export const resetPollGql = gql`
mutation resetPoll($companyCode: String!, $projectCode: String!, $code:String!){
  resetPoll(companyCode: $companyCode, projectCode: $projectCode, code:$code)
  }`;

export const getConfigGql = gql`
query getConfig($companyCode: String!, $projectCode: String!){
  getConfig(companyCode:$companyCode, projectCode:$projectCode)
}`;
