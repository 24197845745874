import { Button, Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { allCompanyUsersGQL, deleteCompanyUserGQL } from './gql';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import { useAuthState } from '../../context/auth';

export default function Pages() {
  const history = useHistory();
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const params = useParams();
  const { companyCode, projectCode } = params;
  const { user } = useAuthState();

  const { loading, data, error, refetch } = useQuery(allCompanyUsersGQL, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const [deleteCompanyUser] = useMutation(deleteCompanyUserGQL, {
    variables: { companyCode },
    onCompleted: () => refetch() && snackbar.showMessage('Nutzer wurde erfolgreich Entfernt'),
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 405) {
        return snackbar.showMessage('Sie können keinen Nutzer entfernen, der eine höhere Rolle als Sie hat.');
      }
      if (err.graphQLErrors[0]?.extensions.code === 404) return snackbar.showMessage('Nutzer nicht gefunden.');
      return snackbar.showMessage('Fehler beim Entfernen des Nutzers');
    },
  });

  if (loading) return (<p>Loading</p>);
  if (error) return (<p>Fehler beim laden</p>);

  const columns = [
    { field: 'email', headerName: 'E-Mail', type: 'string', flex: 1 },
    { field: 'roleName', headerName: 'Rolle', type: 'string', flex: 1 },
    { field: 'status', headerName: 'Status', type: 'string', flex: 1, valueFormatter: ({ value }) => (value === 'active' ? 'Aktiv' : 'Inaktiv') },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 180,
      renderCell: ({ row }) => {
        if (user.roleCode < 5) {
          return (
            <Tooltip title="Entfernen">
              <IconButton
                onClick={async (e) => {
                  e.preventDefault();
                  await confirm({
                    description: (
                      <span>Wollen Sie den Nutzer mit der Email &quot;
                        <strong>{row.email}</strong>
                        &quot; aus dieser Organisation entfernen?
                        Wenn der Nutzer anderen Organisation zugeteilt ist, kann er diese weiterhin verwalten.
                      </span>
                    ),
                    confirmationText: 'Entfernen',
                    confirmationButtonProps: { color: 'secondary' },
                  });
                  deleteCompanyUser({ variables: { email: row.email } });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          );
        }
        return null;
      },
    },
  ];

  return (
    <>
      <PageTitle
        title="Nutzer der Organisation"
        button={(
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`${match.url}/add`)}
          >
            Erstellen
          </Button>
        )}
      />
      <Paper>
        <DataGrid
          columns={columns}
          rows={data?.getUsers || []}
          getRowId={(row) => row.code}
          componentsProps={{ toolbar: { add: true } }}
          pageSize={100}
          autoHeight
          hideFooterRowCount="false"
          localeText={datagrid}
          onCellClick={(selection) => selection.colIndex !== 3 && history.push(`${match.url}/edit/${selection.row.id}`)}
        />
      </Paper>
    </>
  );
}
