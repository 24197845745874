import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PollIcon from '@material-ui/icons/Poll';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Tooltip from '@material-ui/core/Tooltip';
import { allPollsGql, deletePollGql, resetPollGql } from './gql';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import LoadingTable from '../../components/LoadingTable';

export default function Poll() {
  const history = useHistory();
  const params = useParams();
  const { companyCode, projectCode } = params;
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();

  const { loading, data, error, refetch } = useQuery(allPollsGql, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const [deletePoll] = useMutation(deletePollGql, {
    variables: { companyCode, projectCode },
    onCompleted: () => refetch() && snackbar.showMessage('Umfrage wurde erfolgreich gelöscht'),
    onError: () => refetch() && snackbar.showMessage('Fehler beim Löschen der Umfrage'),
  });

  const [resetPoll] = useMutation(resetPollGql, {
    variables: { companyCode, projectCode },
    onCompleted: () => refetch() && snackbar.showMessage('Umfrage wurde erfolgreich zurückgesetzt'),
    onError: () => refetch() && snackbar.showMessage('Fehler beim zurücksetzen der Umfrage'),
  });

  if (error) return (<p>Fehler beim laden</p>);

  const statusTranslation = {
    active: 'Aktiv',
    inactive: 'Inaktiv',
    visible_only: 'Nur Sichtbar',
  };

  const columns = [
    { field: 'name', headerName: 'Name', type: 'string', flex: 0.5 },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      flex: 0.5,
      valueFormatter: ({ value }) => statusTranslation[value] },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 200,
      valueGetter: (params) => params.getValue('code'),
      renderCell: ({ row }) => (
        <>
          <Tooltip title="Ergebnisse">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                history.push(`${match.url}/edit/${row.code}/results`);
              }}
            >
              <PollIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Duplizieren">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                history.push(`${match.url}/copy/${row.code}`);
              }}
            >
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zurücksetzen">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                await confirm({
                  description: (
                    <span>
                      Wollen Sie wirklich alle abgegeben Stimmen der Umfrage mit dem Namen &quot;<strong>{row.name}</strong>&quot; löschen?
                    </span>
                  ),
                  confirmationText: 'Zurücksetzen',
                  confirmationButtonProps: { color: 'secondary' },
                });
                resetPoll({ variables: { code: row.code } });
              }}
            >
              <RotateLeftIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Löschen">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                await confirm({
                  description: (
                    <span>
                      Wollen Sie die Umfrage mit dem Namen &quot;<strong>{row.name}</strong>&quot; löschen?
                    </span>
                  ),
                  confirmationText: 'Löschen',
                  confirmationButtonProps: { color: 'secondary', autoFocus: true },
                });
                deletePoll({ variables: { code: row.code } });
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Umfragen"
        button={(
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`${match.url}/add`)}
          >
            Erstellen
          </Button>
        )}
      />
      {loading ? <LoadingTable columns={columns.length} /> : (
        <Paper>
          <DataGrid
            columns={columns}
            rows={data?.getAllPolls?.polls || []}
            getRowId={(row) => row.code}
            componentsProps={{ toolbar: { add: true } }}
            pageSize={100}
            autoHeight
            hideFooterRowCount="false"
            localeText={datagrid}
            onCellClick={(selection) => (selection.colIndex !== 2)
            && history.push(`${match.url}/edit/${selection.row.code}`)}
          />
        </Paper>
      )}
    </>
  );
}
