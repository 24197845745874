import { Paper, Grid, Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import IframeResizer from 'iframe-resizer-react';
import { addCTAGQL, getCTAGQL, editCTAGQL, getProjectGQL, allPagesGQL } from '../gql';
import ImageCTAParams from '../components/ImageCTAParams';
import BasicCTAParams from '../components/BasicCTAParams';
import IFrameCTAParams from '../components/IFrameCTAParams';
import PollCTAParams from '../components/PollCTAParams';
import CaptionCTAParams from '../components/CaptionCTAParams';
import JsxCTAParams from '../components/JsxCTAParams';
import EmojiCTAParams from '../components/EmojiCTAParams';
import PageTitle from '../../../components/PageTitle';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';
import SectionLayout from '../../../components/SectionLayout';

const defaults = {
  type: 'basic',
  params: {
    actionType: 'none',
    actionEmitter: 'button',
    buttonLabel: 'Hier klicken',
    openNewTab: true,
    autoOpen: 'false',
    jsx: `<CardContent>
  <Typography gutterBottom align="left" variant="h3" component="h3">
    Überschrift
  </Typography>
  <Typography align="left" variant="body1" component="p">
    beispieltext
  </Typography>
</CardContent>
`,
  },
};

export default function CTAEdit() {
  const history = useHistory();
  const match = useRouteMatch();
  const matchEdit = useRouteMatch('/:companyCode/:projectCode/cta/edit/:code');
  const matchCopy = useRouteMatch('/:companyCode/:projectCode/cta/copy/:code');
  const matchAdd = useRouteMatch('/:companyCode/:projectCode/cta/add');
  const pageParams = useParams();
  const { companyCode, projectCode, code } = pageParams;
  const [cta, setCTA] = useState(defaults);
  const [skip, setSkip] = useState(true);
  const iframeRef = useRef(null);
  const [iFrame, setIFrame] = useState({ ready: false });
  const snackbar = useSnackbar();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { loading } = useQuery(getCTAGQL, {
    fetchPolicy: 'no-cache',
    skip,
    variables: { companyCode, projectCode, code },
    onCompleted: ({ getConversionCard }) => {
      if (matchCopy) {
        setCTA({ ...getConversionCard, code: undefined, sequenceId: undefined });
        return;
      }

      const conversionCard = { ...getConversionCard };
      if (getConversionCard.params?.actionType === 'form' && getConversionCard.params?.autoOpen === undefined) {
        conversionCard.params.autoOpen = false;
      }

      if (getConversionCard.params?.actionType === 'form' && getConversionCard.params?.autoOpen !== undefined) {
        conversionCard.params.autoOpen = getConversionCard.params?.autoOpen.toString();
      }
      console.log('conversionCard', conversionCard);
      setCTA(conversionCard);
    },
  });

  const { data: { getProject: project } = {} } = useQuery(getProjectGQL, {
    fetchPolicy: 'no-cache',
    variables: { companyCode, projectCode },
  });

  const { loadingPages, data: { getCustomPages: customPages } = [] } = useQuery(allPagesGQL, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [addCTA, { loading: addCTALoading }] = useMutation(addCTAGQL, {
    onCompleted: () => {
      matchCopy ? history.replace(match.url.replace(`/copy/${code}`, '')) : history.replace(match.url.replace('/add', ''));
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Karte mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim Erstellen der Karte');
    },
  });

  const [updateCTA, { loading: updateCTALoading }] = useMutation(editCTAGQL, {
    onCompleted: () => {
      history.replace(match.url.replace(`/edit/${code}`, ''));
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Eine Karte mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim Speichern der Karte');
    },
  });

  const getRoute = (newUrl) => {
    if (matchAdd?.isExact) return match.url.replace('/cta/add', newUrl);
    if (matchCopy?.isExact) return match.url.replace(`/cta/copy/${cta.code}`, newUrl);
    if (matchEdit?.isExact) return match.url.replace(`/cta/edit/${cta.code}`, newUrl);

    return null;
  };

  const handleAddItem = (target) => {
    if (target.value !== 'create') {
      return setCTA({ ...cta, params: { ...cta.params, [target.name]: target.value } });
    }

    const ROUTE_MAP = {
      formCode: '/forms/add',
      pollCode: '/poll/add',
    };

    const currentState = {
      data: cta,
      url: match.url,
    };

    window.sessionStorage.setItem('ctaInputs', JSON.stringify(currentState));

    return history.push(getRoute(ROUTE_MAP[target.name]));
  };

  const handleCommunication = (message) => {
    if (message.type === 'pageLoaded') {
      setIFrame({ ...iFrame, ready: true });
      iframeRef.current.sendMessage(cta);
    }

    if (message.type === 'cardSizeChanged') {
      setIFrame({ ...iFrame, height: message.height });
    }
  };

  const renderPreview = useMemo(() => {
    if (project) {
      return (
        <IframeResizer
          forwardRef={iframeRef}
          src={`https://${project?.domain}/preview/cta`}
          onMessage={({ message }) => handleCommunication(message)}
          style={{ border: 'none', width: '100%' }}
          autoResize={false}
        />
      );
    }

    return null;
  }, [project]);

  const cleanUpCta = (cta) => {
    if (!cta?.params) return cta;

    const cleanedCta = {
      code: cta.code,
      type: cta.type,
      name: cta.name,
      pageCode: cta.pageCode === 'default' ? null : cta.pageCode,
      posted: cta.posted,
      sequenceId: cta.sequenceId,
      params: {
        title: cta.params.title,
        imgURL: cta.params.imgURL,
        altText: cta.params.altText,
        content: cta.params.content,
      },
    };

    switch (cta.type) {
      case 'basic':
      case 'hidden':
      case 'jsx':
        if (cta.type === 'jsx') {
          cleanedCta.params = {
            ...cleanedCta.params,
            jsx: cta.params.jsx,
          };
        }

        if (cta.params.actionEmitter === 'none') break;

        cleanedCta.params = {
          ...cleanedCta.params,
          actionType: cta.params.actionType,
          actionEmitter: cta.params.actionEmitter,
          ariaLabel: cta.params.ariaLabel,
          buttonLabel: cta.params.buttonLabel,
        };

        if (cta.params.actionType === 'link') {
          cleanedCta.params.link = cta.params.link;
          cleanedCta.params.openNewTab = cta.params.openNewTab;
        }

        if (cta.params.actionType === 'form') {
          cleanedCta.params.formCode = cta.params.formCode;
          cleanedCta.params.autoOpen = cta.params.autoOpen !== undefined ? cta.params.autoOpen.toString() === 'true' : undefined;
        }

        if (cta.params.actionType === 'linkRotation') {
          cleanedCta.params.linkRotation = cta.params.linkRotation;
        }
        break;
      case 'poll':
        cleanedCta.params.pollCode = cta.params.pollCode;
        break;
      case 'iFrame':
        cleanedCta.params.iFrame = {
          height: cta.params.iFrame?.height,
          src: cta.params.iFrame.src,
        };
        break;
      default:
        break;
    }

    return cleanedCta;
  };

  const save = (e) => {
    e.preventDefault();

    const cleanedCta = cleanUpCta(cta);

    if (!cleanedCta?.params?.imgURL && cleanedCta?.params.actionEmitter === 'image') {
      snackbar.showMessage('Fehler beim Speichern der Karte - Klickbares Element erfordert Bildupload');
      return;
    }

    if (!matchEdit) {
      setCTA({ ...cleanedCta });

      addCTA({
        variables: {
          companyCode,
          projectCode,
          cta: { ...cleanedCta, __typename: undefined, posted: false },
        },
      });

      return;
    }

    updateCTA({
      variables: {
        companyCode,
        projectCode,
        code,
        cta: (({ code, ...obj }) => obj)(cleanedCta),
      },
    });
  };

  useEffect(() => {
    const ctaInputs = window.sessionStorage.getItem('ctaInputs');
    if (ctaInputs) {
      const { data } = JSON.parse(ctaInputs);

      setCTA(data);
      window.sessionStorage.removeItem('ctaInputs');
    }

    if (!ctaInputs && (matchEdit || matchCopy)) {
      setSkip(false);
    }
  }, []);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.sendMessage(cta);
    }
  }, [cta, iFrame.ready]);

  if (loading || loadingPages) return (<p>Loading</p>);

  return (
    <form onSubmit={save}>
      <PageTitle
        title={matchEdit ? 'Karten bearbeiten' : 'Neue Karten hinzufügen'}
        button={(
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            {(addCTALoading || updateCTALoading) ? <CircularProgress size={14} /> : (matchEdit ? 'Speichern' : 'Erstellen')}
          </Button>
          )}
      />
      <Grid container xs={12} spacing={3} direction={mdDown ? 'column-reverse' : 'row'} alignItems="baseline">
        <Grid container item md={12} lg={8}>
          <SectionLayout>
            <Grid container item spacing={3} xs={12}>
              <Typography variant="h5" style={{ marginBottom: 0 }}>Allgemeine Einstellungen</Typography>
              <Grid container item spacing={3}>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <SelectFormField
                    label="Kartentyp"
                    name="type"
                    value={cta?.type || 'basic'}
                    options={[
                      { label: 'Bild + Text', value: 'basic' },
                      { label: 'Umfrage', value: 'poll' },
                      { label: 'Interaktive Emojis', value: 'emoji' },
                      { label: 'Externe Webseite (iFrame)', value: 'iFrame' },
                      { label: 'Dynamisches HTML (JSX)', value: 'jsx' },
                      { label: 'Versteckt', value: 'hidden' },
                    ]}
                    onChange={({ target }) => setCTA({ ...cta, type: target.value })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputFormField
                    label="Name"
                    name="name"
                    value={cta?.name}
                    onChange={({ target }) => {
                      setCTA({ ...cta, name: target.value });
                    }}
                    inputProps={{
                      maxLength: 255,
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={6}>
                  <SelectFormField
                    label="Sichbarkeit auf Seiten"
                    name="customPage"
                    value={cta?.pageCode || 'default'}
                    options={customPages ? [{ label: 'Auf allen Seiten', value: 'default' },
                      ...customPages.map((page) => (
                        { label: page.name, value: page.code }
                      )),
                    ] : [{ label: 'Auf allen Seiten', value: null }]}
                    onChange={({ target }) => setCTA({ ...cta, pageCode: target.value })}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SectionLayout>
          <SectionLayout>
            <Grid container item spacing={3} xs={12}>
              { (cta?.type === 'basic' || cta?.type === 'hidden') && (
                <BasicCTAParams
                  params={cta.params}
                  formAction={handleAddItem}
                  onChange={(params) => setCTA({ ...cta, params: { ...cta.params, ...params } })}
                />
              )}
              { cta?.type === 'iFrame' && (
                <IFrameCTAParams
                  params={cta.params}
                  onChange={(params) => setCTA({ ...cta, params: { ...cta.params, ...params } })}
                />
              )}
              { cta?.type === 'poll' && (
                <PollCTAParams
                  params={cta.params}
                  onChange={handleAddItem}
                />
              )}
              { cta?.type === 'emoji' && (
                <EmojiCTAParams getRoute={getRoute} />
              )}
              { cta?.type === 'jsx' && (
                <>
                  <JsxCTAParams
                    params={cta.params}
                    onChange={(params) => setCTA({ ...cta, params: { ...cta.params, ...params } })}
                  />
                  <BasicCTAParams
                    params={cta.params}
                    formAction={handleAddItem}
                    onChange={(params) => setCTA({ ...cta, params: { ...cta.params, ...params } })}
                  />
                </>
              )}
            </Grid>
          </SectionLayout>
          <SectionLayout>
            <Grid container spacing={3} xs={12}>
              { cta?.type !== 'iFrame' && (
              <ImageCTAParams
                params={cta?.params}
                onChange={(params) => setCTA({ ...cta, params: { ...cta.params, ...params } })}
              />
              )}
            </Grid>
            { cta?.type !== 'jsx' && (
            <Grid container spacing={3} xs={12} style={{ marginTop: '24px' }}>
              <CaptionCTAParams
                params={cta?.params}
                onChange={(params) => setCTA({ ...cta, params: { ...cta.params, ...params } })}
              />
            </Grid>
            )}
          </SectionLayout>
        </Grid>
        <Grid item spacing={5} md={12} lg={4} direction="column" style={{ position: mdDown ? 'static' : 'sticky', top: '4rem' }}>
          <Typography variant="h5" style={{ marginBottom: 10 }}>Vorschau</Typography>
          <Paper elevation={3} style={{ maxWidth: '400px', width: '100%', height: iFrame.height }}>
            {renderPreview}
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
