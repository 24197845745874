import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

export default function TimeframeSelector(props) {
  const { value = {}, onStartChange, onEndChange, onLoggedInCustomerOnlyChange } = props;

  return (
    <>
      <Grid
        item
        container
        justify="flex-start"
        style={{ marginBlock: 15 }}
        direction="row"
        spacing={2}
        xs={12}
        sm={12}
        md={10}
        lg={8}
        xl={6}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              label="Start"
              value={value.startDate}
              maxDate={new Date()}
              onChange={onStartChange}
              format="dd.MM.yyyy HH:mm"
              inputVariant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              label="Ende"
              value={value.endDate}
              minDate={value.startDate}
              onChange={onEndChange}
              format="dd.MM.yyyy HH:mm"
              inputVariant="outlined"
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={value.loggedInCustomerOnly}
                onChange={(event) => onLoggedInCustomerOnlyChange(event.target.checked)}
                name="loggedInCustomerOnly"
                color="primary"
              />
            )}
            style={{ marginTop: 8 }}
            label="Zeige nur eingeloggte Teilnehmer"
          />
        </Grid>
      </Grid>
    </>
  );
}
