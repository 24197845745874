import React, { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PageTitle from '../../../components/PageTitle';
import SectionLayout from '../../../components/SectionLayout';
import InputFormField from '../../../components/forms/InputFormField';
import SelectFormField from '../../../components/forms/SelectFormField';
import { addUserGQL, getCompanyUserGQL, updateUserGQL, allUserRolesGQL } from '../gql';

const defaults = {
  status: 'active',
  roleCode: 2,
};

export default function PageTypesEdit() {
  const history = useHistory();
  const match = useRouteMatch();
  const matchEdit = useRouteMatch('/:companyCode/:projectCode/users/edit/:code');
  const matchAdd = useRouteMatch('/:companyCode/:projectCode/users/add');
  const params = useParams();
  const { companyCode, projectCode, userId } = params;
  const [userDetails, setUserDetails] = useState(defaults);
  const snackbar = useSnackbar();

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const { loading } = useQuery(getCompanyUserGQL, {
    skip: !userId,
    variables: { companyCode, projectCode, userId: parseInt(userId) },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getCompanyUser }) => {
      setUserDetails(getCompanyUser);
    },
  });

  const { data: { getUserRoles: roles = [] } = [] } = useQuery(allUserRolesGQL, {
    fetchPolicy: 'no-cache',
  });

  const [addUser, { loading: addUserLoading }] = useMutation(addUserGQL, {
    onCompleted: () => {
      history.replace(match.url.replace('/add', ''));

      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 400) {
        return snackbar.showMessage('Es existiert bereits ein Nutzer mit der gleichen E-Mail Address in dieser Organisation');
      }
      if (err.graphQLErrors[0]?.extensions.code === 404) return snackbar.showMessage('Rolle nicht gefunden.');
      return snackbar.showMessage('Fehler beim hinzufügen des Nutzers.');
    },
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation(updateUserGQL, {
    onCompleted: () => history.replace(match.url.replace(`/edit/${userId}`, '')),
    onError: () => snackbar.showMessage('Fehler beim Speichern des Nutzers'),
  });

  const saveInput = (e) => {
    e.preventDefault();

    if (matchAdd?.isExact) {
      addUser({
        variables: {
          companyCode,
          userInput: {
            ...userDetails,
          },
        },
      });

      return;
    }

    updateUser({
      variables: {
        companyCode,
        email: userDetails.email,
        userInput: {
          firstname: userDetails.firstname,
          lastname: userDetails.lastname,
          status: userDetails.status,
          roleCode: userDetails.roleCode,
        },
      },
    });
  };

  const buttons = [{
    caption: matchEdit ? 'Speichern' : 'Erstellen',
    color: 'primary',
    type: 'submit',
    name: 'save',
    loading: addUserLoading || updateUserLoading,
  }];

  if (loading) return (<p>Loading</p>);

  return (
    <form onSubmit={saveInput}>
      <PageTitle
        title={matchEdit ? 'Nutzer bearbeiten' : 'Nutzer zur Organisation hinzufügen'}
        buttons={buttons}
      />

      <SectionLayout>

        <>
          <Typography variant="h5" style={{ marginBottom: 10 }}>Nutzer-Details</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputFormField
                label="E-Mail"
                name="email"
                value={userDetails?.email}
                onChange={({ target }) => setUserDetails({ ...userDetails, email: target.value })}
                required
                disabled={matchEdit}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputFormField
                label="Vorname"
                name="firstname"
                value={userDetails?.firstname}
                onChange={({ target }) => setUserDetails({ ...userDetails, firstname: target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputFormField
                label="Nachname"
                name="lastname"
                value={userDetails?.lastname}
                onChange={({ target }) => setUserDetails({ ...userDetails, lastname: target.value })}
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <SelectFormField
                label="Status"
                name="status"
                onChange={({ target }) => setUserDetails({ ...userDetails, status: target.value })}
                value={userDetails.status}
                options={[{ label: 'Aktiv', value: 'active' }, { label: 'Inaktiv', value: 'inactive' }]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectFormField
                label="Rolle"
                name="roleCode"
                value={userDetails.roleCode}
                options={[...roles.map((role) => (
                  { label: role.roleName, value: role.roleCode }
                )),
                ]}
                onChange={({ target }) => setUserDetails({ ...userDetails, roleCode: target.value })}
              />
            </Grid>

          </Grid>
        </>
      </SectionLayout>
    </form>
  );
}
