import { gql } from '@apollo/client';

export const allPagesGQL = gql`
query getAllPages($companyCode: String!, $projectCode: String!) {
  getAllPages(companyCode: $companyCode, projectCode: $projectCode){
    pages {
      coverImg
      code
      name
      sequenceId
      status
    }
  }
}`;

export const getPageGQL = gql`
query getPage($companyCode:String!, $projectCode: String!, $pageCode: String!){
  getPage(companyCode: $companyCode, projectCode: $projectCode, pageCode: $pageCode){
    code
    name
    navTitle
    coverImg
    sequenceId
    status
    content {
      titleImg
      content
      buttons {
        link
        label
      }
      bottomGraphics {
        gridAttr
        img
        link
      }
    }
  }
}`;

export const addPageGQL = gql`
mutation addPage($companyCode: String!, $projectCode: String!, $page: AddPage!){
  addPage(companyCode: $companyCode, projectCode: $projectCode, page: $page)
}`;

export const editPageGQL = gql`
mutation updatePage($companyCode: String!, $projectCode: String!, $pageCode: String!, $page: UpdatePage!){
  updatePage(companyCode: $companyCode, projectCode: $projectCode, pageCode: $pageCode, page: $page)
}`;

export const deletePageGQL = gql`
mutation deletePage($companyCode: String!, $projectCode: String!, $pageCode: String!){
  deletePage(companyCode: $companyCode, projectCode: $projectCode, pageCode: $pageCode)
}`;
