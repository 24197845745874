import jwtDecode from 'jwt-decode';
import React, { createContext, useContext, useReducer } from 'react';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

let user = null;

const token = localStorage.getItem('token');
if (token) {
  const decodedToken = jwtDecode(token);
  const expiresAt = new Date(decodedToken.exp * 1000);

  if (new Date() > expiresAt) {
    localStorage.removeItem('token');
  } else {
    user = decodedToken;
  }
} else console.log('No token found');

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        user: action.payload,
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      window.location.href = '/';

      return {
        ...state,
        user: null,
      };
    case 'SWITCH_CONTEXT':
      localStorage.setItem('token', action.payload.token);
      return {
        ...state,
        user: action.payload,

      };
    case 'SET_COMPANY':

      return {
        ...state,
        company: action.payload,
      };

    case 'SET_PROJECT':
      return {
        ...state,
        project: action.payload,
      };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, { user });

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
}

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
