import { Grid,
  Button,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { ColorPicker } from 'material-ui-color';
import InputFormField from '../../../components/forms/InputFormField';

export default function DialogMultipleChoiceSettings({ open, onClose, onFinish, settings }) {
  const useStyles = makeStyles(() => ({
    root: {
      margin: 'auto',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  }));
  const classes = useStyles();
  const [tmpSettings, setTmpSettings] = useState({});

  useEffect(() => {
    setTmpSettings(settings);
  }, [settings]);

  const handleUpdateSetting = (key, setting) => {
    const newSettings = { ...tmpSettings };
    newSettings[key] = setting;
    setTmpSettings(newSettings);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onFinish && onFinish(tmpSettings);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>Konfiguration</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
          >
            <Grid item xs={12}>
              <InputFormField
                label="Aktualisierungs Intervall"
                name="pollIntervall"
                value={(tmpSettings.pollIntervall)}
                InputProps={{ inputProps: { min: 1000 } }}
                onChange={({ target }) => handleUpdateSetting('pollIntervall', target.value)}
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputFormField
                label="Box Schatten"
                name="elevation"
                value={tmpSettings.elevation || 0}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
                onChange={({ target }) => handleUpdateSetting('elevation', target.value)}
                type="number"
              />
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <FormControl component="fieldset" variant="standard" style={{ marginBottom: 10 }}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={tmpSettings?.hideFAB === 'true'}
                      onChange={({ target }) => handleUpdateSetting('hideFAB', target.checked.toString())}
                      color="primary"
                    />
              )}
                  label="FAB ausblenden"
                />
              </FormControl>
            </Grid>
            <Grid container item spacing={2} xs={12}>
              <Typography variant="h6">Farben</Typography>
              <Grid container item xs={12}>
                <div className={classes.flex}>
                  <div>
                    <Typography>Hintergrund:</Typography>
                  </div>
                  <ColorPicker
                    value={tmpSettings?.backgroundColor ? tmpSettings?.backgroundColor : '#fff'}
                    onChange={(e) => {
                      handleUpdateSetting('backgroundColor', `#${e.hex}`);
                    }}
                  />
                </div>
                <div className={classes.flex}>
                  <div>
                    <Typography>Schrift &amp; Rahmen:</Typography>
                  </div>
                  <ColorPicker
                    value={tmpSettings?.fontColor ? tmpSettings?.fontColor : '#fff'}
                    onChange={(e) => {
                      handleUpdateSetting('fontColor', `#${e.hex}`);
                    }}
                  />
                </div>
                <div className={classes.flex}>
                  <div>
                    <Typography>Balken:</Typography>
                  </div>
                  <ColorPicker
                    value={tmpSettings?.fillColor ? tmpSettings?.fillColor : '#fff'}
                    onChange={(e) => {
                      handleUpdateSetting('fillColor', `#${e.hex}`);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={onClose} color="primary">
              Abbrechen
            </Button>
            <Button type="submit" color="primary">
              Speichern
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
