import { Grid,
  makeStyles,
  Button,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Checkbox,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ColorPicker } from 'material-ui-color';
import InputFormField from '../../../components/forms/InputFormField';
import SelectFormField from '../../../components/forms/SelectFormField';

export default function DialogWordCloudSettings({ open, onClose, onFinish, settings }) {
  const useStyles = makeStyles(() => ({
    root: {
      margin: 'auto',
    },
  }));
  const classes = useStyles();
  const [tmpSettings, setTmpSettings] = useState({});

  useEffect(() => {
    setTmpSettings(settings);
  }, [settings]);

  const handleUpdateSetting = (key, setting) => {
    const newSettings = { ...tmpSettings };
    newSettings[key] = setting;
    setTmpSettings(newSettings);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onFinish && onFinish(tmpSettings);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>Konfiguration</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
          >
            <Grid item xs={12}>
              <InputFormField
                label="Aktualisierungs Intervall"
                name="pollIntervall"
                value={(tmpSettings.pollIntervall)}
                InputProps={{ inputProps: { min: 1000 } }}
                onChange={({ target }) => handleUpdateSetting('pollIntervall', target.value)}
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFormField
                label="Skala"
                name="scale"
                value={tmpSettings.scale || 'log'}
                options={[
                  { label: 'Logaritmisch', value: 'log' },
                  { label: 'Wurzel', value: 'sqrt' },
                  { label: 'Linear', value: 'linear' },
                ]}
                onChange={({ target }) => handleUpdateSetting('scale', target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputFormField
                label="Abstand zwischen den Wörtern"
                name="padding"
                value={tmpSettings.padding || 4}
                InputProps={{ inputProps: { min: 0 } }}
                onChange={({ target }) => handleUpdateSetting('padding', target.value)}
                type="number"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <InputFormField
                label="Box Schatten"
                name="elevation"
                value={tmpSettings.elevation || 0}
                InputProps={{ inputProps: { min: 0, max: 10 } }}
                onChange={({ target }) => handleUpdateSetting('elevation', target.value)}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <InputFormField
                label="Minimale höhe"
                name="minHeight"
                value={tmpSettings.minHeight || 400}
                InputProps={{ inputProps: { min: 200 } }}
                onChange={({ target }) => handleUpdateSetting('minHeight', target.value)}
                type="number"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormField
                label="Schriftgröße Min."
                name="minFontSize"
                value={tmpSettings.minFontSize || 15}
                InputProps={{ inputProps: { min: 5, max: 200 } }}
                onChange={({ target }) => handleUpdateSetting('minFontSize', target.value)}
                type="number"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormField
                label="Schriftgröße Max."
                name="maxFontSize"
                value={tmpSettings.maxFontSize || 60}
                InputProps={{ inputProps: { min: 10, max: 400 } }}
                onChange={({ target }) => handleUpdateSetting('maxFontSize', target.value)}
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFormField
                label="Fontfamily"
                name="fontFamily"
                value={tmpSettings.fontFamily || 'Roboto'}
                options={[
                  { label: 'Roboto', value: 'Roboto' },
                  { label: 'Open Sans', value: 'Open+Sans' },
                  { label: 'Lora', value: 'Lora' },
                ]}
                onChange={({ target }) => handleUpdateSetting('fontFamily', target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFormField
                label="Font weight"
                name="fontWeight"
                value={tmpSettings.fontWeight || 'normal'}
                options={[
                  { label: 'Normal', value: 'normal' },
                  { label: 'Bold', value: 'bold' },
                ]}
                onChange={({ target }) => handleUpdateSetting('fontWeight', target.value)}
              />
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <FormControl component="fieldset" variant="standard" style={{ marginBottom: 10 }}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={tmpSettings?.hideFAB === 'true'}
                      onChange={({ target }) => handleUpdateSetting('hideFAB', target.checked.toString())}
                      color="primary"
                    />
              )}
                  label="FAB ausblenden"
                />
              </FormControl>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Typography variant="h6">Hintergrundfarbe</Typography>
              <Grid container item xs={12}>
                <ColorPicker
                  value={tmpSettings?.backgroundColor ? tmpSettings?.backgroundColor : '#fff'}
                  onChange={(e) => {
                    handleUpdateSetting('backgroundColor', `#${e.hex}`);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={onClose} color="primary">
              Abbrechen
            </Button>
            <Button type="submit" color="primary">
              Speichern
            </Button>
          </div>
        </DialogActions>
      </form>

    </Dialog>
  );
}
