import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { allEmojisGql, deleteEmojiGql } from './gql';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import LoadingTable from '../../components/LoadingTable';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
  },
}));

export default function Emojis() {
  const history = useHistory();
  const params = useParams();
  const { companyCode, projectCode } = params;
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const classes = useStyles();

  const { loading, data, error, refetch } = useQuery(allEmojisGql, {
    fetchPolicy: 'no-cache',
    variables: { companyCode, projectCode },
  });

  const [deleteEmoji] = useMutation(deleteEmojiGql, {
    variables: { companyCode, projectCode },
    onCompleted: () => refetch() && snackbar.showMessage('Emoji wurde erfolgreich gelöscht'),
    onError: () => {
      refetch();
      return snackbar.showMessage('Fehler beim Löschen des Emojis.');
    },
  });

  if (error) return (<p>Fehler beim laden</p>);

  const statusTranslation = {
    active: 'Aktiv',
    inactive: 'Inaktiv',
  };

  const columns = [
    {
      field: 'image',
      headerName: ' ',
      width: 70,
      renderCell: ({ value }) => (<img src={value} alt="" className={classes.image} />),
    },
    { field: 'name', headerName: 'Name', type: 'string', flex: 0.4 },
    { field: 'status', headerName: 'Status', type: 'string', flex: 0.3, valueFormatter: ({ value }) => statusTranslation[value] },
    { field: 'sequenceId', headerName: 'Reihenfolge', type: 'number', flex: 0.2 },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 120,
      valueGetter: (params) => params.getValue('code'),
      renderCell: ({ row }) => (
        <Tooltip title="Löschen">
          <IconButton
            onClick={async (e) => {
              e.preventDefault();
              await confirm({
                description: (
                  <span>
                    Wollen Sie das Emoji mit dem Namen &quot;<strong>{row.name}</strong>&quot; wirklich löschen?
                  </span>),
                confirmationText: 'Löschen',
                confirmationButtonProps: { color: 'secondary' },
              });
              deleteEmoji({ variables: { code: row.code } });
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Emojis"
        button={(
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push(`${match.url}/add`)}
            >
              Erstellen
            </Button>
            <Button
              style={{ marginLeft: '15px' }}
              variant="contained"
              onClick={() => history.push(`${match.url}/results`)}
            >
              Ergebnisse
            </Button>
          </div>
        )}
      />
      {loading ? <LoadingTable columns={columns.length} /> : (
        <Paper>
          <DataGrid
            columns={columns}
            rows={data?.getAllEmojis || []}
            getRowId={(row) => row.code}
            componentsProps={{ toolbar: { add: true } }}
            pageSize={100}
            autoHeight
            hideFooterRowCount="false"
            localeText={datagrid}
            onCellClick={(selection) => (selection.colIndex !== 4)
            && history.push(`${match.url}/edit/${selection.row.code}`)}
          />
        </Paper>
      )}
    </>
  );
}
