import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Breadcrumb from '../../components/BreadCrumb';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({

  surface: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
  },
  bread: {
    marginBottom: theme.spacing(2),
  },

}));

export default function Surface(props) {
  const classes = useStyles();
  return (
    <div className={classes.surface}>
      <div className={classes.bread}>
        <Breadcrumb />
      </div>
      {props.children}

    </div>
  );
}
