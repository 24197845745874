import { Button, Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { allPageTypesGQL, deletePageTypeGQL } from './gql';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';

export default function Pages() {
  const history = useHistory();
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const params = useParams();
  const { companyCode, projectCode } = params;

  const { loading, data, error, refetch } = useQuery(allPageTypesGQL, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const [deletePageType] = useMutation(deletePageTypeGQL, {
    variables: { companyCode, projectCode },
    onCompleted: () => refetch() && snackbar.showMessage('Seite wurde erfolgreich gelöscht'),
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) {
        return snackbar.showMessage('Fehler beim Löschen der Seite - Mindestens eine Seite ist mit dem Seitentyp verknüpft.');
      }
      return snackbar.showMessage('Fehler beim Löschen der Seite');
    },
  });

  if (loading) return (<p>Loading</p>);
  if (error) return (<p>Fehler beim laden</p>);

  const columns = [
    { field: 'name', headerName: 'Name', type: 'string', flex: 1 },
    { field: 'status', headerName: 'Status', type: 'string', flex: 1, valueFormatter: ({ value }) => (value === 'active' ? 'Aktiv' : 'Inaktiv') },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 120,
      renderCell: ({ row }) => (
        <>
          <Tooltip title="Duplizieren">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                history.push(`${match.url}/copy/${row.id}`);
              }}
            >
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Löschen">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                await confirm({
                  description: (
                    <span>Wollen Sie die Seitenkategorie mit dem Namen &quot;
                      <strong>{row.name}</strong>
                      &quot; wirklich löschen?
                    </span>
                  ),
                  confirmationText: 'Löschen',
                  confirmationButtonProps: { color: 'secondary' },
                });
                deletePageType({ variables: { code: row.id } });
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Seitenkategorien"
        button={(
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`${match.url}/add`)}
          >
            Erstellen
          </Button>
        )}
      />
      <Paper>
        <DataGrid
          columns={columns}
          rows={data?.getPageTypes || []}
          getRowId={(row) => row.code}
          componentsProps={{ toolbar: { add: true } }}
          pageSize={100}
          autoHeight
          hideFooterRowCount="false"
          localeText={datagrid}
          onCellClick={(selection) => selection.colIndex !== 2 && history.push(`${match.url}/edit/${selection.row.id}`)}
        />
      </Paper>
    </>
  );
}
