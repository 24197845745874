import { Grid, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import InputFormField from '../../../components/forms/InputFormField';
import SelectFormField from '../../../components/forms/SelectFormField';
import { allFormsGql } from '../gql';

export default function ActionButtons(props) {
  const [actions, setActions] = useState(props.actions || []);
  const [forms, setForms] = useState([]);

  useQuery(allFormsGql, {
    variables: { companyCode: props.params.company, projectCode: props.params.project },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getAllForms }) => {
      const formCodes = getAllForms.forms.map(({ title, formCode }) => ({ label: title, value: formCode }));
      setForms(formCodes);
    },
  });

  const onAdd = () => {
    const newState = [...actions];
    newState.push({ type: 'url', label: '', url: '', openInNewTab: true, formCode: '' });
    setActions(newState);
    props.onChange && props.onChange(newState);
  };
  const onDelete = (index) => {
    const newState = [...actions];
    newState.splice(index, 1);
    setActions(newState);
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = [...actions];
    newState[index] = { ...newState[index], [field]: value };
    setActions(newState);
    props.onChange && props.onChange(newState);
  };

  useEffect(() => {
    setActions(props.actions || []);
  }, [props.actions]);

  return (
    <>
      <Typography variant="h6" style={{ marginTop: 30, marginBottom: 10 }}>
        Aktionen unter Stream
        <Button
          variant="contained"
          style={{ borderRadius: 50, minWidth: 0, marginLeft: 5, padding: '0px 8px' }}
          color="primary"
          onClick={onAdd}
        >
          +
        </Button>
      </Typography>
      {actions && actions.map((button, index) => (
        <Grid container spacing={3} key={index} style={{ marginBottom: '30px' }}>
          <Grid item xs={0.5}>
            <Typography style={{ marginTop: '15px' }}>
              {index + 1}
              .
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SelectFormField
                  label="Type"
                  name="type"
                  value={button.type || '3q'}
                  options={[
                    { label: 'Formular', value: 'form' },
                    { label: 'Austausch (Zoom)', value: 'zoomExchange' },
                    { label: 'Link', value: 'link' },
                    { label: 'tawk.to öffnen', value: 'openTawkTo' },
                  ]}
                  onChange={({ target }) => onChange(index, 'type', target.value)}
                />
              </Grid>
              {button.type === 'form'
                  && (
                    <Grid item xs={6}>
                      <SelectFormField
                        label="Formular Titel"
                        name="formCode"
                        value={button.formCode || ''}
                        options={forms}
                        onChange={({ target }) => onChange(index, 'formCode', target.value)}
                      />
                    </Grid>
                  )}
              <Grid item xs={6}>
                <InputFormField
                  label="Label"
                  name="label"
                  value={button.label}
                  onChange={({ target }) => onChange(index, 'label', target.value)}
                  required
                />
              </Grid>
              {(button.type === 'link')
                ? (
                  <>
                    <Grid item xs={6}>
                      <InputFormField
                        label="URL"
                        name="url"
                        value={button.url}
                        onChange={({ target }) => onChange(index, 'url', encodeURI(target.value))}
                        required
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectFormField
                        label="Im neuen Tab öffnen"
                        name="openInNewTab"
                        value={button.openInNewTab === undefined ? true : button.openInNewTab}
                        options={[
                          { label: 'Ja', value: true },
                          { label: 'Nein', value: false },
                        ]}
                        onChange={({ target }) => onChange(index, 'openInNewTab', target.value)}
                      />
                    </Grid>
                  </>
                ) : null}
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" style={{ marginTop: '8px' }} color="secondary" onClick={() => onDelete(index)}>Löschen</Button>
          </Grid>
        </Grid>
      ))}
    </>
  );
}
