/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';
import { emojiGql, addEmojiGql, updateEmojiGql } from '../gql';
import PageTitle from '../../../components/PageTitle';
import SectionLayout from '../../../components/SectionLayout';

const useStyles = makeStyles((theme) => ({
  optionsButtonGroup: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      marginBlockStart: theme.spacing(1),
    },
  },
  image: {
    width: '70px',
  },
}));

const defaults = {
  sequenceId: 0,
  status: 'active',
};

export default function EmojiEdit() {
  const params = useParams();
  const { companyCode, projectCode, code } = params;
  const history = useHistory();
  const classes = useStyles();
  const snackbar = useSnackbar();
  const match = useRouteMatch();
  const matchAdd = useRouteMatch('/:companyCode/:projectCode/emojis/add');
  const [emoji, setEmoji] = useState(defaults);

  const { loading } = useQuery(emojiGql, {
    fetchPolicy: 'no-cache',
    skip: matchAdd,
    variables: { companyCode, projectCode, code },
    onCompleted: ({ getEmoji }) => {
      setEmoji(getEmoji);
    },
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [addEmoji, { loading: addEmojiLoading }] = useMutation(addEmojiGql, {
    onCompleted: () => {
      history.replace(match.url.replace('/add', ''));
      showSuccessMessage();
    },
    onError: (err) => {
      if (err.graphQLErrors[0]?.extensions.code === 409) return snackbar.showMessage('Ein Emoji mit dem selben Code existiert bereits');
      return snackbar.showMessage('Fehler beim Erstellen des Emoji');
    },
  });

  const [editEmoji, { loading: editEmojiLoading }] = useMutation(updateEmojiGql, {
    onCompleted: () => {
      history.replace(match.url.replace(`/edit/${emoji.code}`, ''));
      showSuccessMessage();
    },
    onError: () => snackbar.showMessage('Fehler beim Speichern des Emoji'),
  });

  const save = (e) => {
    e.preventDefault();

    if (matchAdd) {
      setEmoji({ ...emoji });

      addEmoji({ variables: { companyCode, projectCode, emoji: { ...emoji, __typename: undefined } } });
      return true;
    }

    const { code, ...noCode } = emoji;

    editEmoji({ variables: { companyCode, projectCode, code, emoji: noCode } });
    return true;
  };

  if (loading) return (<p>Loading</p>);

  return (
    <form onSubmit={save}>
      <PageTitle
        title={matchAdd ? 'Neues Emoji hinzufügen' : 'Emoji bearbeiten'}
        button={(
          <div className={classes.optionsButtonGroup}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ marginLeft: '15px' }}
            >
              { (addEmojiLoading || editEmojiLoading) ? <CircularProgress size={14} /> : (matchAdd ? 'Erstellen' : 'Speichern') }
            </Button>
          </div>
          )}
      />
      <SectionLayout>
        <Grid container item spacing={3} sm={12} md={12} lg={12}>
          <Grid item xs={12} xl={6}>
            <InputFormField
              label="Name"
              name="Name"
              value={emoji.name}
              onChange={({ target }) => setEmoji({ ...emoji, name: target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <InputFormField
              label="Reihenfolge"
              name="sequenceId"
              type="number"
              value={emoji.sequenceId}
              onChange={({ target }) => setEmoji({ ...emoji, sequenceId: parseInt(target.value) })}
              required
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <SelectFormField
              label="Status"
              name="status"
              value={emoji.status}
              options={[
                { label: 'Aktiv', value: 'active' },
                { label: 'Inaktiv', value: 'inactive' },
              ]}
              onChange={({ target }) => setEmoji({ ...emoji, status: target.value })}
            />
          </Grid>
        </Grid>
        <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>Emoji Icon</Typography>
        <Grid container item spacing={3} sm={12} md={12} lg={12}>
          <Grid item xs={12} xl={6}>
            {emoji?.image
                && (
                <img
                  src={typeof emoji?.image === 'object' && emoji?.image?.name
                    ? URL.createObjectURL(emoji?.image)
                    : emoji?.image}
                  alt=""
                  className={classes.image}
                />
                )}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Emoji-Icon"
              type="file"
              fullWidth
              required={!(emoji.image)}
              inputProps={{ accept: '.png,.jpg,.jpeg,.svg' }}
              onChange={(e) => {
                setEmoji({ ...emoji, image: e.target.files[0] });
              }}
            />
          </Grid>
        </Grid>
      </SectionLayout>
    </form>
  );
}
