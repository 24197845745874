import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { Skeleton } from '@material-ui/lab';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';
import PagesElements from './PageElements';
import AccessRestriction from './AccessRestriction';
import AccessTimeRestriction from './AccessTimeRestriction';
import { editorNewCustomPagesGQL, editorExistingCustomPagesGQL } from '../gql';
import SectionLayout from '../../../components/SectionLayout';

export default function PageDetails({ currentPageRevision, onChange }) {
  const params = useParams();
  const { companyCode, projectCode } = params;

  const {
    loading,
    data,
  } = useQuery(currentPageRevision?.pageType?.code ? editorExistingCustomPagesGQL : editorNewCustomPagesGQL, {
    variables: { companyCode, projectCode, code: currentPageRevision?.pageType?.code },
    fetchPolicy: 'no-cache',
  });

  const optionsPageTypes = () => data?.getPageTypes
    .filter((type) => type.status === 'active' || type.code === currentPageRevision?.pageType?.code)
    .map((type) => ({ label: type.name, value: type.code })) || [];

  return (
    <>
      <SectionLayout>
        <Typography variant="h5" style={{ marginBottom: 10 }}>Seiten Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            { loading ? <Skeleton height={59} /> : (
              <InputFormField
                label="Titel"
                name="title"
                required
                value={currentPageRevision.title}
                onChange={({ target }) => onChange({ ...currentPageRevision, title: target.value })}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            { loading ? <Skeleton height={59} /> : (
              <SelectFormField
                label="Seitentyp"
                name="pageType"
                required
                value={currentPageRevision?.pageType?.code || ''}
                options={optionsPageTypes()}
                onChange={({ target }) => onChange({ ...currentPageRevision, pageType: { code: target.value } })}
              />
            )}
          </Grid>
        </Grid>
      </SectionLayout>
      <SectionLayout>
        <Grid container spacing={3}>
          <AccessTimeRestriction
            onChange={onChange}
            currentPageRevision={currentPageRevision}
            accessTimeRestrictionData={data?.getPageTypeDetail}
            loading={loading}
          />
        </Grid>
      </SectionLayout>
      <SectionLayout>
        <Grid container spacing={3}>
          <AccessRestriction
            onChange={onChange}
            currentPageRevision={currentPageRevision}
            accessRestrictionData={data}
            loading={loading}
          />
        </Grid>
      </SectionLayout>
      <SectionLayout>
        <Grid item container xs={12}>
          <PagesElements
            elements={currentPageRevision.elements}
            onChange={(elements) => onChange({ ...currentPageRevision, elements })}
          />
        </Grid>
      </SectionLayout>
    </>
  );
}
