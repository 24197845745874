/* eslint-disable react/no-danger */
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { useQuery, gql } from '@apollo/client';

const allEmojisGql = gql`
query getAllEmojis($companyCode: String!, $projectCode: String!) {
  getAllEmojis(companyCode: $companyCode, projectCode: $projectCode) {
    code
    name
    image
    sequenceId
    status
  }
}`;

const getEmojiClicksGql = gql`
query getEmojiClicks($companyCode: String!, $projectCode: String!) {
  getEmojiClicks(companyCode: $companyCode, projectCode: $projectCode)
}`;

const useStyles = makeStyles(() => ({
  image: {
    width: '100px',
    animation: '$myship 10s',
    position: 'absolute',
    top: '150%',
  },
  box: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: '#000',
  },
  '@keyframes myship': {
    from: {
      top: '150%',
    },
    to: {
      top: '-100%',
    },
  },
}));

export default function PollResult() {
  const params = useParams();
  const classes = useStyles();
  const [emojisByCode, setEmohisByCode] = useState({});
  const query = new URLSearchParams(useLocation().search);

  const cut = 4 || query.get('clickCut');
  const smilieWidth = 5 || query.get('smilieWidth');
  const pollInterval = query.get('pollInterval') || 800;
  const backgroundColor = query.get('backgroundColor') || 'black';

  useQuery(allEmojisGql, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: ({ getAllEmojis }) => {
      setEmohisByCode(getAllEmojis.reduce((acc, emoji) => {
        acc[emoji.code] = emoji;
        return acc;
      }, {}));
    },
  });

  const generateEmoji = (emoji, clickCount) => {
    if (!emoji) return;
    let currentClickCount = clickCount;
    if (clickCount > cut) currentClickCount = cut;

    while (currentClickCount > 0) {
      const box = document.getElementById('box');
      const emojiElement = document.createElement('img');
      emojiElement.src = emoji.image;
      emojiElement.className = 'reactions smilie-up';

      const position = Math.floor(Math.random() * 90) + 1;
      const width = Math.floor(Math.random() * 1) + smilieWidth;

      emojiElement.style = `margin-left: ${position}%; width: ${width}%`;
      emojiElement.addEventListener('animationend', () => {
        emojiElement.remove();
      });

      box.appendChild(emojiElement);
      currentClickCount -= 1;
    }
  };

  useQuery(getEmojiClicksGql, {
    variables: { companyCode: params.company, projectCode: params.project },
    pollInterval,
    fetchPolicy: 'no-cache',
    onCompleted: ({ getEmojiClicks }) => {
      for (const { code, clicks } of getEmojiClicks) {
        generateEmoji(emojisByCode[code], clicks);
      }
    },
  });

  return (
    <>
      <div className={`${classes.box} box`} id="box" style={{ backgroundColor }} />
      <style dangerouslySetInnerHTML={{ __html: `
        .smilie-up {
          animation:myship 10s;
        }
        .box {
          width: 100%;
          height: 100%;
          position: absolute;
          overflow: hidden
        }

        .reactions{
          position: absolute;
          top: 150%;
        }

        @keyframes myship {
          from {top: 110%;} 
          to { top: -100%; } 
        }`,
      }}
      />
    </>
  );
}
