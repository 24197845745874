import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export default function LoadingTable({ columns }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {[...Array(columns)].map((_, i) => (
              <TableCell key={i} style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Skeleton style={{ marginLeft: 12, marginRight: 12 }} height="56px" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(8)].map((_, i) => (
            <TableRow key={i} style={{ paddingBottom: 0, paddingTop: 0 }}>
              {[...Array(columns)].map((_, i) => (
                <TableCell key={i} style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Skeleton style={{ marginLeft: 12, marginRight: 12 }} height="52px" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
