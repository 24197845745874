import { Button, Paper } from '@material-ui/core'
import React from 'react'
import PageTitle from '../../components/PageTitle';
import { useParams } from 'react-router';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { useQuery, useMutation } from '@apollo/client'
import AceEditor from "react-ace";

//import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-textmate";
import { getConfig, setConfig } from './gql';



export default function Config(props) {
  const params = useParams();
  const snackbar = useSnackbar();
  const [json, setJson] = React.useState(false);
  const [updateStream] = useMutation(setConfig, {
    onCompleted: () => { snackbar.showMessage('Erfolgreich gespeichert') }
  })

  const { loading, error } = useQuery(getConfig, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: ({ getConfig }) => setJson(JSON.stringify(getConfig, null, 2))
  })

  
  if (loading) return (<p>Loading</p>)
  if (error) return (<p>Fehler beim laden</p>)

  const onSave = (value) => {
    let config
    try {
      config = JSON.parse(json)
    } catch (err) {
      console.error(err)
      snackbar.showMessage('Fehler im JSON')
      return
    }
    updateStream({
      variables: {
        companyCode: params.company, projectCode: params.project, config
      }
    })
  }

  return (
    <>
      <PageTitle
        title="Konfiguration"
        button={
          <Button
            color="primary"
            variant="contained"
            onClick={onSave}
            >
              Speichern</Button>
        }
      />
      <Paper>
      <AceEditor
        mode="json"
        theme="textmate"
        onChange={(value) => setJson(value)}
        onValidate={() => console.log('onValidate')}
        editorProps={{ $blockScrolling: false }}
        width="100%"
        value={json}
        enableBasicAutocompletion
        tabSize={2}
      />
      </Paper>
    </>
  )
}