import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Paper } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PollIcon from '@material-ui/icons/Poll';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Tooltip from '@material-ui/core/Tooltip';
import { allFormsGql, deleteFormGql, deleteFormSubmissionsGql } from './gql';
import PageTitle from '../../components/PageTitle';
import { datagrid } from '../../util/language';
import LoadingTable from '../../components/LoadingTable';

export default function QandAPools() {
  const history = useHistory();
  const params = useParams();
  const { companyCode, projectCode } = params;
  const match = useRouteMatch();
  const confirm = useConfirm();
  const snackbar = useSnackbar();

  const { loading, data, error, refetch } = useQuery(allFormsGql, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const [deleteForm] = useMutation(deleteFormGql, {
    variables: { companyCode, projectCode },
    onCompleted: () => refetch() && snackbar.showMessage('Formular wurde erfolgreich gelöscht'),
    onError: (err) => {
      refetch();
      if (err.graphQLErrors[0]?.extensions.code === 409) {
        return snackbar.showMessage(`Formular kann nicht gelöscht werden: Es besetehen bereits entweder Einreichungen 
          oder das Formular ist unter einem Stream oder in einer Conversion Card verlinkt.`);
      }
      return snackbar.showMessage('Fehler beim Löschen des Formulars.');
    },
  });

  const [deleteFormSubmissions] = useMutation(deleteFormSubmissionsGql, {
    variables: { companyCode, projectCode },
    onCompleted: () => refetch() && snackbar.showMessage('Alle Einreichungen des Formulars wurde erfolgreich gelöscht'),
    onError: () => refetch() && snackbar.showMessage('Fehler beim löschen der Einreichungen des Formulars'),
  });

  if (error) return (<p>Fehler beim laden</p>);

  const statusTranslation = {
    active: 'Aktiv',
    inactive: 'Inaktiv',
  };

  const columns = [
    { field: 'title', headerName: 'Titel', type: 'string', flex: 0.5 },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      flex: 0.5,
      valueFormatter: ({ value }) => statusTranslation[value] },
    {
      field: 'views',
      headerName: 'Ansicht',
      width: 120,
      valueGetter: (params) => params.getValue('code'),
      renderCell: ({ row }) => (
        <>
          <Tooltip title="Moderatorenansicht">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                history.push(`${match.url}/edit/${row.code}/moderator`);
              }}
            >
              <PollIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Referentenansicht">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                history.push(`${match.url}/edit/${row.code}/results`);
              }}
            >
              <RecordVoiceOverIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 200,
      valueGetter: (params) => params.getValue('code'),
      renderCell: ({ row }) => (
        <>
          <Tooltip title="Duplizieren">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                history.push(`${match.url}/copy/${row.code}`);
              }}
            >
              <FileCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Alle Einreichungen löschen">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                await confirm({
                  description: (
                    <span>
                      Wollen Sie wirklich alle Einreichungen des Formulares mit dem Namen &quot;<strong>{row.title}</strong>&quot; löschen?
                    </span>),
                  confirmationText: 'Löschen',
                  confirmationButtonProps: { color: 'secondary' },
                });
                deleteFormSubmissions({ variables: { code: row.code } });
              }}
            >
              <RotateLeftIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Löschen">
            <IconButton
              onClick={async (e) => {
                e.preventDefault();
                await confirm({
                  description: <span>Wollen Sie das Formular mit dem Namen &quot;<strong>{row.title}</strong>&quot; wirklich löschen?</span>,
                  confirmationText: 'Löschen',
                  confirmationButtonProps: { color: 'secondary' },
                });
                deleteForm({ variables: { code: row.code } });
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageTitle
        title="Formulare"
        button={(
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push(`${match.url}/add`)}
          >
            Erstellen
          </Button>
        )}
      />
      {loading ? <LoadingTable columns={columns.length} /> : (
        <Paper>
          <DataGrid
            columns={columns}
            rows={data?.getAllForms?.forms || []}
            getRowId={(row) => row.code}
            componentsProps={{ toolbar: { add: true } }}
            pageSize={100}
            autoHeight
            hideFooterRowCount="false"
            localeText={datagrid}
            onCellClick={(selection) => (selection.colIndex !== 2 && selection.colIndex !== 3)
            && history.push(`${match.url}/edit/${selection.row.code}`)}
          />
        </Paper>
      )}
    </>
  );
}
