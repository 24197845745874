import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Breadcrumb from './BreadCrumb';

const useStyles = makeStyles((theme) => ({

  surface: {
    marginTop: theme.spacing(10),
  },

  bread: {
    marginBottom: theme.spacing(2),
  },

}));

export default function Surface(props) {
  const classes = useStyles();
  return (
    <Container className={classes.surface}>
      <div className={classes.bread}>
        <Breadcrumb />
      </div>
      {props.children}
    </Container>
  );
}
