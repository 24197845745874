import React from 'react';
import ShowMedia from './components/ShowMedia';
import ShowCategories from './components/ShowCategories';

export default function Mediathek() {
  return (
    <>
      <ShowMedia />
      <ShowCategories />
    </>
  );
}
