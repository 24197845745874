import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import React, { useState, useEffect } from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { Button, Typography } from '@material-ui/core';

export default function Timesettings(props) {
  const [projectTimes, setProjectTimes] = useState([]);

  const onAdd = () => {
    const newState = [...projectTimes];
    newState.push({ start: new Date().toISOString(), end: new Date().toISOString() });
    setProjectTimes(newState);
    props.onChange && props.onChange(newState);
  };
  const onDelete = (index) => {
    const newState = [...projectTimes];
    newState.splice(index, 1);
    setProjectTimes(newState);
    props.onChange && props.onChange(newState);
  };

  const onChange = (index, field, value) => {
    const newState = [...projectTimes];
    newState[index] = { ...newState[index], [field]: value };
    setProjectTimes(newState);
    props.onChange && props.onChange(newState);
  };

  useEffect(() => {
    setProjectTimes(props.projectTimes || []);
  }, [props.projectTimes]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={7} xl={5}>
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Zugangszeit
        {(projectTimes.length < props.maxTimes || !props.maxTimes)
        && (
        <Button
          variant="contained"
          style={{ borderRadius: 50, minWidth: 0, marginLeft: 5, padding: '0px 8px' }}
          color="primary"
          onClick={onAdd}
        >
          +
        </Button>
        )}
      </Typography>
      {projectTimes && projectTimes.map((time, index) => (
        <Grid container spacing={3} key={`a${index}`}>
          <Grid item xs={0.5}>
            <Typography style={{ marginTop: '15px' }}>
              {index + 1}
              .
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="Anfang"
                value={time.start}
                onChange={(end) => onChange(index, 'start', end)}
                onError={(err) => { if (err) console.error(err); }}
                format="dd.MM.yyyy HH:mm"
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={5}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="Schluss"
                value={time.end}
                onChange={(end) => onChange(index, 'end', end)}
                onError={(err) => { if (err) console.error(err); }}
                format="dd.MM.yyyy HH:mm"
                minDate={time.start}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" style={{ marginTop: '8px' }} color="secondary" onClick={() => onDelete(index)}>Löschen</Button>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
