import React, { useMemo } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import SelectFormField from '../../../components/forms/SelectFormField';

export default function ReportFilter({ filters, onChange }) {
  const eventTargets = useMemo(() => {
    const all = [{ value: 'all', label: 'Alle' }];

    if (filters.targetType === 'CTA') {
      return [
        ...all,
        ...filters.ctaOptions,
      ];
    }

    const pageDefaults = [
      { optionType: 'subheader', value: 'Statische Seiten' },
      { value: '/', label: 'Willkommensseite' },
      { value: '/stream', label: 'Streamseite' },
      { value: '/fin', label: 'Nach-Event Seite' },
    ];

    if (filters.targetType === 'page') {
      return [
        ...all,
        ...pageDefaults,
        ...filters.pageOptions,
      ];
    }

    return [
      ...all,
      ...pageDefaults,
      ...filters.pageOptions,
      ...filters.ctaOptions,
    ];
  }, [filters]);

  return (
    <Grid
      item
      container
      justify="flex-start"
      alignItems="flex-start"
      style={{ marginBlock: 15 }}
      direction="row"
      spacing={2}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid item xs={12} sm="auto">
          <KeyboardDateTimePicker
            variant="inline"
            ampm={false}
            label="Start"
            value={filters.startDate}
            maxDate={new Date()}
            onChange={(target) => onChange({ ...filters, startDate: target })}
            format="dd.MM.yyyy HH:mm"
            inputVariant="outlined"
            style={{ width: 210 }}
          />
        </Grid>
        <Grid item xs={12} sm="auto">
          <KeyboardDateTimePicker
            variant="inline"
            ampm={false}
            label="Ende"
            value={filters.endDate}
            minDate={filters.startDate}
            onChange={(target) => onChange({ ...filters, endDate: target })}
            format="dd.MM.yyyy HH:mm"
            inputVariant="outlined"
            style={{ width: 210 }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid item xs={12} sm="auto">
        <SelectFormField
          label="Eventtyp"
          name="eventType"
          value={filters.targetType}
          options={[
            { value: 'all', label: 'Alle' },
            { value: 'page', label: 'Seitenaufruf' },
            { value: 'CTA', label: 'Klick auf Karte' },
          ]}
          onChange={({ target }) => onChange({ ...filters, targetType: target.value, target: 'all' })}
          required
          style={{ width: 210 }}
        />
      </Grid>
      <Grid item xs={12} sm="auto">
        <SelectFormField
          label="Eventziel"
          name="eventTarget"
          value={filters.target}
          options={eventTargets}
          onChange={({ target }) => (target.value && onChange({ ...filters, target: target.value }))}
          required
          hasGroups
          style={{ width: 210 }}
        />
      </Grid>
      <Grid item xs={12} sm="auto">
        <FormControlLabel
          control={(
            <Checkbox
              checked={filters.groupByCustomer}
              onChange={() => onChange({ ...filters, groupByCustomer: !filters.groupByCustomer })}
              name="groupByCustomer"
              color="primary"
            />
            )}
          style={{ marginTop: 8 }}
          label="Aktionen gruppieren"
        />
      </Grid>
    </Grid>
  );
}
