import { gql } from '@apollo/client';

export const getTicketCategoriesGQL = gql`
  query getTicketCategories(
    $companyCode: String!
    $projectCode: String!
  ) {
    getTicketCategories(
      companyCode: $companyCode
      projectCode: $projectCode
    ) {
      code
      name
      status
      ticketCount
      isDefault
    }
  }
`;

export const getTicketCategoryGQL = gql`
  query getTicketCategory(
    $companyCode: String!
    $projectCode: String!
    $code: String!
  ) {
    getTicketCategory(
      companyCode: $companyCode
      projectCode: $projectCode
      code: $code
    ) {
      code
      name
      status
      ticketCount
      isDefault
    }
  }
`;

export const addTicketCategoryMutation = gql`
  mutation addTicketCategory($companyCode: String!, $projectCode: String!, $ticketCategory: AddTicketCategory!) {
    addTicketCategory(companyCode: $companyCode, projectCode: $projectCode, ticketCategory: $ticketCategory)
  }
`;

export const updateTicketCategoryMutation = gql`
  mutation updateTicketCategory($companyCode: String!, $projectCode: String!, $code: String!, $ticketCategory: UpdateTicketCategory!) {
    updateTicketCategory(companyCode: $companyCode, projectCode: $projectCode, code: $code, ticketCategory: $ticketCategory)
  }
`;

export const deleteTicketCategoryMutation = gql`
  mutation deleteTicketCategory($companyCode: String!, $projectCode: String!, $code: String!) {
    deleteTicketCategory(companyCode: $companyCode, projectCode: $projectCode, code: $code)
  }
`;
