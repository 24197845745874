import { Paper, Grid, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'material-ui-snackbar-provider';
import SelectFormField from '../../../components/forms/SelectFormField';
import InputFormField from '../../../components/forms/InputFormField';
import PageTitle from '../../../components/PageTitle';
import { addInviteGql } from '../gql';
import { getTicketCategoriesGQL } from '../../TicketCategories/gql';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
  },
}));

export default function InviteEdit() {
  const [invite, setInvite] = useState({});
  const classes = useStyles();
  const snackbar = useSnackbar();
  const params = useParams();
  const history = useHistory();

  const { data: getTicketCategoriesData = { getTicketCategories: [] } } = useQuery(getTicketCategoriesGQL, {
    variables: { companyCode: params.company, projectCode: params.project },
  });

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const [addInvite, { loading: addInviteLoading }] = useMutation(addInviteGql, {
    onCompleted: () => { history.push(`/${params.company}/${params.project}/invites`); showSuccessMessage(); },
    onError: () => snackbar.showMessage('Fehler beim Hinzufügen des Teilnehmers'),
  });

  const save = (e) => {
    e.preventDefault();
    addInvite({
      variables: {
        companyCode: params.company,
        projectCode: params.project,
        invite: { ...invite },
      },
    });
  };

  return (
    <>
      <form onSubmit={save}>
        <PageTitle
          title="Neue Einladung hinzufügen"
          button={(
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              {(addInviteLoading) ? <CircularProgress size={14} /> : 'Hinzufügen'}
            </Button>
          )}
        />
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <InputFormField
                label="Email"
                name="email"
                value={invite.email}
                onChange={({ target }) => setInvite({ ...invite, email: target.value })}
                required
                disabled={!!params.participantEmail}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <SelectFormField
                label="Ticketkategorie"
                name="ticketCatgory"
                value={invite.ticketCategory?.code || ''}
                options={getTicketCategoriesData?.getTicketCategories?.map((cat) => ({ label: cat.name, value: cat.code })) || []}
                onChange={({ target }) => setInvite({ ...invite, ticketCategory: { code: target.value } })}
                required={!!invite.ticketCategory?.code}
                fullwidth
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <InputFormField
                label="Vorname"
                name="firstname"
                value={invite.firstname}
                onChange={({ target }) => setInvite({ ...invite, firstname: target.value })}
                required
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <InputFormField
                label="Nachname"
                name="lastname"
                value={invite.lastname}
                onChange={({ target }) => setInvite({ ...invite, lastname: target.value })}
                required
              />
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
}
