import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function InputFormField(props) {
  return (
    <TextField
      variant="outlined"
      fullWidth
      {...props}
      value={props.value || ''}
    />
  );
}

InputFormField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};
