import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAuthDispatch, useAuthState } from '../../context/auth';
import { useNavigationDispatch } from '../../context/navigation';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {

    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% )',
      marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  burgerButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbarIcons: {
    marginLeft: theme.spacing(2),
  },
  typography: {
    alignSelf: 'center',
    fontWeight: 600,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    marginLeft: theme.spacing(8),
    flexGrow: 2,
    alignSelf: 'center',
  },
}));

function ResponsiveDrawer({ projectName, projectType }) {
  const { user } = useAuthState();
  const authDispatch = useAuthDispatch();

  const navigationDispatch = useNavigationDispatch();

  const theme = useTheme();
  const classes = useStyles();
  const md = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerToggle = () => {
    navigationDispatch({ type: 'TOGGLE_BURGER' });
  };

  const handleLogout = () => {
    authDispatch({ type: 'LOGOUT' });
  };

  const typeTranslation = {
    child: '(Teilevent)',
    parent: '(Gruppenevent)',
    standard: '',
  };

  if (!user) {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" noWrap>
              WIRKUNG.stream
            </Typography>
          </Toolbar>
        </AppBar>
        <br />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box display="flex" flexGrow={1}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.burgerButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.typography} noWrap>
              WIRKUNG.stream
            </Typography>
            {(md && projectName) && (
            <Typography variant="h6" className={classes.title}>
              {`${projectName} ${typeTranslation[projectType]}`}
            </Typography>
            )}
          </Box>
          <IconButton
            color="inherit"
            edge="end"
            className={classes.toolbarIcons}
            onClick={handleLogout}
          >
            <Typography variant="button" noWrap>
              Logout
            </Typography>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default ResponsiveDrawer;
