import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import InputFormField from '../../../components/forms/InputFormField';

export default function ContentFlowParams(props) {
  const [params, setParams] = useState(props.params);

  const onChange = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(newState);
  };

  return (
    <>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 10 }}>Contentflow Stream Parameter</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <InputFormField
            label="Corporation Id"
            name="corporationId"
            value={params?.corporationId}
            onChange={({ target }) => onChange('corporationId', target.value)}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <InputFormField
            label="Output ID"
            name="outputId"
            value={params?.outputId}
            onChange={({ target }) => onChange('outputId', target.value)}
            required
          />
        </Grid>
      </Grid>
    </>
  );
}
