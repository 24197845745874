import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { GroupAdd } from '@material-ui/icons';
import PeopleIcon from '@material-ui/icons/People';
import HistoryIcon from '@material-ui/icons/History';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PollIcon from '@material-ui/icons/Poll';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MoodIcon from '@material-ui/icons/Mood';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import BarChartIcon from '@material-ui/icons/BarChart';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ClassIcon from '@material-ui/icons/Class';
import { useAuthState } from '../../context/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    background: theme.palette.background.paper,
  },
  nested: {},
  link: {
    textDecoration: 'none',
    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
      color: '#000',
    },
  },
  subnav: {
    marginLeft: '10px',
    backgroundColor: theme.palette.grey[100],
  },
  subItem: {
    hyphens: 'auto',
  },
}));

function getItems(type = 'standard') {
  let customer = {
    key: 100,
    name: 'Teilnehmer',
    icon: <PeopleIcon />,
    subitems: [
      {
        key: 101,
        name: 'Einladungen',
        icon: <GroupAdd />,
        route: 'invites',
      },
      {
        key: 102,
        name: 'Reg. Teilnehmer',
        icon: <PeopleIcon />,
        route: 'participants',
      },
      {
        key: 103,
        name: 'Login Historie',
        icon: <HistoryIcon />,
        route: 'logins',
      },
      {
        key: 14,
        name: 'Auswertung',
        icon: <BarChartIcon />,
        route: 'report',
      },
    ],
  };

  if (type === 'parent') {
    return {
      list: [
        {
          items: [
            customer,
            {
              key: 200,
              name: 'Konfiguration',
              icon: <SettingsIcon />,
              roles: [0, 1],
              subitems: [
                {
                  key: 201,
                  name: 'E-Mail Einstellungen',
                  route: 'config/mailSettings',
                },
                {
                  key: 203,
                  name: 'Ticketkategorien',
                  route: 'ticketCategories',
                },
              ],
            },
          ],
        },
      ],
    };
  }

  if (type === 'child') customer = null;

  const myItems = {
    list: [
      {
        items: [
          {
            key: 1,
            name: 'Übersicht',
            icon: <DashboardIcon />,
            route: 'overview',
          },
          {
            key: 2,
            name: 'Karten',
            icon: <CallToActionIcon />,
            route: 'cta',
            roles: [0, 1, 2],
          },
          {
            key: 3,
            name: 'Interaktive Elemente',
            roles: [0, 1, 2],
            icon: <DynamicFeedIcon />,
            subitems: [
              {
                key: 4,
                name: 'Umfragen',
                icon: <PollIcon />,
                route: 'poll',
              },
              {
                key: 5,
                name: 'Formulare',
                icon: <FormatAlignCenterIcon />,
                route: 'forms',
              },
              {
                key: 6,
                name: 'Emoji',
                icon: <MoodIcon />,
                route: 'emojis',
              },

            ],
          },

          {
            key: 7,
            name: 'Seiten',
            roles: [0, 1, 2],
            icon: <PostAddIcon />,
            subitems: [
              {
                key: 8,
                name: 'Seiten',
                icon: <InsertDriveFileIcon />,
                route: 'customPages',
              },
              {
                key: 9,
                name: 'Seitenkategorien',
                icon: <ClassIcon />,
                route: 'pageTypes',
              },
            ],
          },

          {
            key: 12,
            name: 'Mediathek',
            icon: <VideoLibraryIcon />,
            route: 'mediathek',
            roles: [0, 1, 2],
          },
          {
            key: 13,
            name: 'Streams',
            icon: <OndemandVideoIcon />,
            roles: [0, 1, 2],
            route: 'streams',
          },
          customer,
          {
            key: 15,
            name: 'Konfiguration',
            icon: <SettingsIcon />,
            roles: [0, 1],
            subitems: [
              {
                key: 16,
                name: 'Frontend Einstellungen',
                route: 'config',
              },
              {
                key: 17,
                name: 'E-Mail Einstellungen',
                route: 'config/mailSettings',
              },
              {
                key: 18,
                name: 'Zugangseinstellungen',
                route: 'config/accessSettings',
              },
              {
                key: 19,
                name: 'Chat-Einstellungen',
                route: 'chat-settings',
              },
              {
                key: 20,
                name: 'Ticketkategorien',
                route: 'ticketCategories',
              },
              {
                key: 21,
                name: 'Nutzer',
                route: 'users',
              },
            ],
          },
        ],
      },
    ],
  };

  return myItems;
}

export default function NavList({ projectType }) {
  const [open, setOpen] = useState(null);
  const { user } = useAuthState();

  const match = useRouteMatch();
  const items = getItems(projectType, user.roleCode);
  const classes = useStyles();

  const roleCode = parseInt(user.roleCode);
  const filteredItems = {
    list: [
      {
        items: items.list[0].items.filter((item) => {
          if (!item) return true;
          if (!item.roles) return true;
          return item.roles.includes(roleCode);
        }),
      },
    ],
  };

  if (projectType) {
    return (
      <div>
        {filteredItems.list.map((list, index) => (
          <List
            key={index}
            className={classes.root}
          >
            {list.items.filter((item) => item).map((item) => {
              if (item.subitems != null) {
                return (
                  <div key={item.key}>
                    <ListItem
                      button
                      onClick={() => setOpen(item.key === open ? null : item.key)}
                    >
                      {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}

                      <ListItemText primary={item.name} />
                      {open === item.key ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse
                      key={`${item.key}-collapse`}
                      component="li"
                      in={
                    !!(
                      open === item.key
                      || item.subitems.find(({ key }) => open === key)
                    )
                  }
                      timeout="auto"
                      unmountOnExit
                      className={classes.subnav}
                    >
                      <List disablePadding>
                        {item.subitems.map((sitem) => (
                          <Link
                            key={`f${sitem.key}`}
                            className={classes.link}
                            to={`${match.url}/${sitem.route}`}
                          >
                            <ListItem key={`g${sitem.key}`} className={classes.nested}>
                              <ListItemIcon>{sitem.icon}</ListItemIcon>
                              <ListItemText
                                className={classes.subItem}
                                key={`h${sitem.key}`}
                                primary={sitem.name}
                              />
                            </ListItem>
                          </Link>
                        ))}
                      </List>
                    </Collapse>{' '}
                  </div>
                );
              }
              return (
                <Link
                  key={item.key}
                  className={classes.link}
                  to={`${match.url}/${item.route}`}
                >
                  <ListItem
                    button
                    onClick={() => setOpen(item.key)}
                    key={item.key}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText className={classes.subItem} primary={item.name} />
                  </ListItem>
                </Link>
              );
            })}
            <Divider key={list.key} absolute />
          </List>
        ))}
      </div>
    );
  }
  return null;
}
