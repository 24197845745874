import React from 'react';
import { Button, Chip, CircularProgress, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  pageTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  typo: {
    color: theme.palette.text.hint,
    display: 'inline',
  },
  button: {
    // boxShadow: theme.customShadows.widget,
    textTransform: 'none',
    '&:active': {
      // boxShadow: theme.customShadows.widgetWide,
    },
  },
  flexRowWrap: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      marginBlockStart: theme.spacing(1),
    },
  },
}));

export default function PageTitle(props) {
  const classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <div className={classes.flexRowWrap}>
        <Typography className={classes.typo} variant="h4" size="sm">
          {props.title}
        </Typography>
        {props.chip && (
          <Chip
            size="small"
            {...props.chip}
          />
        )}
      </div>
      {props.button && props.button}
      {props.buttons && (
        <div className={classes.flexRowWrap}>
          {props.buttons.map((btn) => (
            <Button
              color={btn.color}
              type={btn.type}
              name={btn.name}
              variant="contained"
              size="large"
              disabled={btn.disabled}
            >
              {btn.loading ? <CircularProgress size={24} style={{ color: 'DimGray' }} /> : btn.caption}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}
