import { gql } from '@apollo/client';

export const allFormsGql = gql`
query getAllForms($companyCode: String!, $projectCode: String!) {
  getAllForms(companyCode: $companyCode, projectCode: $projectCode) {
      forms {
        code
        title
        status
      }
    }
  }`;

export const formGql = gql`
query getForm($companyCode: String!, $projectCode: String!, $code: String!) {
  getForm(companyCode: $companyCode, projectCode: $projectCode, code: $code) {
      code
      title
      description
      automaticallySendToSpeaker
      fields {
        id
        type
        label
        defaultValue
        hintText
        sequenceId
        status
        params
        isRequired
      }
      status
    }
  }`;

export const addFormGql = gql`
mutation createForm($companyCode: String!, $projectCode: String!, $form: CreateFormInput!){
  createForm(companyCode: $companyCode, projectCode: $projectCode, form:$form) {
    code
  }  
}`;

export const editFormGql = gql`
mutation updateForm($companyCode: String!, $projectCode: String!, $code: String!, $form: UpdateFormInput!){
  updateForm(companyCode: $companyCode, projectCode: $projectCode, code: $code, form:$form)
}`;

export const deleteFormGql = gql`
mutation deleteForm($companyCode: String!, $projectCode: String!, $code: String!){
  deleteForm(companyCode: $companyCode, projectCode: $projectCode, code: $code)    
}`;

export const deleteFormSubmissionsGql = gql`
mutation deleteAllFormSubmissions($companyCode: String!, $projectCode: String!, $code: String!){
  deleteAllFormSubmissions(companyCode: $companyCode, projectCode: $projectCode, code:$code)    
}`;

export const exportFormGql = gql`
  query exportForm($companyCode: String!, $projectCode: String!, $code:String!) {
    exportForm(companyCode: $companyCode, projectCode: $projectCode, code:$code) {
      url
    }
  }
`;

export const getSubmissionsGql = gql`
query getFormSubmissions($companyCode: String!, $projectCode: String!, $code: String!){
  getFormSubmissions(companyCode:$companyCode, projectCode:$projectCode, code:$code){
    submissions {
      id: submissionId
      author
      authorType
      isLocked
      status
      fields {
        formFieldId
        label
        content
        updatedContent
      }
      updatedAt
      createdAt
    }
  }
}`;

export const getLockedFormSubmissionsGql = gql`
query getLockedFormSubmissions($companyCode: String!, $projectCode: String!, $code: String!){
  getLockedFormSubmissions(companyCode:$companyCode, projectCode:$projectCode, code:$code){
    id: submissionId
    author
    authorType
    isLocked
    status
    updatedAt
    createdAt
  }
}`;

export const getSubmissionsForSpeakerGql = gql`
query getFormSubmissionsForSpeaker($companyCode: String!, $projectCode: String!, $code: String!){
  getFormSubmissionsForSpeaker(companyCode:$companyCode, projectCode:$projectCode, code:$code){
    submissions {
      id: submissionId
      author
      authorType
      isLocked
      status
      fields {
        formFieldId
        label
        content
        updatedContent
      }
      updatedAt
      createdAt
    }
  }
}`;

export const deleteFormSubmissionGql = gql`
mutation deleteFormSubmission ($companyCode: String!, $projectCode: String!, $submissionId: Int!) {
  deleteFormSubmission(companyCode: $companyCode, projectCode: $projectCode, submissionId: $submissionId)
}
`;

export const updateFormSubmissionGql = gql`
mutation updateFormSubmission ($companyCode: String!, $projectCode: String!, $submissionId: Int!, $updateFormSubmission: UpdateFormSubmission!){
  updateFormSubmission(companyCode: $companyCode, projectCode: $projectCode, submissionId: $submissionId, formSubmission: $updateFormSubmission)
}
`;

export const addFormSubmissionGql = gql`
mutation addFormSubmission ($companyCode: String!, $projectCode: String!, $code: String!, $author: String!, $fields: [AddFormSubmissionField]!){
  addFormSubmission(companyCode: $companyCode, projectCode: $projectCode, code: $code, author: $author, fields: $fields)
}
`;

export const lockFormSubmissionGql = gql`
mutation lockFormSubmission ($companyCode: String!, $projectCode: String!, $submissionId: Int!) {
  lockFormSubmission(companyCode: $companyCode, projectCode: $projectCode, submissionId: $submissionId)
}
`;

export const unlockFormSubmissionGql = gql`
mutation unlockFormSubmission ($companyCode: String!, $projectCode: String!, $submissionId: Int!) {
  unlockFormSubmission(companyCode: $companyCode, projectCode: $projectCode, submissionId: $submissionId)
}
`;
