import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  button: {
    whiteSpace: 'noWrap',
  },

}));

export default function EmojiCTAParams({ getRoute }) {
  const classes = useStyles();
  return (
    <Grid container spacing={3} item xs={12} md={12}>
      <Grid item xs={12}>
        <Typography variant="h5">Emojis Verwalten</Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={3} xl={3}>
        <Button className={classes.button} href={getRoute('/emojis')} target="_blank" color="primary">Zur Verwaltung</Button>
      </Grid>
    </Grid>
  );
}
