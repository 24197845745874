import { Grid, FormControl, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import {
  updateFormSubmissionGql,
  addFormSubmissionGql,
  lockFormSubmissionGql,
  unlockFormSubmissionGql,
} from '../gql';

export default function DialogFormSubmission({ open, onClose, onFinish, submission, onLock, onUnlock, fields = [] }) {
  const [submissionFields, setSubmissionFields] = useState({});
  const [author, setAuthor] = useState();
  const params = useParams();
  const newSubmission = !submission;

  const [updateFormSubmission] = useMutation(updateFormSubmissionGql, {
    variables: { companyCode: params.company, projectCode: params.project, submissionId: submission?.id },
  });

  const [addFormSubmission] = useMutation(addFormSubmissionGql, {
    variables: { companyCode: params.company, projectCode: params.project, code: params.code },
  });

  const [lockFormSubmission] = useMutation(lockFormSubmissionGql, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: onLock,
  });

  const [unlockFormSubmission] = useMutation(unlockFormSubmissionGql, {
    variables: { companyCode: params.company, projectCode: params.project },
    onCompleted: onUnlock,
  });

  useEffect(() => {
    const fields = (submission?.fields || []).reduce((acc, field) => {
      acc[field.formFieldId] = field;
      return acc;
    }, {});
    setSubmissionFields(fields);
    setAuthor(submission?.author || 'Moderator');
  }, [submission?.fields, submission?.author, open]);

  useEffect(() => {
    let intervalId;
    if (submission?.id) {
      lockFormSubmission({ variables: { submissionId: submission?.id } });
      intervalId = setInterval(() => {
        lockFormSubmission({ variables: { submissionId: submission?.id } });
      }, 10000);
    }

    return () => {
      if (submission?.id) unlockFormSubmission({ variables: { submissionId: submission?.id } });
      if (intervalId) clearInterval(intervalId);
    };
  }, [open]);

  const onSubmit = async (e) => {
    e.preventDefault();
    // add
    if (newSubmission) {
      // apply defaults
      for (const field of fields) {
        if (!field.defaultValue) continue;
        if (submissionFields[field.id]) continue;
        submissionFields[field.id] = { formFieldId: field.id, updatedContent: field.defaultValue };
      }

      const fieldsToAdd = Object.values(submissionFields)
        .filter(({ updatedContent }) => updatedContent)
        .map(({ formFieldId, updatedContent }) => ({ formFieldId, content: updatedContent }));
      await addFormSubmission({
        variables: { fields: fieldsToAdd, author },
      });
      onFinish();
      return;
    }

    // update
    const fieldsToUpdate = Object.values(submissionFields)
      .filter(({ updatedContent }) => updatedContent)
      .map(({ formFieldId, updatedContent }) => ({ formFieldId, updatedContent }));

    if (!fieldsToUpdate.length) return;
    await updateFormSubmission({
      variables: { updateFormSubmission: { fields: fieldsToUpdate } },
    });
    onFinish();
  };

  const renderField = (field) => {
    if (['text', 'email', 'number', 'multilineText'].includes(field.type)) {
      return (
        <TextField
          key={field.id}
          type={field.type === 'multilineText' ? 'text' : field.type}
          margin="dense"
          fullWidth
          variant="outlined"
          color="secondary"
          value={submissionFields[field.id]?.updatedContent || submissionFields[field.id]?.content || ''}
          helperText={submissionFields[field.id]?.updatedContent && !newSubmission && `Orginal Wert: ${submissionFields[field.id]?.content}`}
          label={field.label}
          inputProps={{ maxLength: 500 }}
          required={field.isRequired}
          defaultValue={newSubmission ? field.defaultValue : null}
          rows={field.type === 'multilineText' ? 6 : null}
          multiline={field.type === 'multilineText'}
          onChange={(e) => {
            setSubmissionFields({
              ...submissionFields,
              [field.id]: {
                formFieldId: field.id,
                content: submissionFields[field.id]?.content,
                updatedContent: e.target.value,
              },
            });
          }}
        />
      );
    }

    if (field.type === 'checkbox') {
      const content = submissionFields[field.id]?.updatedContent || submissionFields[field.id]?.content;
      return (
        <Grid item xs={12} key={field.id}>
          <FormControl component="fieldset" variant="standard">
            <FormControlLabel
              control={(
                <Checkbox
                  checked={content === 'true'}
                  onChange={(e) => {
                    setSubmissionFields({
                      ...submissionFields,
                      [field.id]: {
                        formFieldId: field.id,
                        content: submissionFields[field.id]?.content,
                        updatedContent: e.target.checked ? 'true' : 'false',
                      },
                    });
                  }}
                  color="primary"
                />
              )}
              label={field.label}
            />
            {submissionFields[field.id]?.updatedContent && !newSubmission
              && (
              <FormHelperText style={{ marginTop: -10, marginLeft: 32 }}>
                  {`Orginal Wert: ${submissionFields[field.id]?.content === 'true' ? '✅' : '❌'}`}
              </FormHelperText>
              )}
          </FormControl>
        </Grid>
      );
    }
    return null;
  };

  return (

    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title">Einreichung {newSubmission ? 'hinzufügen' : 'editieren'}</DialogTitle>
        <DialogContent>
          <TextField
            value={author}
            margin="dense"
            type="text"
            fullWidth
            variant="outlined"
            color="secondary"
            inputProps={{ maxLength: 20 }}
            required
            disabled={!newSubmission}
            label="Name"
            onChange={(e) => setAuthor(e.target.value)}
          />
          {fields.map((field) => renderField(field))}
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={onClose} color="primary">
              Abbrechen
            </Button>
            <Button type="submit" color="primary">
              Speichern
            </Button>
          </div>
        </DialogActions>
      </form>

    </Dialog>
  );
}
