import { Button, makeStyles, Paper, Switch } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'material-ui-snackbar-provider';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PageTitle from '../../../components/PageTitle';
import {
  updateFormSubmissionGql,
  deleteFormSubmissionGql,
  getSubmissionsGql,
  formGql,
  getLockedFormSubmissionsGql,
} from '../gql';
import { datagrid } from '../../../util/language';
import DialogFormSubmission from '../components/DialogFormSubmission';
import DialogFieldSelection from '../components/DialogFieldSelection';

const useStyles = makeStyles({
  lockedColumn: {
    backgroundColor: '#ff1744',
    color: 'white',
  },
});

export default function ModeratorView() {
  const pageParams = useParams();
  const confirm = useConfirm();
  const snackbar = useSnackbar();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fieldModalOpen, setFieldModalOpen] = useState(false);
  const [currentSubmission, setCurrentSubmission] = useState({});
  const [submissions, setSubmissions] = useState([]);
  const [fieldsToShow, setFieldsToShow] = useState([]);

  const { loading, error, refetch } = useQuery(getSubmissionsGql, {
    variables: {
      companyCode: pageParams.company,
      projectCode: pageParams.project,
      code: pageParams.code,
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getFormSubmissions }) => {
      setSubmissions(getFormSubmissions.submissions);
    },
  });

  const { loading: formLoading, data: formData, error: formError } = useQuery(formGql, {
    variables: {
      companyCode: pageParams.company,
      projectCode: pageParams.project,
      code: pageParams.code,
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getForm }) => {
      const key = `form-moderator-fields-${[pageParams.company, pageParams.project, pageParams.code].join('-')}`;
      if (!localStorage.getItem(key)) return setFieldsToShow(getForm.fields);
      // load saved fields
      const fieldIds = localStorage.getItem(key);
      return setFieldsToShow(getForm.fields.filter(({ id }) => fieldIds.includes(id)));
    },
  });

  const { refetch: lockRefetch } = useQuery(getLockedFormSubmissionsGql, {
    variables: {
      companyCode: pageParams.company,
      projectCode: pageParams.project,
      code: pageParams.code,
    },
    fetchPolicy: 'no-cache',
    pollInterval: 2000,
    onCompleted: ({ getLockedFormSubmissions }) => {
      const lockedSubmissionById = getLockedFormSubmissions?.reduce((acc, submission) => {
        acc[submission.id] = submission;
        return acc;
      }, {}) || {};
      const updatedSubmissions = submissions.map((submission) => ({ ...submission, isLocked: !!lockedSubmissionById[submission.id] }));
      setSubmissions([...updatedSubmissions]);
    },
  });

  const [updateFormSubmission] = useMutation(updateFormSubmissionGql, {
    variables: {
      companyCode: pageParams.company,
      projectCode: pageParams.project,
    },
    onCompleted: () => refetch() && snackbar.showMessage('Einreichung wurde erfolgreich gespeichert'),
    onError: () => snackbar.showMessage('Fehler beim Speichern der Einreichung.'),
  });

  const [deleteFormSubmission] = useMutation(deleteFormSubmissionGql, {
    variables: {
      companyCode: pageParams.company,
      projectCode: pageParams.project,
    },
    onCompleted: () => refetch() && snackbar.showMessage('Einreichung wurde erfolgreich gelöscht'),
    onError: () => refetch() && snackbar.showMessage('Fehler beim Löschen der Einreichung'),
  });

  if (loading || formLoading) return (<p>Loading</p>);
  if (error || formError) return (<p>Fehler beim laden</p>);

  const getCellClassName = ({ row }) => (row.isLocked ? classes.lockedColumn : '');

  const fieldsColumns = fieldsToShow.map((field) => ({
    field: field.id,
    // eslint-disable-next-line react/no-danger
    headerName: (<span dangerouslySetInnerHTML={{ __html: field.label }} />),
    cellClassName: getCellClassName,
    type: 'string',
    flex: 0.4,
    valueGetter: ({ row }) => {
      const submissionField = row?.fields?.find(({ formFieldId }) => formFieldId === field.id);
      let content = submissionField?.updatedContent || submissionField?.content;
      if (['checkbox', 'anonymCheckbox'].includes(field.type)) content = content === 'true' ? '✅' : '❌';
      if (field.type === 'number' && submissionField) content = parseInt(content).toLocaleString('de-de');
      return content;
    },
  })) || [];

  const columns = [
    { field: 'id', headerName: 'Id', type: 'string', flex: 0.2, cellClassName: getCellClassName },
    { field: 'author', headerName: 'Name', type: 'string', flex: 0.4, cellClassName: getCellClassName },
    ...fieldsColumns,
    {
      field: 'status',
      headerName: 'Posted',
      width: 120,
      cellClassName: getCellClassName,
      renderCell: ({ row }) => (
        <Switch
          checked={row.status === 'sentToSpeaker'}
          onChange={(async (e) => {
            e.preventDefault();
            await updateFormSubmission({
              variables: {
                updateFormSubmission: { status: e.target.checked ? 'sentToSpeaker' : 'active' },
                submissionId: row.id,
              },
            });
          })}
          color="primary"
          name="checked"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          disabled={row.isLocked}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Aktionen',
      width: 120,
      valueGetter: (params) => params.getValue('code'),
      cellClassName: getCellClassName,
      renderCell: ({ row }) => (
        <Tooltip title="Löschen">
          <IconButton
            onClick={async (e) => {
              e.preventDefault();
              await confirm({
                description: (
                  <span>Wollen Sie die Einreichung mit der Id &quot;
                    <strong>{row.id}</strong>
                    &quot; wirklich löschen?
                  </span>),
                confirmationText: 'Löschen',
                confirmationButtonProps: { color: 'secondary' },
              });
              deleteFormSubmission({ variables: { submissionId: row.id } });
            }}
            disabled={row.isLocked}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  return (
    <>
      <PageTitle
        title="Einreichungen"
        button={(
          <div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setCurrentSubmission(null);
                setOpen(true);
              }}
            >
              Einreichung hinzufügen
            </Button>
            {/*    <Button
              style={{ marginLeft: '15px' }}
              color="primary"
              variant="contained"
              onClick={() => history.push(`${match.url}/add`)}
            >
              Exportieren
            </Button> */}
            <Button
              style={{ marginLeft: '15px' }}
              color="default"
              variant="contained"
              onClick={() => setFieldModalOpen(true)}
            >
              Spaltenauswahl
            </Button>
            <Button
              style={{ marginLeft: '15px' }}
              color="default"
              variant="contained"
              onClick={() => refetch()}
            >
              <RefreshIcon fontSize="small" />
            </Button>
          </div>
        )}
      />
      <Paper>
        <DataGrid
          columns={columns}
          rows={submissions || []}
          getRowId={(row) => row.code}
          componentsProps={{ toolbar: { add: true } }}
          pageSize={100}
          autoHeight
          hideFooterRowCount="false"
          localeText={datagrid}
          onCellClick={(selection) => {
            if (selection.colIndex >= (columns.length - 2)) return;
            if (selection.row.isLocked) return;
            setCurrentSubmission(selection.row);
            setOpen(true);
          }}
        />
        <DialogFormSubmission
          open={open}
          onClose={() => {
            setCurrentSubmission(null);
            refetch();
            setOpen(false);
          }}
          onUnlock={() => lockRefetch()}
          onLock={() => lockRefetch()}
          onFinish={async () => {
            setCurrentSubmission(null);
            refetch();
            setOpen(false);
          }}
          submission={currentSubmission}
          fields={formData?.getForm?.fields}
        />
        <DialogFieldSelection
          open={fieldModalOpen}
          fields={formData?.getForm?.fields || []}
          selectedFields={fieldsToShow}
          onClose={() => setFieldModalOpen(false)}
          onFinish={(selectedFields) => {
            setFieldModalOpen(false);
            setFieldsToShow(selectedFields);
            const key = `form-moderator-fields-${[pageParams.company, pageParams.project, pageParams.code].join('-')}`;
            localStorage.setItem(key, selectedFields.map(({ id }) => id));
          }}
        />
      </Paper>
    </>
  );
}
