import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import AceEditor from 'react-ace';

function JsxCTAParams(props) {
  const [params, setParams] = useState(props.params);

  useEffect(() => {
    setParams(props.params || {});
  }, [props]);

  const onChange = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(newState);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Dynamisches HTML (JSX)</Typography>
      </Grid>
      <AceEditor
        mode="jsx"
        theme="textmate"
        onChange={(content) => {
          onChange('jsx', content);
        }}
        editorProps={{ $blockScrolling: false }}
        width="100%"
        value={params?.jsx}
        enableBasicAutocompletion
        enableLiveAutocompletion
        tabSize={2}
        style={{ marginBottom: '24px' }}
      />
    </>
  );
}

export default JsxCTAParams;
