import React, { Fragment, useState } from 'react';
import { Grid,
  Container,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import { makeStyles } from '@material-ui/core/styles';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-jsx';
import 'ace-builds/src-noconflict/theme-textmate';

const useStyles = makeStyles((theme) => ({
  jsxContainer: {
    backgroundColor: theme.palette.grey[200],
    cursor: 'pointer',
  },
}));

export default function JSXElement(props) {
  const classes = useStyles();
  const [params, setParams] = useState(props.element?.params);
  const [jsx, setJsx] = useState(params?.jsx);
  const [dialogOpen, setDialogOpen] = useState(false);
  const defaultJSX = '<Typography variant="body1" align="center">Zum Editieren auf die graue Fläche klicken.</Typography> ';

  const onChangeParams = (field, value) => {
    const newState = { ...params, [field]: value };
    setParams(newState);
    props.onChange && props.onChange(props.index, 'params', newState);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = () => {
    onChangeParams('jsx', jsx);
    setDialogOpen(false);
  };

  return (
    <>
      <Grid item xs={11} md={11} className={classes.jsxContainer} onClick={() => setDialogOpen(true)}>
        <JsxParser
          key="jsx-parser"
          components={{ Fragment, Container, Paper, Grid, Typography, Link, Button }}
          jsx={params?.jsx || defaultJSX}
        />
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="jsx-element-dialog">Dynamisches HTML (JSX)</DialogTitle>
        <DialogContent>
          <AceEditor
            mode="jsx"
            theme="textmate"
            onChange={(content) => setJsx(content)}
            editorProps={{ $blockScrolling: false }}
            width="100%"
            value={jsx}
            enableBasicAutocompletion
            enableLiveAutocompletion
            tabSize={2}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleSave} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
