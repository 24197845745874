import React, { useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PageTitle from '../../../components/PageTitle';
import SectionLayout from '../../../components/SectionLayout';
import InputFormField from '../../../components/forms/InputFormField';
import SelectFormField from '../../../components/forms/SelectFormField';
import AccessTime from '../../CustomPages/components/AccessTime';
import { addPageTypeGQL, getPageTypeDetailGQL, updatePageTypeGQL } from '../gql';
import { getTicketCategoriesGQL } from '../../TicketCategories/gql';

const defaults = {
  status: 'active',
  schedulingMode: 'inherited',
  accessRestrictionType: 'event',
  showLogo: true,
};

export default function PageTypesEdit() {
  const history = useHistory();
  const match = useRouteMatch();
  const matchEdit = useRouteMatch('/:companyCode/:projectCode/pageTypes/edit/:code');
  const matchCopy = useRouteMatch('/:companyCode/:projectCode/pageTypes/copy/:code');
  const matchAdd = useRouteMatch('/:companyCode/:projectCode/pageTypes/add');
  const params = useParams();
  const { companyCode, projectCode, code } = params;
  const [pageTypeDetails, setPageTypeDetails] = useState(defaults);
  const snackbar = useSnackbar();

  const showSuccessMessage = () => {
    snackbar.showMessage('Erfolgreich gespeichert');
  };

  const { loading } = useQuery(getPageTypeDetailGQL, {
    skip: !code,
    variables: { companyCode, projectCode, code },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getPageTypeDetail }) => {
      if (matchCopy) {
        setPageTypeDetails({ ...getPageTypeDetail, name: '', code: undefined });
      } else {
        setPageTypeDetails(getPageTypeDetail);
      }
    },
  });

  const { loading: ticketCategoriesLoading, data: getTicketCategoriesData = { getTicketCategories: [] } } = useQuery(getTicketCategoriesGQL, {
    variables: { companyCode, projectCode },
    fetchPolicy: 'no-cache',
  });

  const [addPageType, { loading: addPageTypeLoading }] = useMutation(addPageTypeGQL, {
    onCompleted: () => {
      if (matchCopy) {
        history.replace(match.url.replace(`/copy/${code}`, ''));
      } else {
        history.replace(match.url.replace('/add', ''));
      }
      showSuccessMessage();
    },
    onError: () => snackbar.showMessage('Fehler beim Speichern der Seite'),
  });

  const [updatePageType, { loading: updatePageTypeLoading }] = useMutation(updatePageTypeGQL, {
    onCompleted: () => history.replace(match.url.replace(`/edit/${code}`, '')),
    onError: () => snackbar.showMessage('Fehler beim Speichern der Seite'),
  });

  const saveInput = (e) => {
    e.preventDefault();

    if (matchAdd?.isExact || matchCopy?.isExact) {
      addPageType({
        variables: {
          companyCode,
          projectCode,
          pageType: {
            ...pageTypeDetails,
          },
        },
      });

      return;
    }

    updatePageType({
      variables: {
        companyCode,
        projectCode,
        code,
        pageType: {
          ...pageTypeDetails,
          code: undefined,
        },
      },
    });
  };

  const buttons = [{
    caption: matchEdit ? 'Speichern' : 'Erstellen',
    color: 'primary',
    type: 'submit',
    name: 'save',
    loading: addPageTypeLoading || updatePageTypeLoading,
  }];

  if (loading || ticketCategoriesLoading) return (<p>Loading</p>);

  return (
    <form onSubmit={saveInput}>
      <PageTitle
        title={matchEdit ? 'Seitentyp bearbeiten' : 'Neuen Seitentyp hinzufügen'}
        buttons={buttons}
      />
      <SectionLayout>
        <Typography variant="h5" style={{ marginBottom: 10 }}>Meta Daten</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InputFormField
              label="Name"
              name="name"
              value={pageTypeDetails?.name}
              onChange={({ target }) => setPageTypeDetails({ ...pageTypeDetails, name: target.value })}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectFormField
              label="Status"
              name="status"
              onChange={({ target }) => setPageTypeDetails({ ...pageTypeDetails, status: target.value })}
              value={pageTypeDetails.status}
              options={[{ label: 'Aktiv', value: 'active' }, { label: 'Inaktiv', value: 'inactive' }]}
            />
          </Grid>
        </Grid>
      </SectionLayout>
      <SectionLayout>
        <Typography variant="h5" style={{ marginBottom: 10 }}>Zeitschaltung</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectFormField
              label="Typ"
              name="schedulingMode"
              value={pageTypeDetails.schedulingMode}
              options={[
                { label: 'Aus', value: 'off' },
                { label: 'Von Projekt übernehmen', value: 'inherited' },
                { label: 'Benutzerdefiniert', value: 'custom' }]}
              onChange={({ target }) => setPageTypeDetails({ ...pageTypeDetails, schedulingMode: target.value })}

            />
          </Grid>
          { pageTypeDetails.schedulingMode === 'custom'
          && (
          <Grid item container xs={12}>
            <AccessTime
              accessTime={pageTypeDetails}
              onChange={setPageTypeDetails}
            />
          </Grid>
          )}
        </Grid>
      </SectionLayout>
      <SectionLayout>
        <Typography variant="h5" style={{ marginBottom: 10 }}>Zugriffsbeschänkung</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectFormField
              label="Typ"
              name="accessRestrictionType"
              value={pageTypeDetails.accessRestrictionType}
              options={[
                { label: 'Offen', value: 'open' },
                { label: 'Von Projekt übernehmen', value: 'event' },
                { label: 'Ticketkategorien einschränken', value: 'ticketCategory' },
                { label: 'Mit Passwort schützen', value: 'password' }]}
              onChange={({ target }) => setPageTypeDetails({ ...pageTypeDetails, accessRestrictionType: target.value })}

            />
          </Grid>
          {
            pageTypeDetails.accessRestrictionType === 'password'
            && (
            <Grid item xs={12} md={6}>
              <InputFormField
                label="Passwort"
                name="password"
                value={pageTypeDetails?.accessRestrictionParams?.password}
                onChange={({ target }) => setPageTypeDetails({
                  ...pageTypeDetails,
                  accessRestrictionParams: { password: target.value } })}
                required
              />
            </Grid>
            )
          }
          {
            pageTypeDetails.accessRestrictionType === 'ticketCategory'
            && (
            <Grid item xs={12} md={6}>
              <SelectFormField
                label="Berechtigte Tickets"
                name="ticketCategoryAccess"
                multiple
                value={
                  pageTypeDetails?.accessRestrictionParams?.ticketCategories?.length
                    ? pageTypeDetails.accessRestrictionParams?.ticketCategories : []
                }
                options={getTicketCategoriesData?.getTicketCategories ? [
                  ...getTicketCategoriesData.getTicketCategories.map((cat) => (
                    { label: cat.name, value: cat.code }
                  )),
                ] : []}
                onChange={({ target }) => setPageTypeDetails({
                  ...pageTypeDetails,
                  accessRestrictionParams: { ...pageTypeDetails.accessRestrictionParams, ticketCategories: target.value },
                })}
                requireds
              />
            </Grid>
            )
          }
        </Grid>
      </SectionLayout>
      <SectionLayout>
        <Typography variant="h5" style={{ marginBottom: 10 }}>Navigationsleiste</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={pageTypeDetails?.showLogo}
                  onChange={({ target }) => setPageTypeDetails({ ...pageTypeDetails, showLogo: target.checked })}
                  color="primary"
                />
              )}
              label="Logo Anzeigen"
            />
          </Grid>
        </Grid>
      </SectionLayout>
    </form>
  );
}
